import { Injectable } from '@angular/core';
import { CanActivate, Router, UrlTree } from '@angular/router';
import { hasUserPreferences } from 'company-finder-common';
import { UserService } from '../services/user/user.service';

@Injectable()
export class CanActivateMyUpdatesRouteGuard implements CanActivate {
  constructor(private _userService: UserService, private router: Router) {}

  async canActivate(): Promise<boolean | UrlTree> {
    const user = await this._userService.getCachedUser();
    return hasUserPreferences(user)
      ? true
      : this.router.parseUrl('user/preferences');
  }
}
