import { AuthnSource } from '.';
import { ICompanyLists } from './companyLists';
import { UserRoles } from './userRoles';

export class LoginInfo {
    id: string;
    iss: string;
    exp: number;
    iat: number;
    origin: AuthnSource;
    role: UserRoles;
    externalCredentials: ICompanyLists;
}

export class JuniverseInfo {
    jfc: string;
    sub: string;
    roles: string[];
    aud: string;
}
