export type KeyOfType<Type, ValueType> = keyof {
    [Key in keyof Type as Type[Key] extends ValueType ? Key : never]: ValueType;
};

// eslint-disable-next-line @typescript-eslint/ban-types
export function deepFreeze(object: object): object {
    // Retrieve the property names defined on object
    const propNames = Reflect.ownKeys(object);

    // Freeze properties before freezing self
    for (const name of propNames) {
        const value = object[name];

        if (
            (value && typeof value === 'object') ||
            typeof value === 'function'
        ) {
            deepFreeze(value);
        }
    }

    return Object.freeze(object);
}

export function ensureValueIsBoolean(booleanString: unknown): boolean {
    if (booleanString === null || booleanString === undefined) {
        return undefined;
    }
    return (
        booleanString === 'true' ||
        booleanString === 1 ||
        booleanString === '1' ||
        booleanString === 'Yes' ||
        booleanString === true
    );
}

export function convertToYesNo(booleanString: unknown): 'Yes' | 'No' {
    return ensureValueIsBoolean(booleanString) ? 'Yes' : 'No';
}
