import { LocalizedTextIds } from '../config';

export class ValueWithHeaderId {
    public header: LocalizedTextIds;
    public value: unknown;
}

export class ValueWithHeader {
    public header: string;
    public value: unknown;
}
