import { Environment } from '../config/general.config';

export enum ServerStatus {
    Unknown,
    OK,
    Unhealthy,
}

export class HealthStatus {
    public status = ServerStatus.Unknown;
    public indexDocCount: number;
    public apiVersion: string;
    public environment: Environment;
    public message: string;
    public warnings: string[];
    public dataLake: string;
    public masterData: string;
    public database: string;
    public dbConnected: boolean;
    public viewStatuses: Record<string, boolean>;
}
