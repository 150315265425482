import { Component, Input } from '@angular/core';

import { ComponentBase } from '../../../../../../_common/components/_component.base';
import { DeploymentContext } from '../../../../../../_common/utilities/deployment-context/deployment-context';

@Component({
  selector: 'annulus',
  templateUrl: './annulus.component.html',
  styleUrls: ['./annulus.component.scss'],
})
export class AnnulusComponent extends ComponentBase {
  // public properties
  @Input()
  public color: string;
  @Input()
  public iconClass: string;
  @Input()
  public scale: string;
  @Input()
  public value: number;

  public constructor(dc: DeploymentContext) {
    super(dc);
  }

  // public getters
  public get positionClass(): string {
    return this.scale === 'large'
      ? 'cf_annulus-icon-large'
      : 'cf_annulus-icon-small';
  }

  public get rotationClass(): string {
    const degrees = Math.floor(this.value * 360);
    const rotationPrefix =
      this.color === 'light' ? 'rotationLight' : 'rotationDark';
    return `${rotationPrefix}${degrees}`;
  }

  public get sizeRotationAndStrokeClasses(): string {
    return this.scale === 'large'
      ? `cf_annulus-size-large ${this.rotationClass}`
      : `cf_annulus-size-small ${this.rotationClass}`;
  }
}
