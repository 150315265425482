import { EventOfInterest } from './EventOfInterest';
import { EventOfInterestType } from './EventOfInterestType';
import { NewCompanyEventOfInterest } from './NewCompanyEventOfInterest';
import { NewsEventOfInterest } from './NewsEventOfInterest';
import { CompanyUpdateEventOfInterest } from './CompanyUpdateEventOfInterest';
import { UserInfo } from './UserInfo';
import _ from 'lodash';
import { MatchedCompanyInfo } from './MatchedCompanyInfo';

export class UserEventsOfInterestDigest {
    // Constructor should sort events by date desc

    public userInfo: UserInfo;
    public companies: MatchedCompanyInfo[]; // for all companies in events included

    // These are set up by constructor
    public events: EventOfInterest[]; // selected for user
    public directlyFollowedEvents: EventOfInterest[];
    public indirectlyFollowedEvents: EventOfInterest[];
    public newCompanyEvents: NewCompanyEventOfInterest[];
    public companyUpdateEvents: CompanyUpdateEventOfInterest[];
    public inTheNewsEvents: NewsEventOfInterest[];
    public followedCompanyIds: string[]; // id's of all directly followed companies
    public totalUniqueCompanyCount: number = 0;

    // When there are no events, suggest...
    public suggestedNews: NewsEventOfInterest[];

    public constructor(
        userInfo: UserInfo,
        events: EventOfInterest[],
        companies: MatchedCompanyInfo[],
        suggestedNews?: NewsEventOfInterest[]
    ) {
        this.userInfo = userInfo;
        this.events = _.orderBy(events, (ev: any) => ev.eventDate, 'desc');
        this.companies = companies;
        this.suggestedNews = suggestedNews;
        this.followedCompanyIds = companies
            .filter((company) => company.isFollowing)
            .map((company) => company.id);
        this.directlyFollowedEvents = _.filter(this.events, (ev: any) =>
            _.includes(this.followedCompanyIds, ev.companyId)
        );
        this.indirectlyFollowedEvents = _.filter(
            this.events,
            (ev: any) => !_.includes(this.followedCompanyIds, ev.companyId)
        );
        this.newCompanyEvents = _.filter(
            this.indirectlyFollowedEvents,
            (ev: any) => ev.eventType === EventOfInterestType.NewCompany
        ) as NewCompanyEventOfInterest[];
        this.companyUpdateEvents = _.filter(
            this.indirectlyFollowedEvents,
            (ev: any) => ev.eventType === EventOfInterestType.CompanyUpdate
        ) as CompanyUpdateEventOfInterest[];
        this.inTheNewsEvents = _.filter(
            this.indirectlyFollowedEvents,
            (ev: any) => ev.eventType === EventOfInterestType.InTheNews
        ) as NewsEventOfInterest[];
    }

    /**
     * Eliminate redundant data structures that the constructor recreates
     */
    public pruneToTransferObject() {
        // FUTURE: There is probably a better way to do this.  But we noticed a payload (several MBs) when the
        // client received what should have been a paged result set.  It turned out the supporting collections
        // were not also paged/sliced. So, the simplest approach seemed to be to just prune the redundant properties
        // that would be recreated/populated by the constructor on the receiving-side.
        delete this.followedCompanyIds;
        delete this.directlyFollowedEvents;
        delete this.indirectlyFollowedEvents;
        delete this.newCompanyEvents;
        delete this.companyUpdateEvents;
        delete this.inTheNewsEvents;
    }
}
