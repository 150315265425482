import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable, of, pipe, throwError } from 'rxjs';
import { catchError, retryWhen, tap } from 'rxjs/operators';
import { JnjErrorHandler } from '../utilities/jnj-error-handler/jnj-error-handler';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
  constructor(private errorHandler: JnjErrorHandler) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      retryWhen((errors) =>
        errors.pipe(
          tap((error) => {
            if (
              error?.message.indexOf(`0 Unknown`) > -1 &&
              error.url.indexOf('/api/v1/') > -1
            ) {
              console.log(`ADJQ-1537: ${JSON.stringify(error)}`);
            } else {
              throw error;
            }
          })
        )
      ),
      catchError((error: HttpErrorResponse) => {
        // We don't want to raise a toast for non-API HTTP errors
        // since, for example, we expect Juniverse to 401 for all
        // non-company users
        if (!this.isAPIError(error)) {
          return throwError(error);
        }

        this.errorHandler.handleError(
          new Error(this.formatErrorMsg(error)),
          'HTTP Error',
          false,
          error.error?.correlationId
        );

        // Don't throw the error, or it will wind up back in the
        // JNJ Error Handler Logging the 500, which should already
        // have been logged by the server
        return of(null);
      })
    );
  }

  public isAPIError(error: HttpErrorResponse): boolean {
    return error.status >= 500 && error.url.indexOf('/api/v1/') > -1;
  }

  public formatErrorMsg(error: HttpErrorResponse): string {
    return error.error instanceof ErrorEvent
      ? `Error: ${error.error.message}`
      : `Error Code: ${error.status},  Message: ${error.message}`;
  }
}
