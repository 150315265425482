import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';

@Injectable()
export class RemoveOldInternalRouteGuard implements CanActivate {
  constructor(private router: Router) {}

  canActivate(
    _route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean | UrlTree {
    if (!state.url?.includes('internal')) {
      // if it isn't an old internal route, continue on, no need
      // for a no-op redirect
      return true;
    }

    return this.router.parseUrl(state.url.replace('/internal', ''));
  }
}
