import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

// model imports
import {
  EventFilterType,
  EventOfInterestType,
  NewsEventOfInterest,
  UserEventsOfInterestDigest,
} from 'company-finder-common';

// utility/service imports
import { DeploymentContext } from '../../utilities/deployment-context/deployment-context';

import { ServiceBase } from '../_service.base';

@Injectable()
export class EventService extends ServiceBase {
  constructor(
    protected _deploymentContext: DeploymentContext,
    protected _httpClient: HttpClient
  ) {
    super(_httpClient, _deploymentContext, '/event');
  }

  public unseenCounts: number[];
  // public methods
  public async getUnseenEventCounts(): Promise<number[]> {
    if (!this.unseenCounts) {
      this.unseenCounts = await this._httpClient
        .get<number[]>(`${this._apiUrl}/user/unread-count`, {
          headers: this._standardHeaders,
        })
        .toPromise();
    }

    return this.unseenCounts;
  }

  public clearUnseenCounts(): void {
    this.unseenCounts = [0, 0, 0, 0];
  }

  public async getUserEventsOfInterestDigest(
    index: number,
    count: number,
    selectedUpdateType: EventOfInterestType = EventOfInterestType.AllUpdates,
    filterTypes: EventFilterType[] = []
  ): Promise<UserEventsOfInterestDigest> {
    return this._httpClient
      .post<UserEventsOfInterestDigest>(
        `${this._apiUrl}`,
        { index, count, selectedUpdateType, filterTypes },
        { headers: this._standardHeaders }
      )
      .toPromise();
  }

  public async getCompanyNewsDigest(
    companyId: string
  ): Promise<NewsEventOfInterest[]> {
    return this._httpClient
      .get<NewsEventOfInterest[]>(`${this._apiUrl}/company/${companyId}`, {
        headers: this._standardHeaders,
      })
      .toPromise();
  }
}
