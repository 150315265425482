import { Injectable } from '@angular/core';
import sortBy from 'lodash/sortBy';
import {
  Filter,
  Location,
  StatusDisplayItem,
  MetaDataValue,
  StatusMetaData,
} from 'company-finder-common';
import { MenuOption, GroupedMenuOptions } from './menu-option.interface';
import { DeploymentContext } from '../../utilities/deployment-context/deployment-context';

// By design, this service is not a singleton so every consuming component must register
// it as a provider
@Injectable()
export class MenuOptionService {
  public locationOptions: MenuOption<Location>[] = [];
  public indicationOptions: MenuOption<string>[] = [];
  public statusOptions: GroupedMenuOptions<StatusDisplayItem>[] = [];

  constructor(private deploymentContext: DeploymentContext) {}

  public async initMenus(filter: Filter): Promise<void> {
    const summary = this.deploymentContext.comprehensiveSummary;

    this.locationOptions = sortBy(
      summary.locations.map((location) => {
        let value = false;
        if (filter && !filter.isShowAllLocations()) {
          value = !!filter.locations.find(
            (filterLocation) => filterLocation === location.name
          );
        }
        return {
          id: location.name,
          label: location.name,
          value: value,
          dataModel: location,
        };
      }),
      (locationOption) => locationOption.label
    );

    this.statusOptions = this.usedStatusGroups.map((meta) =>
      this.metaToMenuOption(meta, filter)
    );

    this.indicationOptions =
      this.deploymentContext.referenceValueData.indications.map((ind) => ({
        id: ind.value,
        label: ind.value,
        value: !!filter.indications?.find(
          (filterIndication) => filterIndication === ind.value
        ),
        dataModel: ind.value,
      }));
  }

  private metaToMenuOption(
    meta: StatusMetaData,
    filter: Filter
  ): GroupedMenuOptions<StatusDisplayItem> {
    return {
      groupName: meta.groupName,
      category: meta.statusType,
      options: meta.values
        .filter((metaValue) => this.isStatusUsed(metaValue.value))
        .map((metaValue) => this.metaValueToMenuOption(metaValue, filter)),
    };
  }

  private metaValueToMenuOption(
    value: MetaDataValue,
    filter: Filter
  ): MenuOption<StatusDisplayItem> {
    return {
      id: value.value,
      label: value.display,
      value: filter?.locationStatuses.includes(value.value),
      dataModel: {
        status: value.value,
        displayName: value.display,
      },
    };
  }

  private _usedStatusGroups: StatusMetaData[];

  public get usedStatusGroups(): StatusMetaData[] {
    if (!this._usedStatusGroups) {
      this._usedStatusGroups = this.deploymentContext.statusMetadata.filter(
        (meta) =>
          meta.values.some((metaValue) => this.isStatusUsed(metaValue.value))
      );
    }
    return this._usedStatusGroups;
  }

  private isStatusUsed(status: string): boolean {
    return this.deploymentContext.comprehensiveSummary.allStatusesWithCounts.some(
      (statusWithCount) =>
        statusWithCount.statusItem.status === status &&
        statusWithCount.count > 0
    );
  }
}
