import { CompanyKey, LocalizedTextIds } from 'company-finder-common';

// Review interfaces
export interface ReviewableEntity {
  updateFields: ReviewableUpdateField[];
}
export interface ReviewableUpdate {
  updateFields: ReviewableUpdateField[];
  dealUpdates: ReviewableEntity[];
  fundingUpdates: ReviewableEntity[];
}
export interface ReviewableUpdateField {
  displayName: string;
  isSet: boolean;
  newValue: string;
  oldValue: string;
}
interface ReviewedItem {
  isApproved: boolean;
}
export interface ReviewedProperty extends ReviewedItem {
  name: string;
}
export interface ReviewedModel extends ReviewedItem {
  modelId: string;
}
export enum ReviewConfirmationStatus {
  None,
  All,
  Partial,
}
// Submission interfaces
export interface SimpleUpdate {
  content: string;
  displayName: string;
  isLogo?: boolean;
  propertyName: string;
  title?: string;
}

export interface AddDeleteUpdate {
  added?: string[];
  deleted?: string[];
  displayName: string;
  propertyName: string;
}
export interface AddDeleteEditUpdate extends AddDeleteUpdate {
  edited?: EditedContent[];
  modelId: string;
}
export interface EditedContent {
  title: string;
  updates: SimpleUpdate[];
}
export interface SubmittedUpdate {
  // Ordinary properties + logo
  simpleUpdates: SimpleUpdate[];
  // Tag Updates
  addDeleteUpdates: AddDeleteUpdate[];
  // Deals & Funding Updates
  addDeleteEditUpdates: AddDeleteEditUpdate[];
}
// SelfUpdate modes
export enum SelfUpdateMode {
  Edit = 'edit',
  Review = 'review',
}

export interface EditItemChoice {
  label?: string;
  value: string;
}

// Tag Reviews
export enum ItemReviewModificationType {
  Exclude,
  Preserve,
}
export interface ItemReviewModification {
  item: string;
  modificationType: ItemReviewModificationType;
  value: boolean;
}
// MultiChoice
export interface MultiChoiceItemChange {
  propertyName: string;
  value: string;
}
// Grouped properties
export interface GroupedProperty {
  groupName: string;
  propertyNames: string[];
}

export interface EditablePropertyGroup {
  header?: LocalizedTextIds;
  properties: CompanyKey[];
  subheader?: {
    subheaderId: LocalizedTextIds;
    url?: {
      target: string;
      display: string;
    };
  };
}
