import { ICompanyUpdate } from './company-update.interface';
import { IDealUpdate } from './deal-update.interface';
import { IFundingUpdate } from './funding-update.interface';
import { IEntityBase } from './entity-base.interface';
import { CompanyKey } from '../model/company';
import { Deal, Funding } from '../model';

export enum ProcessedStatus {
    NotStarted = 'not started',
    Retry1 = 'retry1',
    Retry2 = 'retry2',
    Failed = 'failed',
    Success = 'success',
}

export enum UpdateStatus {
    /** This field/update has been reviewed and approved.
     * Note that an updated is considered approved if any individual field was approved,
     * regardless of the number of declined fields. */
    Approved = 'approved',
    /** This field/update has been reviewed and declined. */
    Declined = 'declined',
    /** This field/update has been submitted but not reviewed. */
    Pending = 'pending',
    /** The field/update has not been submitted but was stored in the db for the user to complete at a later date */
    Saved = 'saved',
    /** The field has been approved but the data hub team is not ready to consume this value*/
    PreLaunch = 'prelaunch',
}

// FUTURE - What the client provides for an update field is now a significantly reduced set
// and it may make sense to decouple the API Interface for an update from the DB representation
export interface IUpdateField extends IEntityBase {
    emailAddress?: string;
    name: CompanyKey | keyof Deal | keyof Funding;
    nav_table_name?: 'company_update' | 'deal_update' | 'funding_update';
    backend_destination?: 'jForce' | 'blueKnight';
    newValue: string;
    oldValue: string;
    processed?: ProcessedStatus;
    status?: UpdateStatus;
    companyUpdate?: ICompanyUpdate;
    dealUpdate?: IDealUpdate;
    fundingUpdate?: IFundingUpdate;
    processedDate?: Date;
    processedComments?: string;
}
