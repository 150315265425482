import { Component } from '@angular/core';
import { AuthnService } from '../../../_common/services/authn/authn.service';
import { ComponentBase } from '../../../_common/components/_component.base';
import { DeploymentContext } from '../../../_common/utilities/deployment-context/deployment-context';

@Component({
  selector: 'disclaimer',
  templateUrl: './disclaimer.component.html',
  styleUrls: ['./disclaimer.component.scss'],
})
export class DisclaimerComponent extends ComponentBase {
  public constructor(
    dc: DeploymentContext,
    private _authnService: AuthnService
  ) {
    super(dc);
  }

  public isAuthenticated(): boolean {
    return this._authnService.isAuthenticated;
  }

  public get jlabsUrl(): string {
    return this._deploymentContext.hostingSiteUrl;
  }
}
