export enum SortType {
    Ranking = 'Relevancy',
    Date = 'Newest',
    Alpha = 'Alphabetic',
}

export interface FilterFlags {
    isBlueKnight?: boolean;
    isCrossSector?: boolean;
    isQFCWinner?: boolean;
    isNewInLastQuarter?: boolean;
    isNewInLast90Days?: boolean;
    isFollowedCompaniesOnly?: boolean;
    isMyJPALCompaniesOnly?: boolean;
}
// FUTURE - These are not used consistently and could likely be removed in the future
export interface FilterTotals {
    totalLocations?: number;
    totalPrimarySectors?: number;
    totalPrimarySubSectors?: number;
    totalSecondarySectors?: number;
    totalSecondarySubSectors?: number;
    totalIndications?: number;
}

export interface FilterFields {
    locations?: string[];
    primarySectors?: string[];
    primarySubSectors?: string[];
    secondarySectors?: string[];
    secondarySubSectors?: string[];
    indications?: string[];
    currentRdStage?: string;
    tags?: string[];
    locationStatuses?: string;
    companyStatuses?: string;
}

export class Filter {
    // public properties
    public static equals(someFilter: Filter, otherFilter: Filter): boolean {
        // Stringified JSON is an efficient and scalable way to compare complex objects
        // provided that the order of properties is static. See https://stackoverflow.com/a/1144249
        // and https://www.mattzeunert.com/2016/01/28/javascript-deep-equal.html
        return JSON.stringify(someFilter) === JSON.stringify(otherFilter);
    }

    public locationStatuses: string;
    public companyStatuses: string;
    public totalLocations: number;
    public totalPrimarySectors: number;
    public totalPrimarySubSectors: number;
    public totalSecondarySectors: number;
    public totalSecondarySubSectors: number;
    public totalIndications: number;
    public locations: string[];
    public primarySectors: string[];
    public primarySubSectors: string[];
    public secondarySectors: string[];
    public secondarySubSectors: string[];
    public tags: string[];
    public indications: string[];
    public isBlueKnight: boolean;
    public isCrossSector: boolean;
    public isQFCWinner: boolean;
    public isNewInLastQuarter: boolean;
    public currentRdStage: string;
    public isNewInLast90Days: boolean;
    public isFollowedCompaniesOnly: boolean;
    public isMyJPALCompaniesOnly: boolean;

    public constructor(
        fields?: FilterFields,
        totals?: FilterTotals,
        flags?: FilterFlags,
        public sort: SortType = SortType.Ranking
    ) {
        this.locations = fields?.locations ?? [];
        this.primarySectors = fields?.primarySectors ?? [];
        this.primarySubSectors = fields?.primarySubSectors ?? [];
        this.secondarySectors = fields?.secondarySectors ?? [];
        this.secondarySubSectors = fields?.secondarySubSectors ?? [];
        this.tags = fields?.tags ?? [];
        this.indications = fields?.indications ?? [];
        this.currentRdStage = fields?.currentRdStage ?? '';
        this.locationStatuses = fields?.locationStatuses ?? '';
        this.companyStatuses = fields?.companyStatuses ?? '';

        this.totalLocations = totals?.totalLocations ?? 0;
        this.totalPrimarySectors = totals?.totalPrimarySectors ?? 0;
        this.totalPrimarySubSectors = totals?.totalPrimarySubSectors ?? 0;
        this.totalSecondarySectors = totals?.totalSecondarySectors ?? 0;
        this.totalSecondarySubSectors = totals?.totalSecondarySubSectors ?? 0;
        this.totalIndications = totals?.totalIndications ?? 0;

        this.isBlueKnight = flags?.isBlueKnight ?? false;
        this.isCrossSector = flags?.isCrossSector ?? false;
        this.isQFCWinner = flags?.isQFCWinner ?? false;
        this.isNewInLastQuarter = flags?.isNewInLastQuarter ?? false;
        this.isNewInLast90Days = flags?.isNewInLast90Days ?? false;
        this.isFollowedCompaniesOnly = flags?.isFollowedCompaniesOnly ?? false;
        this.isMyJPALCompaniesOnly = flags?.isMyJPALCompaniesOnly ?? false;
    }

    public static get emptyFilter(): Filter {
        return new Filter();
    }

    public static get emptyAlphaSortFilter(): Filter {
        const empty = this.emptyFilter;
        empty.sort = SortType.Alpha;
        return empty;
    }

    public isShowAllLocations(): boolean {
        return (
            this.locations.length === this.totalLocations ||
            this.locations.length === 0
        );
    }

    public isShowAllSectors(): boolean {
        return (
            this.primarySectors?.length === this.totalPrimarySectors ||
            this.primarySectors?.length === 0
        );
    }

    public isShowAllSubSectors(): boolean {
        return (
            this.primarySubSectors?.length === this.totalPrimarySubSectors ||
            this.primarySubSectors?.length === 0
        );
    }

    public isShowAllSecondarySectors(): boolean {
        return (
            this.secondarySectors?.length === this.totalSecondarySectors ||
            this.secondarySectors?.length === 0
        );
    }

    public isShowAllSecondarySubSectors(): boolean {
        return (
            this.secondarySubSectors?.length ===
                this.totalSecondarySubSectors ||
            this.secondarySubSectors?.length === 0
        );
    }

    public isShowAllIndications(): boolean {
        // CAB TODO - Include totals
        return this.indications?.length === 0;
    }

    public isShowAll(): boolean {
        // NOTE: The various filters available in the UI treat NONE or empty as ALL.
        return (
            (!this.locationStatuses || this.locationStatuses === '') &&
            (!this.companyStatuses || this.companyStatuses === '') &&
            this.isShowAllLocations() &&
            this.isShowAllSectors() &&
            this.isShowAllSubSectors() &&
            this.isShowAllSecondarySectors() &&
            this.isShowAllSecondarySubSectors() &&
            this.isShowAllIndications() &&
            (!this.tags || this.tags.length === 0) &&
            !this.isBlueKnight &&
            !this.isCrossSector &&
            !this.isQFCWinner &&
            !this.isNewInLastQuarter &&
            !this.currentRdStage &&
            !this.isNewInLast90Days &&
            !this.isFollowedCompaniesOnly &&
            !this.isMyJPALCompaniesOnly
        );
    }

    public clear(): void {
        this.locationStatuses = '';
        this.companyStatuses = '';
        this.locations = [];
        this.indications = [];
        this.primarySectors = [];
        this.primarySubSectors = [];
        this.secondarySectors = [];
        this.secondarySubSectors = [];
        this.tags = undefined;
        this.isBlueKnight = undefined;
        this.isCrossSector = undefined;
        this.isQFCWinner = undefined;
        this.isNewInLastQuarter = undefined;
        this.currentRdStage = undefined;
        this.isNewInLast90Days = undefined;
        this.isFollowedCompaniesOnly = undefined;
        this.isMyJPALCompaniesOnly = undefined;

        this.sort = undefined;
    }

    /** Returns a deep copy of this filter */
    public clone(): Filter {
        const copy = Filter.emptyFilter;
        Object.assign(copy, this);
        copy.locations = [...this.locations];
        copy.primarySectors = [...this.primarySectors];
        copy.primarySubSectors = [...this.primarySubSectors];
        copy.secondarySectors = [...this.secondarySectors];
        copy.secondarySubSectors = [...this.secondarySubSectors];
        copy.tags = this.tags ? [...this.tags] : undefined;
        return copy;
    }

    public serializeToKeyValuePairs(): string[] {
        const keyValuePairs = [];
        const defaultFilter = Filter.emptyFilter;
        for (const [key, value] of Object.entries(this)) {
            const isArray = Array.isArray(value);
            if (
                (value !== undefined &&
                    !isArray &&
                    value !== defaultFilter[key]) ||
                (isArray && value.length !== 0)
            ) {
                if (typeof value === 'boolean' && !value) {
                    continue;
                }
                keyValuePairs[key] = {
                    value: value,
                    isArray: isArray,
                };
            }
        }
        return keyValuePairs;
    }
}
