import { Component, Input, OnInit } from '@angular/core';
import { ComponentBase } from '../../../../_common/components/_component.base';

import { ReviewEditsService } from '../../services/review-edits.service';

// model imports
import { Deal, Funding, UpdateStatus } from 'company-finder-common';
import { DeploymentContext } from '../../../../_common/utilities/deployment-context/deployment-context';

@Component({
  selector: 'approve-decline',
  templateUrl: './approve-decline.component.html',
  styleUrls: ['./approve-decline.component.scss'],
})
export class ApproveDeclineComponent extends ComponentBase implements OnInit {
  // public properties
  @Input()
  public propertyName: string;
  @Input()
  public deal: Deal;
  @Input()
  public funding: Funding;
  @Input()
  public includeInSummary = false;
  public statuses = UpdateStatus;

  // private properties
  public _status = UpdateStatus.Pending;

  public constructor(
    dc: DeploymentContext,
    protected _reviewEditsService: ReviewEditsService
  ) {
    super(dc);
  }

  // public getters & setters
  public get status(): UpdateStatus {
    if (this.propertyName) {
      return this._reviewEditsService.getPropertyStatus(this.propertyName);
    } else if (this.deal) {
      return this._reviewEditsService.getModelStatus(this.deal.dealId);
    } else if (this.funding) {
      return this._reviewEditsService.getModelStatus(this.funding.fundingId);
    }
  }

  public approveDeal(deal: Deal): void {
    this._reviewEditsService.approveDeal(deal);
  }

  public approveFunding(funding: Funding): void {
    this._reviewEditsService.approveFunding(funding);
  }

  public approveProperty(): void {
    if (this.status !== UpdateStatus.Approved) {
      this._reviewEditsService.approveProperty(this.propertyName);
    } else {
      this._reviewEditsService.resetPropertyStatus(this.propertyName);
    }
  }

  public declineDeal(deal: Deal): void {
    this._reviewEditsService.declineDeal(deal);
  }

  public declineFunding(funding: Funding): void {
    this._reviewEditsService.declineFunding(funding);
  }

  public declineProperty(): void {
    if (this.status !== UpdateStatus.Declined) {
      this._reviewEditsService.declineProperty(this.propertyName);
    } else {
      this._reviewEditsService.resetPropertyStatus(this.propertyName);
    }
  }

  public get isInactive(): boolean {
    return !!this._reviewEditsService.currentEditItemProperty;
  }

  public ngOnInit(): void {
    this._reviewEditsService.register(this);
  }
}
