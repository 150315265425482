import { Component, Input } from '@angular/core';
import { ComponentBase } from '../../../../_common/components/_component.base';
@Component({
  selector: 'investment-summary',
  templateUrl: './investment-summary.component.html',
  styleUrls: ['./investment-summary.component.scss'],
})
export class InvestmentSummaryComponent extends ComponentBase {
  @Input()
  public highestLevelOfFundingSecured: string;

  @Input()
  public totalSecuredAndContingentAmount: string;
}
