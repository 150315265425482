export class HasMatches {
    public constructor(
        public matchedLocations: string[],
        public matchedSearches: string[],
        public matchedSectors: string[],
        public matchedTags: string[],
        public matchedBlueKnight: boolean = false
    ) {
        this.matchedLocations = matchedLocations;
        this.matchedSearches = matchedSearches;
        this.matchedSectors = matchedSectors;
        this.matchedTags = matchedTags;
        this.matchedBlueKnight = matchedBlueKnight;
    }
}
