import * as _ from 'lodash';

import {
  Component,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  ElementRef,
  OnDestroy,
  TemplateRef,
  SimpleChanges,
  OnChanges,
} from '@angular/core';
import {
  MenuOption,
  HierarchicalMenuOption,
} from '../../../_common/services/menu-option/menu-option.interface';
import { Subscription, fromEvent } from 'rxjs';
import { ComponentBase } from '../_component.base';
import { KeyOfType } from 'company-finder-common';

@Component({
  selector: 'dropdown-menu',
  templateUrl: './dropdown-menu.component.html',
  styleUrls: ['./dropdown-menu.component.scss'],
})
export class DropdownMenuComponent<T>
  extends ComponentBase
  implements OnDestroy, OnChanges
{
  // A label for the dropdown
  @Input() label?: string;
  // A list of menu options to display
  @Input() menuOptions: MenuOption<T>[] | HierarchicalMenuOption<T>[];
  // Value to show on the dropdown button when no items are selected
  @Input() defaultValue: string;
  @Input() menuStructureTemplate?: TemplateRef<never>;
  @Input() optionDisplayTemplate?: TemplateRef<never>;
  @Input() independentChildren = false;
  @Input() itemsPerColumn = 8;
  // An event that tracks the set of selected elements at any given time
  @Output() select = new EventEmitter<T[]>();
  // Event called after selection has been computed. Passes the menuOption that triggered the selection
  // calculation as context. Think of this as a callback to the selection process.
  @Output() afterSelect = new EventEmitter<MenuOption<T>>();

  public isMenuOpen = false;
  // Indeterminate options are parents with a partially selected children. They receive special styling
  public indeterminateOptions: { [id: string]: boolean } = {};
  public columns: number[];

  private _dropdownMenuContent: ElementRef<HTMLDivElement>;
  public _windowClickSubscription: Subscription;
  public _dropdownMenuContentClickSubscription: Subscription;

  public async ngOnChanges(_changes: SimpleChanges): Promise<void> {
    const menuOptionsChanges = _changes.menuOptions;
    // Nothing to to if no menuOptions changes
    if (!menuOptionsChanges) {
      return;
    }

    // Deep compare with prior value, and only rebuild on change
    if (
      this.menuOptions &&
      !_.isEqual(
        menuOptionsChanges.previousValue,
        menuOptionsChanges.currentValue
      )
    ) {
      this.columns = this.getColumns(this.menuOptions);
    }
  }

  public get value(): string {
    return this.calculateSelectedOptionsCount();
  }

  @ViewChild('dropdownMenuContent')
  set content(content: ElementRef<HTMLDivElement>) {
    if (content) {
      this._dropdownMenuContent = content;
      this._dropdownMenuContentClickSubscription = fromEvent(
        this._dropdownMenuContent.nativeElement,
        'click'
      ).subscribe((event) => {
        // stop propagation so that the events within the dropdown content don't bubble and cause the menu to close
        event.stopPropagation();
      });
    } else {
      this.unsubscribeMenu('_dropdownMenuContentClickSubscription');
    }
  }

  ngOnDestroy(): void {
    this.unsubscribeMenu('_windowClickSubscription');
    this.unsubscribeMenu('_dropdownMenuContentClickSubscription');
  }

  public toggleMenu(): void {
    if (this.isMenuOpen) {
      this.hideMenu();
    } else {
      this.openMenu();
    }
  }

  public getColumns(
    menuOptions: MenuOption<T>[] | HierarchicalMenuOption<T>[]
  ): number[] {
    const numberOfColumns = Math.ceil(menuOptions.length / this.itemsPerColumn);
    return Array(numberOfColumns)
      .fill(0)
      .map((x, i) => i);
  }

  public updateSelection(
    menuOption: MenuOption<T> | HierarchicalMenuOption<T>
  ): void {
    // handle hierarchy if present
    const hierarchicalMenuOption = menuOption as HierarchicalMenuOption<T>;
    if (hierarchicalMenuOption.children && !this.independentChildren) {
      this.setChildren(
        hierarchicalMenuOption.children,
        hierarchicalMenuOption.value
      );
    }
    if (hierarchicalMenuOption.parent && !this.independentChildren) {
      this.setAncestors(hierarchicalMenuOption.parent);
    }

    // Recurse the menuOption hierarchy to report all selected options
    const selected = this.getSelectedOptions(this.menuOptions);
    this.select.emit(selected);
    this.afterSelect.emit(menuOption);
  }

  public handleSingleSelect(menuOption: MenuOption<T>): void {
    menuOption.value = !menuOption.value;
    this.updateSelection(menuOption);
  }

  private getSelectedOptions(
    menuOptions: Array<MenuOption<T> | HierarchicalMenuOption<T>>
  ): T[] {
    return menuOptions.reduce((selectedOptions, menuOption) => {
      if (menuOption.value) {
        // Get only those options that are selected
        selectedOptions.push(menuOption.dataModel);
        // Selected options cannot be indeterminate
        this.indeterminateOptions[menuOption.id] = false;
      }

      const hierarchicalMenuOption = menuOption as HierarchicalMenuOption<T>;
      if (hierarchicalMenuOption.children) {
        selectedOptions.push(
          ...this.getSelectedOptions(hierarchicalMenuOption.children)
        );
      }

      return selectedOptions;
    }, []);
  }

  private setChildren(
    childOptions: HierarchicalMenuOption<T>[],
    val: boolean
  ): void {
    // Changing the value of a parent option should change all of its children to match that value
    childOptions.forEach((menuOption) => {
      menuOption.value = val;
      if (menuOption.children) {
        this.setChildren(menuOption.children, val);
      }
    });
  }

  private setAncestors(parentOption: HierarchicalMenuOption<T>): void {
    // Only set the parent value to true if all children's values are true
    parentOption.value = parentOption.children.every(
      (childOption) => childOption.value
    );
    // Option is indeterminate if at least one, but not all, of child values are true
    this.indeterminateOptions[parentOption.id] =
      !parentOption.value &&
      parentOption.children.some((childOption) => childOption.value);

    if (parentOption.parent) {
      this.setAncestors(parentOption.parent);
    }
  }

  private calculateSelectedOptionsCount() {
    const selected = this.getSelectedOptions(this.menuOptions);
    if (selected.length === 0) {
      return this.defaultValue;
    }

    return `${selected.length} Selected`;
  }

  private openMenu(): void {
    this.isMenuOpen = true;
    // subscribe asynchronously so that the current click doesn't close the menu
    setTimeout(() => {
      this._windowClickSubscription = fromEvent(window, 'click').subscribe(
        () => {
          // Perform hideMenu only after done handling this click-- if we hideMenu()
          // too soon, the dropdown won't get to handle it. ADJQ-1501
          setTimeout(() => {
            this.hideMenu();
          });
        }
      );
    });
  }

  private hideMenu(): void {
    this.isMenuOpen = false;
    this.unsubscribeMenu('_windowClickSubscription');
    this.unsubscribeMenu('_dropdownMenuContentClickSubscription');
  }

  private unsubscribeMenu(
    subscriptionField: KeyOfType<DropdownMenuComponent<T>, Subscription>
  ) {
    const subscription: Subscription = this[subscriptionField];
    subscription?.unsubscribe();
    this[subscriptionField] = undefined;
  }
}
