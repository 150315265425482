import { Component } from '@angular/core';

import { DeploymentContext } from '../../../../_common/utilities/deployment-context/deployment-context';
import { MenuOptionService } from '../../../../_common/services/menu-option/menu-option.service';
import { BaseSelectorComponent } from '../../../../_common/components/selector/BaseSelectorComponent';
import { StatusDisplayItem } from '../../../../../../company-finder-common/src';
import { GroupedMenuOptions } from '../../../../_common/services/menu-option/menu-option.interface';

@Component({
  selector: 'status-selector',
  templateUrl: './status-selector.component.html',
  styleUrls: ['./status-selector.component.scss'],
  providers: [MenuOptionService],
})
export class StatusSelectorComponent extends BaseSelectorComponent<StatusDisplayItem> {
  public constructor(
    dc: DeploymentContext,
    private _menuOptionService: MenuOptionService
  ) {
    super(dc);
  }

  public get statusOptions(): GroupedMenuOptions<StatusDisplayItem>[] {
    return this._menuOptionService.statusOptions;
  }

  public get label(): string {
    return this.Loc(this.LocalizedTextIds.Status);
  }
}
