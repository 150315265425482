import { Input, Output, EventEmitter, Directive } from '@angular/core';
import {
  HierarchicalMenuOption,
  MenuOption,
} from '../../services/menu-option/menu-option.interface';
import { ComponentBase } from '../_component.base';

@Directive()
export class BaseSelectorComponent<T> extends ComponentBase {
  // A list of menu options to display
  @Input() menuOptions: MenuOption<T>[] | HierarchicalMenuOption<T>[];

  // An event that tracks the set of selected elements at any given time
  @Output() select = new EventEmitter<T[]>();
  // Event called after selection has been computed. Passes the menuOption that triggered the selection
  // calculation as context. Think of this as a callback to the selection process.
  @Output() afterSelect = new EventEmitter<MenuOption<T>>();

  public relaySelect(event: any): void {
    this.select.emit(event);
  }

  public relayAfterSelect(event: any): void {
    this.afterSelect.emit(event);
  }

  // public methods
  public includeMenuOptionInColumn(
    column: number,
    itemsPerColumn: number,
    index: number,
    offset = 0
  ): boolean {
    return (
      index >= column * itemsPerColumn - offset &&
      index < column * itemsPerColumn + itemsPerColumn - offset
    );
  }
}
