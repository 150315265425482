import {
  SharedStats,
  SectorCounts,
  Location,
  LocationMetaData,
  GeneralConfig,
  sortSubsectors,
  SectorWithCountAndCanonicalString
} from 'company-finder-common';

// This class is mostly the same data/properties as the search result passed over from the api.
// However, using this class allows for safe defaults and safe access methods to avoid 
// propigating the same null checks throughout the code.
export class Summary extends SharedStats {
  private locationMetadata: LocationMetaData[] = [];
  public constructor(sourceData: SharedStats, config: GeneralConfig = GeneralConfig.globalInstance) {
    super();
    Object.assign(this, sourceData);
    ({ locationMetadata: this.locationMetadata } = config);

  }

  public companyCountBySector(sector: string): number {
    return this.sectorGroups?.find((s) => s.name === sector)?.value ?? 0;
  }

  public companyCountBySubsector(subsector: string): number {
    return this.sectorGroups
      ?.find((s) => s.children?.some((s2) => s2.name === subsector))
      ?.children
      ?.find((s2) => s2.name === subsector)
      ?.value ?? 0;
  }

  public numberOfCompaniesAtLocationBySector(
    location: string,
    sector: string
  ): number {
    try {
      return this.locationStatusCounts[location][sector];
    } catch {
      return 0;
    }
  }

  public getNumberOfCompaniesAtLocation(location: string): number {
    return this.locationStatusCounts[location]?.count ?? 0
  }

  public subSectorsBySector(sector: string): string[] {
    return sortSubsectors(
      this.sectorGroups
        ?.find((s) => s.name === sector)
        .children.filter((sub) => sub.value > 0) // Exclude subsectors that are tied to only non-primary opportunities
        .map((sub) => sub.name) ?? []
    );
  }

  public get descendingPrimarySectorCounts(): SectorCounts[] {
    return this.sectorGroups.sort((a, b) => b.value - a.value);
  }

  public get maxCompanyCount(): number {
    return Object.keys(this.locationStatusCounts).reduce((companyCount, key) => {
      return this.locationStatusCounts[key].count > companyCount
        ? this.locationStatusCounts[key].count
        : companyCount
    }, 0);
  }

  private _locations: Location[];

  public get locations(): Location[] {
    if (!this._locations) {
      const locationKeys = Object.keys(this.locationStatusCounts);
      this._locations = locationKeys.map((key) => {
        const meta = this.locationMetadata.find((m) => m.name === key);

        if (!meta) {
          // Some companies could have a location that isn't configured
          return;
        }

        return {
          name: meta.name,
          isNorthAmerican: meta.isNorthAmerican,
          excludeFromMap: meta.excludeFromMap,
          city: meta.city,
          geoPosition: meta.geoPosition,
          locationStatuses: []
        };
      });
    }
    return this._locations.filter((loc) => !!loc);
  }

  public get sectors(): string[] {
    return this.sectorGroups.map((sectorGroup) => sectorGroup.name);
  }

  public get subsectors(): string[] {
    return sortSubsectors(this.sectorGroups.flatMap((s) => s.children.map((c) => c.name)));
  }

  private _allSectorsWithCounts: SectorWithCountAndCanonicalString[];

  public get allSectorsWithCounts(): SectorWithCountAndCanonicalString[] {
    if (!this._allSectorsWithCounts) {
      this._allSectorsWithCounts = this.sectors.flatMap((sector) => {
        const subSectors = this?.subSectorsBySector(sector) ?? [];
        return [{
          sector: sector,
          parentSector: null,
          subSectors: subSectors,
          count: this.companyCountBySector(sector),
          canonicalString: sector.toLowerCase(),
        },
        ...subSectors.map((subSector) => {
          return {
            sector: subSector,
            parentSector: sector,
            subSectors: null,
            count: this.companyCountBySubsector(subSector),
            canonicalString: subSector.toLowerCase(),
          }
        })
        ];
      });
    }

    return this._allSectorsWithCounts;
  }
}
