import { decodeHtmlEntities } from '../utility';
import { LocalizationDict } from './general.config';

export enum LocPrefix {
    // Used as a prefix to localize strings that come from the db
    Db = 'DB',

    // Used to map 'Current' into 'Current Resident', etc
    LocStatus = 'LocStatus',

    // Used to map status into a more brief word (e.g., keeping 'Current' instead of 'Current Resident')
    LocStatusShort = 'LocStatusShort',

    // Used to map 'Pharmaceuticals' into 'Pharma', etc
    SectorShortName = 'SectorShortName',

    // Used if needed to remap the full display name for a sector
    SectorFullName = 'SectorFullName',
}

export class Localizer {
    private pluralRules: Intl.PluralRules;

    public constructor(private dict: LocalizationDict) {
        this.pluralRules = new Intl.PluralRules(dict['Locale']);
    }

    public Localize(
        key: string,
        pluralNumber: number = null,
        ...replacements: (string | number | boolean)[]
    ): string {
        if (!(key?.length > 0)) {
            return '';
        }

        let localized = this.dict[key];
        if (localized === undefined) {
            // Perhaps arg was a string with other embedded substitutions, so process it as such
            localized = key;
        }

        // Retrieve correct pluralization if applicable
        if (pluralNumber) {
            // If no params given, assume first (and only) param is the number
            if (replacements.length === 0) {
                replacements.push(pluralNumber);
            }
            const rule = this.pluralRules.select(pluralNumber);
            if (this.dict.hasOwnProperty(key + '$' + rule)) {
                localized = this.dict[key + '$' + rule];
            }
        }

        // Recursively localize any keys within replacement, keys have to start with a letter
        localized = localized?.replace(
            /\${([a-zA-Z_][-a-zA-Z0-9_]*)}/g,
            (match, k) => this.Localize(k)
        );

        // Replace variables in string using replacements
        localized = localized?.replace(/\${(\d+)}/g, (match, index) => {
            const numericIndex = Number(index); // Convert the index to a number
            if (numericIndex >= 0 && numericIndex < replacements.length) {
                const replacement = replacements[numericIndex];
                // eslint-disable-next-line eqeqeq
                if (replacement == null || replacement == undefined) {
                    // don't use !replacement, since 0 and false could be intentional values
                    console.log(
                        `Unexpected missing replacement in localizer for ${match}`
                    );
                    return match;
                }
                return this.Localize(replacements[numericIndex].toString());
            } else {
                return match; // Return the original pattern if it's out of bounds
            }
        });

        localized = decodeHtmlEntities(localized);

        return localized;
    }

    public LocalizeWithPrefix(
        key: string,
        prefix: string,
        tryWithNoPrefix: boolean = false
    ): string {
        if (!key) {
            return key;
        }

        const stringWithPrefix = `${prefix}_${key.toString().replace(' ', '')}`;
        let result = this.Localize(stringWithPrefix);
        if (result === stringWithPrefix) {
            if (tryWithNoPrefix) {
                result = this.Localize(key);
                // If no mapping found, this will return key anyway, so no need to test
            } else {
                // No entry found, just return the key
                result = key;
            }
        }
        return result;
    }

    public buildFlatDictionary(prefix: string = ''): LocalizationDict {
        const flattened: LocalizationDict = {};
        for (const [k] of Object.entries(this.dict)) {
            flattened[prefix + k] = this.Localize(k);
        }
        return flattened;
    }
}
