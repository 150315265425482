import { ActivatedRoute, Router } from '@angular/router';
import { getUrlFromRoute } from '../../guards/guard-utils';
import { DeploymentContext } from '../deployment-context/deployment-context';

export function getParsedReturnUrl(activatedRoute: ActivatedRoute): string {
  const route =
    (activatedRoute.snapshot.queryParams['returnUrl'] as string) || '/';
  const targetQueryParams =
    (activatedRoute.snapshot.queryParams['targetQueryParams'] as string) || '';
  return `${route}?${targetQueryParams}`;
}

export function navigateToLogin(
  router: Router,
  route: ActivatedRoute,
  context: DeploymentContext
): void {
  const loginUrl = router.parseUrl(
    context.ssoConfig?.enabled ? '/ping' : '/login'
  );

  let deepestRoute = route.snapshot;
  while (deepestRoute.firstChild) {
    deepestRoute = deepestRoute.firstChild;
  }

  loginUrl.queryParams['returnUrl'] = getUrlFromRoute(deepestRoute);
  router.navigateByUrl(loginUrl.toString());
}
