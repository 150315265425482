import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  UrlTree,
} from '@angular/router';
import { Logger } from '../utilities/logger/logger';
import { AuthnService } from '../services/authn/authn.service';
import {
  CompanyResolutionType,
  stringIsJforceId,
  urlIdentifierForCompany,
} from 'company-finder-common';
import { CompanyService } from '../services/company/company.service';
import { UserService } from '../services/user/user.service';
import { WebAnalyticsService } from '../services/web-analytics/web.analytics';

@Injectable()
/*
  This guard ensures the user has access to update (or review) the
  given company. This does not do the basic auth or role check - the
  auth guard should be used for that so we do not duplicate code
*/
export class CanActivateUpdateRouteGuard implements CanActivate {
  protected _logger = new Logger(this.constructor.name);

  constructor(
    private auth: AuthnService,
    private companyService: CompanyService,
    private userService: UserService,
    private router: Router,
    private webAnalyticsService: WebAnalyticsService
  ) {}

  public async canActivate(
    route: ActivatedRouteSnapshot
  ): Promise<boolean | UrlTree> {
    // This runs before the resolver, so prime the cache
    await this.userService.getCachedUser();

    if (!this.userService.hasCompaniesForUpdate) {
      // The user is authenticated, so no point bouncing to the sso.
      // They can't update this (or any) company, so send them back home.
      return this.router.parseUrl('/');
    }

    let companyToUpdate = route.params.name;

    if (!companyToUpdate && this.userService.isCompanyContact) {
      if (this.userService.companiesForUpdate.length === 1) {
        return true; // Assume the company details resolver can derive the company to load, so let it through
      } else {
        this.webAnalyticsService.trackEvent('error', {
          category: 'juniverse-integration',
          event: `too-many-companies-in-token`,
          label: `${this.auth.userId ?? ''}:${this.auth.juniverseToken}`,
        });
        this._logger.warn(
          `Navigator can not automatically pick among the JUniverse user's ${this.userService.companiesForUpdate.length} companies`
        );
      }
    }

    // If we are coming in from JUniverse, the name is not guaranteed to match the name
    // we are using so they pass the JForce Id.
    // If the segment is an ID, load the company to get the name.
    // This could eventually be cached or streamlined to be more efficient but
    // given the low number of hits we expect for this page just fetching it should
    // be fine for now.
    if (stringIsJforceId(companyToUpdate)) {
      const company = await this.companyService.getByNameOrOppId(
        companyToUpdate,
        CompanyResolutionType.Update
      );
      companyToUpdate = urlIdentifierForCompany(company.name);
    }

    let isAuthorized = false;

    // If the user is the company contact for the given company or . . .
    if (this.userService.isCompanyContact) {
      this.userService.companiesForUpdate.forEach((company: string) => {
        const urlFormattedCompanyName = urlIdentifierForCompany(company);
        if (urlFormattedCompanyName === companyToUpdate) {
          isAuthorized = true;
        }
      });

      if (!isAuthorized) {
        this.webAnalyticsService.trackEvent('error', {
          category: 'juniverse-integration',
          event: `no-matching-companies-in-token`,
          label: `${this.auth.userId ?? ''}:${this.auth.juniverseToken}`,
        });
        this._logger.warn(
          `Navigator can not automatically pick among the JUniverse user's ${this.userService.companiesForUpdate.length} companies`
        );
      }
    } else {
      this.userService.companiesForReview.forEach((company: string) => {
        if (urlIdentifierForCompany(company) === companyToUpdate) {
          isAuthorized = true;
        }
      });
    }

    // let them through. Otherwise send them to the main page (since we know they already logged in)
    return isAuthorized || this.router.parseUrl('/');
  }
}
