/* eslint-disable @typescript-eslint/no-explicit-any */
import { Deal, Funding, ICompanyUpdate } from 'company-finder-common';

// Our ORM package isn't persisting boolean properties with a value of 1 as true (or in MySQL, 1),
// but if we explicitly set it to true instead of 1, persistence works as we'd like.
// FUTURE: When we reconsile the full scope of boolean impedance issues, we shouldn't need this anymore.
export function ensureMappingForDealOrFunding(
  companyUpdate: ICompanyUpdate
): void {
  companyUpdate.dealUpdates?.forEach((dealUpdate) => {
    if (dealUpdate.isNewDeal) {
      dealUpdate.isNewDeal = true;
    }
    if (dealUpdate.isDeletedDeal) {
      dealUpdate.isDeletedDeal = true;
    }
  });
  companyUpdate.fundingUpdates?.forEach((fundingUpdate) => {
    if (fundingUpdate.isNewFunding) {
      fundingUpdate.isNewFunding = true;
    }
    if (fundingUpdate.isDeletedFunding) {
      fundingUpdate.isDeletedFunding = true;
    }
  });
}

export function formatDealOrFundingContent(
  propertyName: keyof Deal | keyof Funding,
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  propertyValue: any,
  currency?: string
): string {
  const numericValue = parseInt(propertyValue, 10);
  switch (propertyName) {
    case 'isConfidential':
    case 'isJnjDeal':
      return numericValue ? 'Yes' : 'No';
    case 'amount':
    case 'raised':
      return new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: currency || 'USD',
      }).format(numericValue);
    case 'contingentFundingAmountUsd':
    case 'securedFundingAmountUsd':
    case 'amountRaisedUsd':
      return new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
      }).format(numericValue);
    case 'announcementDate':
    case 'dateRaised':
      return propertyValue instanceof Date
        ? (propertyValue as any).toLocaleString('en-US', {
            dateStyle: 'medium',
          })
        : (new Date(propertyValue) as any).toLocaleString('en-US', {
            dateStyle: 'medium',
          });
    default:
      return propertyValue;
  }
}
