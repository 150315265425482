import {
  Component,
  Input,
  OnChanges,
  Output,
  EventEmitter,
  SimpleChanges,
  OnInit,
  AfterViewChecked,
  ViewContainerRef,
} from '@angular/core';

import { ComponentBase } from '../_component.base';
import { DeploymentContext } from '../../utilities/deployment-context/deployment-context';

// The text property of this component will be parsed into segments.  If the tag is not specified,
// the segment will just generate a text node.  Otherwise it specifies the tag to use (generally SPAN
// but can be A or possibly B for different element types).  Note that if it is not SPAN, the code
// will still build with a SPAN and then at runtime attempt to replace the SPAN with the requested
// element type, copying over attributes that were set.  This is not perfect, and may not propagate
// all behaviors.  However, it seems sufficient for what we need to do now.
// Thd ID is just a back reference so we can get the LocalizedSegment from the native element, and
// will not be set for text elements.
// The cssClass and href will be put on the generated element, and the clickFuncId is only used if there are
// multiple click events the parent component needs to handle (e.g., if this snippet had two links
// in it).  If there is only one click event, you can just bind that method to the (clickHandler)
// on the <localized-text-snippet> and it will be called (otherwise, create a handler that looks
// at the provided argument clickFuncId and calls the appropriate method).
// If a tooltip string is provided, this component will create a child DIV element to this segment's
// element (e.g., SPAN) and will attach hover tooltip behavior to it.
interface LocalizedSegment {
  text: string;
  tag?: string;
  id?: string;
  cssClass?: string;
  href?: string;
  clickFuncId?: string;
  tooltip?: string;

  // These are internal, should not be used outside of this class
  tooltipShowing?: boolean;
  clickHandler?: () => void;
  mouseEnterHandler?: () => void;
  mouseLeaveHandler?: () => void;
}

const snippetRegexp = /<snippet([^>]*)>([^<>]*)<\/snippet>/;

@Component({
  selector: 'localized-text-snippet',
  templateUrl: './localized-text-snippet.component.html',
  styleUrls: ['./localized-text-snippet.component.scss'],
})
export class LocalizedTextSnippetComponent
  extends ComponentBase
  implements OnChanges, OnInit, AfterViewChecked
{
  @Input() text: string;
  @Output() clickHandler = new EventEmitter();

  public segments: LocalizedSegment[] = [];

  public constructor(dc: DeploymentContext, private _vc: ViewContainerRef) {
    super(dc);
  }

  ngOnInit(): void {
    this.buildSegmentsFromText();
  }

  ngOnChanges(_changes: SimpleChanges): void {
    this.buildSegmentsFromText();
  }

  ngAfterViewChecked(): void {
    this.fixTagsAndPropagateAttributes();
  }

  private fixTagsAndPropagateAttributes(): void {
    const el = this._vc.element.nativeElement as HTMLElement;

    const getSegment = (element: Element): LocalizedSegment => {
      const segmentId = element.getAttribute('segmentId');
      if (segmentId) {
        return this.segments.filter((s) => s.id === segmentId)[0];
      }
      return null;
    };

    // Make sure all segments have the right tag type
    for (let i = 0; i < el.children.length; ++i) {
      let child = el.children[i];

      const segment = getSegment(child);
      if (segment) {
        const desiredSegmentTag = segment.tag.toUpperCase();
        if (child.tagName !== desiredSegmentTag) {
          const newChild = document.createElement(desiredSegmentTag);
          newChild.innerHTML = child.innerHTML;
          for (let j = 0; j < child.attributes.length; ++j) {
            const attr = child.attributes[j];
            newChild.setAttribute(attr.name, attr.value);
          }
          child.parentElement.replaceChild(newChild, child);
          child = newChild;
        }
      }
    }

    // Kind of ugly, but to allow our immediate parent to govern CSS styles in the component .scss file
    // put our children into the same component ID attribute namespace
    // ASSUMES here that Angular will put that component ID attribute into the first attribute.
    // if that is ever not true, will need to modify this code.
    const parentComponentId = this._vc.element.nativeElement.attributes[0].name;

    const doAllDescendants = (
      element: Element,
      segment: LocalizedSegment
    ): void => {
      for (let i = 0; i < element.children.length; i++) {
        const child = element.children[i];
        if (!segment) {
          segment = getSegment(child);
        }

        if (segment) {
          doAllDescendants(child, segment);

          // Add parent's component ID
          child.setAttribute(parentComponentId, '');

          // Add event listeners
          this.setEventListener(child, 'click', segment.clickHandler);
          if (segment.tooltip) {
            this.setEventListener(
              child,
              'mouseenter',
              segment.mouseEnterHandler
            );
            this.setEventListener(
              child,
              'mouseleave',
              segment.mouseLeaveHandler
            );
          }
        }
      }
    };

    doAllDescendants(el, null);
  }

  private setEventListener(
    el: Element,
    eventType: string,
    handler: () => void
  ): void {
    el.removeEventListener(eventType, handler);
    el.addEventListener(eventType, handler);
  }

  // Convert a string with blank-separated attr='value' pairs into a js object
  private attrsToDict(attrList: string): { [key: string]: string } {
    return JSON.parse(
      `{${attrList
        .replace(/([a-z]+)='((?:[^'\\]|\\.)*)'/gi, '"$1":"$2",')
        .replace(/,$/, '')}}`
    );
  }

  // Parse the text input into discrete segments to bind to the UI
  private buildSegmentsFromText() {
    this.segments = [];
    let id = 1; // Unique ID counter for non-text node ID's

    for (let remainder = this.text; remainder?.length > 0; ) {
      // By default, assume rest of string contains no <snippet> tags
      let prefix = remainder;
      let matchEnd = remainder.length;
      let snippetSegment: LocalizedSegment = null;

      const match = snippetRegexp.exec(remainder);
      if (match?.length > 1) {
        // We found a <snippet>
        const matchStart = match.index;
        matchEnd = matchStart + match[0].length;
        prefix = remainder.substring(0, matchStart);
        const attrs = match[1];
        const dict = this.attrsToDict(attrs);
        snippetSegment = {
          text: match[2],
          id: `S${id++}`,
          tag: 'span',
        };
        Object.assign(snippetSegment, dict);

        // Assign these once so we can remove them-- if we create new objects each time, we can't remove easily
        snippetSegment.clickHandler = () => this.handleClick(snippetSegment);
        snippetSegment.mouseEnterHandler = () =>
          this.handleMouseEnter(snippetSegment);
        snippetSegment.mouseLeaveHandler = () =>
          this.handleMouseLeave(snippetSegment);
      }
      if (prefix?.length > 0) {
        this.segments.push({
          text: prefix,
        });
      }
      if (snippetSegment) {
        this.segments.push(snippetSegment);
      }
      remainder = remainder.substring(matchEnd);
    }
  }

  // Called for any click events here- will fire the clickHandler event Output binding
  public handleClick(segment: LocalizedSegment): void {
    this.clickHandler.emit(segment.clickFuncId);
  }

  // Really only bound for segments with a tooltip value
  public handleMouseEnter(segment: LocalizedSegment): void {
    segment.tooltipShowing = true;
  }
  public handleMouseLeave(segment: LocalizedSegment): void {
    segment.tooltipShowing = false;
  }
}
