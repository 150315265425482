import { Component, ViewChild } from '@angular/core';
import { SearchBarComponent } from '../../../search-bar/search-bar.component';
import { SelfUpdateMode } from '../../company-update.interface';
import { CompanyUpdateService } from '../../services/company-update.service';
import { Router } from '@angular/router';
import { ComponentBase } from '../../../../_common/components/_component.base';
import { DeploymentContext } from '../../../../_common/utilities/deployment-context/deployment-context';
import { AuthnService } from '../../../../_common/services/authn/authn.service';
@Component({
  selector: 'update-header',
  templateUrl: './update-header.component.html',
  styleUrls: ['./update-header.component.scss'],
})
export class UpdateHeaderComponent extends ComponentBase {
  public get selfUpdateMode(): SelfUpdateMode {
    return this._companyUpdateService.selfUpdateMode;
  }
  public SelfUpdateModes = SelfUpdateMode;

  @ViewChild(SearchBarComponent)
  public searchBar: SearchBarComponent;

  constructor(
    dc: DeploymentContext,
    private _router: Router,
    private _authnService: AuthnService,
    private _companyUpdateService: CompanyUpdateService
  ) {
    super(dc);
  }

  public goHome(): void {
    this._router.navigate(['/']);
  }

  public get userId(): string {
    return this._authnService.userId;
  }
}
