import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ComponentBase } from '../../../../_common/components/_component.base';

import { ReviewNotification } from 'company-finder-common';
import { UserService } from '../../../../_common/services/user/user.service';
import { AuthnService } from '../../../../_common/services/authn/authn.service';
import { DeploymentContext } from '../../../../_common/utilities/deployment-context/deployment-context';

@Component({
  selector: 'navigation-panel',
  templateUrl: './navigation-panel.component.html',
  styleUrls: ['./navigation-panel.component.scss'],
})
export class NavigationPanelComponent extends ComponentBase {
  // public properties
  @Input() public isFollowEnabled: boolean;
  @Input() public unseenEventCount: number;
  @Input() public updateCount: number;
  @Input() public reviews: ReviewNotification[];
  @Output() public goHome: EventEmitter<void> = new EventEmitter<void>();
  @Output() public goToCompanyUpdate: EventEmitter<number> =
    new EventEmitter<number>();
  @Output() public goToMyUpdates: EventEmitter<void> = new EventEmitter<void>();
  @Output() public goToPreferences: EventEmitter<number> =
    new EventEmitter<number>();
  @Output() public showPendingUpdates: EventEmitter<void> =
    new EventEmitter<void>();

  public constructor(
    dc: DeploymentContext,
    protected userService: UserService,
    protected authnService: AuthnService
  ) {
    super(dc);
  }

  public get isReviewer(): boolean {
    return this.userService.isReviewer;
  }

  public get isCompanyContact(): boolean {
    return this.userService.isCompanyContact;
  }

  public get isInternal(): boolean {
    return this.authnService.isInternal;
  }

  // public methods
  public emitGoHome(): void {
    this.goHome.emit();
  }

  public emitGoToCompanyUpdate(index: number): void {
    this.goToCompanyUpdate.emit(index);
  }

  public emitGoToPreferences(): void {
    this.goToPreferences.emit();
  }

  public emitGoToMyUpdates(): void {
    this.goToMyUpdates.emit();
  }

  public emitShowPendingUpdates(): void {
    this.showPendingUpdates.emit();
  }
}
