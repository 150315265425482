import { Component, Input, Output, EventEmitter } from '@angular/core';

type ActionButtonType =
  | 'submit'
  | 'cancel'
  | 'delete'
  | 'restore'
  | 'approve'
  | 'decline';

@Component({
  selector: 'action-button',
  templateUrl: './action-button.component.html',
  styleUrls: ['./action-button.component.scss'],
})
export class ActionButtonComponent {
  @Input() type: ActionButtonType;
  @Input() disabled = false;
  @Input() hidden = false;
  @Output() buttonClick = new EventEmitter();

  public handleClick(): void {
    this.buttonClick.emit();
  }
}
