import { Component, Input } from '@angular/core';

import { ComponentBase } from '../_component.base';

// service imports
import {
  Breadcrumb,
  BreadcrumbsService,
} from '../../services/breadcrumbs/breadcrumbs.service';
import { DeploymentContext } from '../../utilities/deployment-context/deployment-context';

@Component({
  selector: 'breadcrumbs',
  templateUrl: './breadcrumbs.component.html',
  styleUrls: ['./breadcrumbs.component.scss'],
})
export class BreadcrumbsComponent extends ComponentBase {
  @Input()
  public separatorIconPath: string;

  public constructor(
    dc: DeploymentContext,
    private _breadcrumbService: BreadcrumbsService
  ) {
    super(dc);
  }

  // public getters
  public get breadcrumbs(): Breadcrumb[] {
    // ADJQ-1071: The AKQA redesign provides an Explore navigation affordance separately, and removes
    // it from the breadcrumbs. The easiest thing for us to do is filter out the Explore (root) breadcrumb
    // visually, but leave it available for use by others. Its action, BreadcrumbsService, does a few
    // things for us that is nice to have centralized.
    return this._breadcrumbService.breadcrumbs.filter(
      (bc) => bc !== this._breadcrumbService.rootBreadcrumb
    );
  }

  public get isShowingBreadcrumbs(): boolean {
    return this._breadcrumbService.breadcrumbs.length > 1;
  }

  // public methods
  public performBreadcrumbAction(breadcrumb: Breadcrumb): void {
    if (breadcrumb.action) {
      breadcrumb.action();
    }
  }
}
