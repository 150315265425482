import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';

import { Subject } from 'rxjs';

import { LogoSize } from '../../../_common/constants/LogoSize';
import { ReviewNotification } from 'company-finder-common';

import moment from 'moment';
import { ComponentBase } from '../../../_common/components/_component.base';
import { DeploymentContext } from '../../../_common/utilities/deployment-context/deployment-context';

@Component({
  selector: 'review-modal',
  templateUrl: './review-modal.component.html',
  styleUrls: ['./review-modal.component.scss'],
})
export class ReviewModalComponent extends ComponentBase {
  @Input()
  public showModal: boolean;

  @Input()
  public reviews: ReviewNotification[];

  public closingSubject = new Subject();

  public size = LogoSize.Medium;

  public constructor(dc: DeploymentContext, private _router: Router) {
    super(dc);
  }

  // public methods
  public close(): void {
    this.closingSubject.next();
  }

  public formatDate(date: Date): string {
    const datePart = moment.utc(date).local().format('MMM DD, YYYY').toString();
    const timePart = moment.utc(date).local().format('h:mm A').toString();
    return datePart + ' at ' + timePart;
  }

  public goToCompanyUpdate(companyUrlName: string): void {
    this.close();
    this._router.navigate(['company', 'update', companyUrlName]);
  }

  public get hasReviews(): boolean {
    return this.reviews?.length > 0;
  }
}
