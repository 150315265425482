import _ from 'lodash';

export class TimeZoneUtils {
    public static get MIN_TZ(): number {
        return -11;
    }
    public static get MAX_TZ(): number {
        return 12;
    }

    public static getCurrentTimeZoneOffset(
        allowHalfTz: boolean = true,
        allowSpecialTz: boolean = true
    ): number {
        let timeZone: number = -(new Date().getTimezoneOffset() / 60);
        timeZone = allowHalfTz ? timeZone : Math.round(timeZone);
        timeZone = allowSpecialTz
            ? timeZone
            : TimeZoneUtils.normalizeTimeZone(timeZone);
        return timeZone;
    }

    public static getTimeZoneForTime(goalTimeIn24HrFormat: number): number {
        if (goalTimeIn24HrFormat < 0 || goalTimeIn24HrFormat > 23) {
            return null;
        }

        return _.find(
            this.currentTimesForAllTimeZones(),
            (x) => x.timeInHours === goalTimeIn24HrFormat
        ).timeZoneOffset;
    }

    public static currentTimesForAllTimeZones(): TimeForTimeZone[] {
        const currentTimeZone: number = TimeZoneUtils.getCurrentTimeZoneOffset(
            false,
            false
        );
        const currentTimeInHrs: number = new Date().getHours();
        let timeForTimeZone: number =
            currentTimeInHrs - (currentTimeZone - TimeZoneUtils.MIN_TZ);
        const timesForAllTimeZones: TimeForTimeZone[] = [];

        for (
            let timeZone: number = TimeZoneUtils.MIN_TZ;
            timeZone <= TimeZoneUtils.MAX_TZ;
            timeZone++
        ) {
            timesForAllTimeZones.push({
                timeZoneOffset: timeZone,
                timeInHours:
                    timeForTimeZone <= 23
                        ? timeForTimeZone
                        : timeForTimeZone % 24,
            } as TimeForTimeZone);
            timeForTimeZone++;
        }

        return timesForAllTimeZones;
    }

    public static getTimeZoneName(tzOffset: number): string {
        return `UTC${(tzOffset >= 0 ? '+' : '') + tzOffset.toString()}`;
    }

    private static normalizeTimeZone(timeZone: number) {
        // we don't care about "half" time zones such as UTC+03:30
        let correctedUserTimeZone: number = Math.round(timeZone);

        // we don't care about "special" time zones such as UTC+13 or UTC+14
        correctedUserTimeZone =
            correctedUserTimeZone > TimeZoneUtils.MAX_TZ
                ? TimeZoneUtils.MAX_TZ
                : correctedUserTimeZone < TimeZoneUtils.MIN_TZ
                ? TimeZoneUtils.MIN_TZ
                : correctedUserTimeZone;

        return correctedUserTimeZone;
    }
}

export interface TimeForTimeZone {
    timeZoneOffset: number;
    timeInHours: number;
}
