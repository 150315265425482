import {
  Component,
  OnChanges,
  SimpleChanges,
  Input,
  Output,
  EventEmitter,
  ContentChild,
  TemplateRef,
  ElementRef,
  ViewChild,
} from '@angular/core';
import {
  IUser,
  LocalizedTextIds,
  SelectionEventData,
} from 'company-finder-common';
import _ from 'lodash';
import { ComponentBase } from '../../../_common/components/_component.base';
import { DeploymentContext } from '../../../_common/utilities/deployment-context/deployment-context';
import {
  ActionModalComponent,
  ActionResult,
  ActionOptions,
} from '../action-modal/action-modal.component';

@Component({
  selector: 'picker-modal',
  templateUrl: './picker-modal.component.html',
  styleUrls: ['./picker-modal.component.scss'],
})
export class PickerModalComponent extends ComponentBase implements OnChanges {
  // private properties
  private _itemsMatched: unknown[] = [];
  private _customItems: unknown[] = [];
  private _selectedItems: unknown[] = [];
  private saveProgressIndicatorDelay =
    this._deploymentContext.progressIndicator.delay;
  private lastSearchTermSubmitted = null;

  public constructor(dc: DeploymentContext) {
    super(dc);
  }

  // Invoked when filter text (search) changes, and once at startup with empty string
  // If instance supports Custom (like tags), when no match, can put up
  // a modal (within a modal), and add to its custom list.  Then,
  // filterFunc() will add the custom list to whatever it was going
  // to return, along with setting the flag custom: true on the
  // object.
  @Input()
  public filterFunc: (filterString: string) => Promise<unknown[]>;

  // Returns true if item1 is same item as item2
  @Input()
  public compareFunc: (item1: unknown, item2: unknown) => boolean;

  @Input()
  public itemVisibilityFunc: (item1: unknown) => boolean;

  @Input()
  public modalTitle: string;

  @Input()
  public blurb: string;

  @Input()
  public customItemsTitle: string;

  @Input()
  public titleGlyph: string;

  @Input()
  public filterPlaceholder: string;

  @Input()
  public initialSearchText: string;

  @Input()
  public actionVerbText = 'Add';

  @Input()
  public objectsTypeName = 'object';

  @Input()
  public isOnBoardingWizard: boolean;

  @Input()
  public user: IUser;

  @Input()
  public wizardStep: number;

  @Input()
  public totalMatchedCompanies: number;

  @Input()
  showModal: boolean;

  @Output()
  showModalChange = new EventEmitter<boolean>();

  @Output()
  wizardAction = new EventEmitter<ActionResult>();

  @ViewChild('searchText', { static: true })
  private _searchTextElement: ElementRef;

  @Output() action = new EventEmitter();
  @Output() select = new EventEmitter();

  @ContentChild('itemTemplate') itemTemplate: TemplateRef<ElementRef>;
  @ContentChild('noItemMatchTemplate')
  noItemMatchTemplate: TemplateRef<ElementRef>;
  @ViewChild(ActionModalComponent, { static: true })
  modal: ActionModalComponent;

  public get selectedItems(): unknown[] {
    return this._selectedItems;
  }

  public get matchedItems(): unknown[] {
    return this._itemsMatched;
  }

  public get matchedNonCustomItems(): unknown[] {
    return this._itemsMatched;
  }

  public get customItems(): unknown[] {
    return this._customItems;
  }

  public get submitActionText(): string {
    return this.selectedItems.length > 1
      ? `${this.actionVerbText} ${this.selectedItems.length} Selected`
      : `${this.actionVerbText} Selected`;
  }

  public get actionOptions(): ActionOptions[] {
    return [
      {
        actionResult: ActionResult.Submit,
        displayText: this.submitActionText,
        disabled: this.selectedItems.length === 0,
      },
      {
        actionResult: ActionResult.Cancel,
        displayText: this.Loc(LocalizedTextIds.Cancel),
        disabled: false,
      },
    ];
  }

  public get wizardActionOptions(): ActionOptions[] {
    return [
      {
        actionResult: ActionResult.Continue,
        displayText: this.Loc(LocalizedTextIds.PickerModalAdd),
        disabled: this.selectedItems.length === 0,
      },
      {
        actionResult: ActionResult.Skip,
        displayText: this.Loc(LocalizedTextIds.PickerModalSkip),
        disabled: false,
      },
    ];
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.showModal) {
      if (!changes.showModal.previousValue && changes.showModal.currentValue) {
        // When transitioning from closed to open, reset the modal
        this._itemsMatched = [];
        this._selectedItems = [];
        // Throttle back progress indicator while this modal is up, so screen doesn't flash while autocompleting
        // This is pretty hacky.  Better approaches might be:
        //  Add some url pattern of API calls to interceptor to skip progress indication for
        //  Fetch all companies and do local filtering by name (would need some fulltext / indexing help)
        this._deploymentContext.progressIndicator.delay = 5000;

        // Run filter with initial expression to start.
        // Some instances (e.g., Company) will no-op if not enough letters
        // provided.  Others (e.g., Tags) will simply yield the full
        // set of unfiltered values.
        this.onSearchChange(this.initialSearchText || '');

        // Set initial focus
        setTimeout(() => {
          this._searchTextElement.nativeElement.focus();
        });

        this.modal.open();
      }
    }
  }

  public async onClearClicked(): Promise<void> {
    this._searchTextElement.nativeElement.value = '';
    this.onSearchChange('');
  }

  public async onSearchChange(newSearchText: string): Promise<void> {
    this.lastSearchTermSubmitted = newSearchText;

    const results = await this.filterFunc(newSearchText);

    // At this point we have results.  Make sure they are the results from the most recent search before populating UI with them
    // since asynchronous results could be processed out of order (and don't want to waste time processing old results)
    if (this.lastSearchTermSubmitted === newSearchText) {
      this._itemsMatched = results;
    }
  }

  // Item can be either an object (e.g., SectorWithCountAndCanonicalString) or just a canonical string to find by
  public findSelected(item: unknown | string): unknown {
    return this._selectedItems.find((anItem) => {
      if (!anItem) {
        return !item;
      }
      if (!item) {
        return false;
      }
      return this.compareFunc(anItem, item);
    });
  }

  // Item can be either an object (e.g., SectorWithCountAndCanonicalString) or just a canonical string to find by
  public isSelected(item: unknown | string): boolean {
    return this.findSelected(item) != null;
  }

  public isItemVisible(item: unknown | string): boolean {
    return !this.itemVisibilityFunc || this.itemVisibilityFunc(item);
  }

  // Item can be either an object (e.g., SectorWithCountAndCanonicalString) or just a canonical string to find by
  public toggleSelection(item: unknown | string): void {
    const selected = this.findSelected(item);

    if (selected == null) {
      this.selectItem(item);
    } else {
      this.deselectItem(selected);
    }

    // Give specific implementation a chance to modify selection
    // Let them know what was selected or deselected
    // They can invoke methods to modify selection in response.
    this.select.emit({
      selectedItems: this._selectedItems,
      select: selected ? null : item,
      deselect: selected,
    } as SelectionEventData);
  }

  public onClose(actionResult: ActionResult): void {
    const toAdd =
      actionResult === ActionResult.Submit ||
      actionResult === ActionResult.Continue
        ? this._selectedItems
        : [];
    this.action.emit(toAdd);
    // When the modal closes, reset the progressIndicator delay
    this._deploymentContext.progressIndicator.delay =
      this.saveProgressIndicatorDelay;
    this._searchTextElement.nativeElement.value = '';
    this.showModalChange.emit(false);
    if (this.isOnBoardingWizard) {
      this.wizardAction.emit(actionResult);
    }
  }

  public addCustomItem(item: unknown): void {
    this._customItems.push(item);
    this._selectedItems.push(item);
  }

  // Item must be an object (not a string)
  public selectItem(item: unknown): void {
    if (!this.isSelected(item)) {
      this._selectedItems.push(item);
    }
  }

  // Item can be either an object (e.g., SectorWithCountAndCanonicalString) or just a canonical string to find by
  public deselectItem(selected: unknown | string): void {
    if (_.isString(selected)) {
      selected = this.findSelected(selected);
    }
    if (selected) {
      _.pull(this._selectedItems, selected);
    }
  }

  public get noItemMatched(): boolean {
    return (
      !!this.lastSearchTermSubmitted &&
      this.lastSearchTermSubmitted.trim().length > 0 &&
      this.matchedItems.length === 0 &&
      _.findIndex(this._customItems, (ci) =>
        this.compareFunc(ci, this.lastSearchTermSubmitted)
      ) < 0
    );
  }
}
