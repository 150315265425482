import { Injectable, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { DeploymentContext } from '../../utilities/deployment-context/deployment-context';

@Injectable({ providedIn: 'root' })
export class SeoService {
  constructor(
    @Inject(DOCUMENT) private _document: Document,
    private deploymentContext: DeploymentContext
  ) {}

  public addCanonicalTag(urlPath: string): void {
    let linkTag = this._document.head.querySelector('link[rel="canonical"]');

    if (!linkTag) {
      linkTag = this._document.createElement('link');
      linkTag.setAttribute('rel', 'canonical');
      this._document.head.appendChild(linkTag);
    }

    linkTag.setAttribute(
      'href',
      `${this.deploymentContext.hostingSiteUrl}${urlPath}`
    );
  }
}
