import { Component } from '@angular/core';

import { ComponentBase } from '../_component.base';
import { DeploymentContext } from '../../utilities/deployment-context/deployment-context';

@Component({
  selector: 'site-header',
  templateUrl: './site-header.component.html',
  styleUrls: ['./site-header.component.scss'],
})
export class SiteHeaderComponent extends ComponentBase {
  title = 'SiteHeader';
  public constructor(dc: DeploymentContext) {
    super(dc);
  }
}
