import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';

import { ComponentBase } from '../../_common/components/_component.base';

// model imports
import { Filter, TagWithCount } from 'company-finder-common';

// service imports
import {
  Breadcrumb,
  BreadcrumbsService,
} from '../../_common/services/breadcrumbs/breadcrumbs.service';
import { CompanyService } from '../../_common/services/company/company.service';
import { DeploymentContext } from '../../_common/utilities/deployment-context/deployment-context';
import { FilterComponent } from '../filter/filter.component';
import { SearchService } from '../../_common/services/search/search.service';
import { WebAnalyticsService } from '../../_common/services/web-analytics/web.analytics';
import { Summary } from '../../_common/utilities/summary/summary';

@Component({
  selector: 'main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss'],
})
export class MainComponent extends ComponentBase implements OnDestroy, OnInit {
  // public properties
  public comprehensiveSummary: Summary;
  public activeSummary: Summary;
  public tagCounts: TagWithCount[];
  @ViewChild('filterComponent', { static: true })
  public filterComponent: FilterComponent;

  // private properties
  private _breadcrumb: Breadcrumb;

  public constructor(
    dc: DeploymentContext,
    private _breadcrumbsService: BreadcrumbsService,
    private _companyService: CompanyService,
    private _router: Router,
    private _searchService: SearchService,
    private _webAnalyticsService: WebAnalyticsService
  ) {
    super(dc);
  }

  // public getters
  public get filter(): Filter {
    return this._searchService.filter;
  }

  public get filterSubject(): Subject<Filter> {
    return this._searchService.filterSubject;
  }

  private async loadSearch(): Promise<void> {
    const result = await this._searchService.searchAggregate();
    this.activeSummary = new Summary(result);
  }

  // public methods
  public async ngOnInit(): Promise<void> {
    await this.initializeData(true);
    this.addSubscription(
      this.filterSubject.subscribe(async () => {
        // NOTE: This does not trigger navigation to search results.
        this.loadSearch();
      })
    );

    this._breadcrumbsService.clearBreadcrumbs();
    this._breadcrumb = this._breadcrumbsService.rootBreadcrumb;
    // FUTURE: This action implementation completely replaces, but with very similar content,
    //  what the BreadcrumbService constructor itself established.  Would be nice to avoid duplicate logic if possible.
    //  This implementation is used the breadcrumbs are used on a MainComponent view (e.g Landing Page or Search Results).
    this._breadcrumb.action = async () => {
      this._webAnalyticsService.trackBreadcrumbNavigation(
        this._breadcrumb.name,
        this._router
      );

      if (
        this._deploymentContext.rawConfig.behavior
          .clearFilterUponNavigateToExplore
      ) {
        this._searchService.filter.clear();
      }

      // Clear the search results so we're showing the overview screen.
      this.clearSearchResults();
      // Hack off any other crumbs after this one.
      this._breadcrumbsService.setCurrentBreadcrumb(this._breadcrumb);
      // Reapply an empty search term and the current filter
      await this.initializeData(false);
      this._router.navigate(['/']);
    };
    this._breadcrumbsService.updateBreadcrumb(this._breadcrumb);
  }

  public ngOnDestroy(): void {
    if (this._subscriptions) {
      this._subscriptions.unsubscribe();
      this._subscriptions = undefined;
    }
  }

  public clearSearchResults(clearPredicate: boolean = true): void {
    if (clearPredicate) {
      this._searchService.clearSearchPredicate();
    }
    this._deploymentContext.ensureScrolledToTop();
  }

  // private methods
  private async getActiveData(): Promise<void> {
    const result = await this._companyService.searchAndFilterAggregate(
      '',
      0,
      0,
      this.filter
    );
    this.activeSummary = new Summary(result);
  }

  private async initializeData(isComprehensive: boolean): Promise<void> {
    // Per Brittany: Never want search terms to be preserved when entering the Overview screen
    //  either via Breadcrumb (cleared separately) or via browser back button (cleared by the logic below)
    this._searchService.clearSearchPredicate();

    this.tagCounts = await this._searchService.getTagCounts();

    if (!isComprehensive) {
      await this.getActiveData();
    } else {
      this.activeSummary = this._deploymentContext.comprehensiveSummary;
    }
  }
}
