import { ModelBase } from './_model.base';

export class Deal extends ModelBase {
    public dealId: string;
    public dealParty: string;
    public dealType: string;
    public amount: string;
    public amountCurrency: string;
    public contingentAmount: string;
    public contingentFundingAmountUsd: string;
    public securedFundingAmountUsd: string;
    public announcementDate: Date;
    public isConfidential: boolean;
    public isJnjDeal: boolean;
    public opportunityIdPrimary: string;
    public accountId: string;
    public isDeleted: boolean;
    public comments: string;
}
