export enum LocalizedTextIds {
    AppNameQualifier = 'AppNameQualifier',
    AppLongName = 'AppLongName',
    AppShortName = 'AppShortName',
    AppCompanyLongName = 'AppCompanyLongName',
    AppCompanyShortName = 'AppCompanyShortName',
    AcrossApp = 'AcrossApp',
    AcrossaSector = 'AcrossaSector',
    AcrossLocations = 'AcrossLocations',
    AcrossInitiatives = 'AcrossInitiatives',
    CompaniesbytheNumbers = 'CompaniesbytheNumbers',
    Following = 'Following',
    MyUpdatesAllUpdates = 'MyUpdatesAllUpdates',
    MyUpdatesCoProvidedUpdates = 'MyUpdatesCoProvidedUpdates',
    MyUpdatesInTheNews = 'MyUpdatesInTheNews',
    MyUpdatesNewCompanies = 'MyUpdatesNewCompanies',
    Preferences = 'Preferences',
    MyUpdatesMySearchQueries = 'MyUpdatesMySearchQueries',
    MyUpdatesSectorsSubSectors = 'MyUpdatesSectorsSubSectors',
    MyUpdatesJLABSLocations = 'MyUpdatesJLABSLocations',
    MyUpdatesBlueKnightCompanies = 'MyUpdatesBlueKnightCompanies',
    MyUpdatesShowingUpdatesFor = 'MyUpdatesShowingUpdatesFor',
    MyUpdatesShowingUpdatesFor$one = 'MyUpdatesShowingUpdatesFor$one',
    MyUpdatesShowingUpdatesFor$other = 'MyUpdatesShowingUpdatesFor$other',
    ActionModalReceiveUpdates = 'ActionModalReceiveUpdates',
    ActionModalReceiveUpdates$one = 'ActionModalReceiveUpdates$one',
    ActionModalReceiveUpdates$other = 'ActionModalReceiveUpdates$other',
    QFCAwardee = 'QFCAwardee',
    BlueKnight = 'BlueKnight',
    BlueKnightTm = 'BlueKnightTm',
    On = 'On',
    Off = 'Off',
    JnjToastClickHereToReload = 'JnjToastClickHereToReload',
    JnjLogoutSuccessToastYouHaveBeen = 'JnjLogoutSuccessToastYouHaveBeen',
    LogInOutBannerYouAreCurrently = 'LogInOutBannerYouAreCurrently',
    LogInOutBannerNeedToGoBack = 'LogInOutBannerNeedToGoBack',
    LogInOutBannerAuthenticatedAs = 'LogInOutBannerAuthenticatedAs',
    LogInOutBannerToInternalVersion = 'LogInOutBannerToInternalVersion',
    LogInOutBannerAutheticatedToInternal = 'LogInOutBannerAutheticatedToInternal',
    LogInOutBannerSuccessfully = 'LogInOutBannerSuccessfully',
    LogIn = 'LogIn',
    LogOut = 'LogOut',
    CreateCustomTagNoTags = 'CreateCustomTagNoTags',
    CreateCustomTagWouldYouLikeToAdd = 'CreateCustomTagWouldYouLikeToAdd',
    PickerModalMatches = 'PickerModalMatches',
    PickerModalNoObjects = 'PickerModalNoObjects',
    PickerModalWeCouldntFind = 'PickerModalWeCouldntFind',
    PickerModalPleaseCheckYour = 'PickerModalPleaseCheckYour',
    PickerModalSkip = 'PickerModalSkip',
    PickerModalAdd = 'PickerModalAdd',
    TagsPickerModalCustomTags = 'TagsPickerModalCustomTags',
    FilterListBy = 'FilterListBy',
    TagsPickerModalCreateCustomTag = 'TagsPickerModalCreateCustomTag',
    TagsPickerModalAddTags = 'TagsPickerModalAddTags',
    TagsPickerModalBlurbCompanyUpdate = 'TagsPickerModalBlurbCompanyUpdate',
    TagsPickerModalBlurbCompanyEdit = 'TagsPickerModalBlurbCompanyEdit',
    TagsPickerModalEditCustom = 'TagsPickerModalEditCustom',
    TagsPickerModalApply = 'TagsPickerModalApply',
    TagsPickerModalUseThis = 'TagsPickerModalUseThis',
    ProgressIndicatorLoading = 'ProgressIndicatorLoading',
    ShowMoreLessMore = 'ShowMoreLessMore',
    ShowMoreLessLess = 'ShowMoreLessLess',
    StayInTheLoopWhatsNew = 'StayInTheLoopWhatsNew',
    StayInTheLoopCustomize = 'StayInTheLoopCustomize',
    StayInTheLoopGetStarted = 'StayInTheLoopGetStarted',
    StayInTheLoopLater = 'StayInTheLoopLater',
    StayInTheLoopLine1 = 'StayInTheLoopLine1',
    StayInTheLoopLine2 = 'StayInTheLoopLine2',
    PendingChangesGuard = 'PendingChangesGuard',
    AllCompanies = 'AllCompanies',
    BreadcrumbsSearchResults = 'BreadcrumbsSearchResults',
    CommunityAndDiversitySubtitle = 'CommunityAndDiversitySubtitle',
    CommunityAndDiversitySerial = 'CommunityAndDiversitySerial',
    CommunityAndDiversityFirst = 'CommunityAndDiversityFirst',
    CommunityAndDiversityAnyCompany = 'CommunityAndDiversityAnyCompany',
    CommunityAndDiversityFemaleLed = 'CommunityAndDiversityFemaleLed',
    CommunityAndDiversityMinorityLed = 'CommunityAndDiversityMinorityLed',
    CommunityAndDiversityIndividuals = 'CommunityAndDiversityIndividuals',
    CommunityAndDiversityNorthAmerica = 'CommunityAndDiversityNorthAmerica',
    CommunityAndDiversityNotApplicable = 'CommunityAndDiversityNotApplicable',
    CommunityAndDiversityTitle = 'CommunityAndDiversityTitle',
    StrategicCollaborationSubtitle = 'StrategicCollaborationSubtitle',
    StrategicCollaborationPubliclyTraded = 'StrategicCollaborationPubliclyTraded',
    StrategicCollaborationIPOs = 'StrategicCollaborationIPOs',
    StrategicCollaborationAcquired = 'StrategicCollaborationAcquired',
    StrategicCollaborationFinancing = 'StrategicCollaborationFinancing',
    StrategicCollaborationValue = 'StrategicCollaborationValue',
    StrategicCollaborationTitle = 'StrategicCollaborationTitle',
    QuickfireChallenge = 'QuickfireChallenge',
    ByTheNumbersLocations = 'ByTheNumbersLocations',
    ByTheNumbersLocations$one = 'ByTheNumbersLocations$one',
    ByTheNumbersLocations$other = 'ByTheNumbersLocations$other',
    ByTheNumbersLocationsAll = 'ByTheNumbersLocationsAll',
    ContactSendAMessage = 'ContactSendAMessage',
    ContactGetInTouchWith = 'ContactGetInTouchWith',
    OK = 'OK',
    NewsInTheNews = 'NewsInTheNews',
    NewsDaysAgo = 'NewsDaysAgo',
    Yesterday = 'Yesterday',
    Today = 'Today',
    CompanyDetailsStealth = 'CompanyDetailsStealth',
    New = 'New',
    Unfollow = 'Unfollow',
    Follow = 'Follow',
    Company = 'Company',
    Download = 'Download',
    DownloadPDF = 'DownloadPDF',
    DownloadWord = 'DownloadWord',
    Primary = 'Primary',
    Sector = 'Sector',
    SubSector = 'SubSector',
    Indication = 'Indication',
    SectorDetail = 'SectorDetail',
    Secondary = 'Secondary',
    CompanyDetailsLeadProductStage = 'CompanyDetailsLeadProductStage',
    CompanyDetailsDeal = 'CompanyDetailsDeal',
    Problem = 'Problem',
    Solution = 'Solution',
    Priority = 'Priority',
    CompanyDetailsInternalJJ = 'CompanyDetailsInternalJJ',
    CompanyDetailsInformationIntended = 'CompanyDetailsInformationIntended',
    CompanyDetailsInternalJJBARDA = 'CompanyDetailsInternalJJBARDA',
    CompanyDetailsInformationIntendedBARDA = 'CompanyDetailsInformationIntendedBARDA',
    KeyManagement = 'KeyManagement',
    CompanyDetailsKeyDifferentiation = 'CompanyDetailsKeyDifferentiation',
    CompanyDetailsRecentUpdate = 'CompanyDetailsRecentUpdate',
    CompanyDetailsViewMore = 'CompanyDetailsViewMore',
    CompanyDetailsInvestmentSummary = 'CompanyDetailsInvestmentSummary',
    CompanyDetailsHighestLevel = 'CompanyDetailsHighestLevel',
    CompanyDetailsTotalDollars = 'CompanyDetailsTotalDollars',
    CompanyDetailsDeals = 'CompanyDetailsDeals',
    CompanyDetailsOrganization = 'CompanyDetailsOrganization',
    CompanyDetailsAmount = 'CompanyDetailsAmount',
    CompanyDetailsAnnouced = 'CompanyDetailsAnnouced',
    CompanyDetailsType = 'CompanyDetailsType',
    CompanyDetailsConfidential = 'CompanyDetailsConfidential',
    Yes = 'Yes',
    No = 'No',
    CompanyDetailsOpportunities = 'CompanyDetailsOpportunities',
    CompanyDetailsStage = 'CompanyDetailsStage',
    CompanyDetailsCloseDate = 'CompanyDetailsCloseDate',
    CompanyDetailsDealType = 'CompanyDetailsDealType',
    Funding = 'Funding',
    CompanyDetailsFundingRound = 'CompanyDetailsFundingRound',
    CompanyDetailsDateRaised = 'CompanyDetailsDateRaised',
    CompanyDetailsFundingParty = 'CompanyDetailsFundingParty',
    CompanyDetailsCompanyObjective = 'CompanyDetailsCompanyObjective',
    CompanyDetailsApproach = 'CompanyDetailsApproach',
    CompanyDetailsAnticipated = 'CompanyDetailsAnticipated',
    CompanyDetailsMentorship = 'CompanyDetailsMentorship',
    CompanyDetailsTechnology = 'CompanyDetailsTechnology',
    CompanyDetailsValue = 'CompanyDetailsValue',
    CompanyDetailsNumberOfFuture = 'CompanyDetailsNumberOfFuture',
    CompanyDetailsPublicInfo = 'CompanyDetailsPublicInfo',
    CompanyDetailsAnd = 'CompanyDetailsAnd',
    CompanyDetailsVirtual = 'CompanyDetailsVirtual',
    CompanyDetailsAVirtualCurrent = 'CompanyDetailsAVirtualCurrent',
    CompanyDetailsACurrent = 'CompanyDetailsACurrent',
    CompanyDetailsAVirtual = 'CompanyDetailsAVirtual',
    CompanyDetailsAnOf = 'CompanyDetailsAnOf',
    CompanyDetailsTheCompany = 'CompanyDetailsTheCompany',
    CompanyDetailsWithin = 'CompanyDetailsWithin',
    CompanyDetailsInternalJJInfo = 'CompanyDetailsInternalJJInfo',
    CompanyDetailsInternalBKOverview = 'CompanyDetailsInternalBKOverview',
    CompanyDetailsInternalBKMilestones = 'CompanyDetailsInternalBKMilestones',
    ContactFor = 'ContactFor',
    RDPlansAndMilestones = 'RDPlansAndMilestones',
    MajorMilestones = 'MajorMilestones',
    MajorMilestonesQuestion = 'MajorMilestonesQuestion',
    CurrentStagePharma = 'CurrentStagePharma',
    CurrentStageMedTech = 'CurrentStageMedTech',
    CurrentStagePharmaQuestion = 'CurrentStagePharmaQuestion',
    CurrentStageMedTechQuestion = 'CurrentStageMedTechQuestion',
    AdditionalContext = 'AdditionalContext',
    AdditionalContextQuestion = 'AdditionalContextQuestion',
    AnticipatedCommercialProductYr = 'AnticipatedCommercialProductYr',
    AnticipatedCommercialProductYrQuestion = 'AnticipatedCommercialProductYrQuestion',
    RegStatus = 'RegStatus',
    RegStatusQuestion = 'RegStatusQuestion',
    NonLeadRegStatus = 'NonLeadRegStatus',
    NonLeadRegStatusQuestion = 'NonLeadRegStatusQuestion',
    IPLandscape = 'IPLandscape',
    CompositionOfMatter = 'CompositionOfMatter',
    SecuredIP = 'SecuredIP',
    FiledIP = 'FiledIP',
    AdditionalIPDetails = 'AdditionalIPDetails',
    CompositionOfMatterQuestion = 'CompositionOfMatterQuestion',
    SecuredIPQuestion = 'SecuredIPQuestion',
    FiledIPQuestion = 'FiledIPQuestion',
    AdditionalIPDetailsQuestion = 'AdditionalIPDetailsQuestion',
    FundingStage = 'FundingStage',
    FundingStageDetails = 'FundingStageDetails',
    PleaseClarify = 'PleaseClarify',
    CapitalRaised = 'CapitalRaised',
    NonDilutiveFunding = 'NonDilutiveFunding',
    DilutiveFunding = 'DilutiveFunding',
    CurrentChallenges = 'CurrentChallenges',
    BlueKnightPartnerships = 'BlueKnightPartnerships',
    BlueKnightCommercialPartnerships = 'BlueKnightCommercialPartnerships',
    BlueKnightRDPartnerships = 'BlueKnightRDPartnerships',
    BlueKnightOtherPartnerships = 'BlueKnightOtherPartnerships',
    CurrentTeamSizeQuestion = 'CurrentTeamSizeQuestion',
    AlignedTrlQuestion = 'AlignedTrlQuestion',
    OverallTrlQuestion = 'OverallTrlQuestion',
    DilutiveFundingQuestion = 'DilutiveFundingQuestion',
    NonDilutiveFundingQuestion = 'NonDilutiveFundingQuestion',
    PharmaStageQuestion = 'PharmaStageQuestion',
    MedTechStageQuestion = 'MedTechStageQuestion',
    RegulatoryApprovalQuestion = 'RegulatoryApprovalQuestion',
    OverallTrl = 'OverallTrl',
    AlignedTrl = 'AlignedTrl',
    BlueKnightPartnershipsQuestion = 'BlueKnightPartnershipsQuestion',
    FundingStageQuestion = 'FundingStageQuestion',
    GoalsAndPrioritiesQuestion = 'GoalsAndPrioritiesQuestion',
    BlueKnightInformationTechnologyReadinessLevel = 'BlueKnightInformationTechnologyReadinessLevel',
    FilterFilters = 'FilterFilters',
    FilterCrossSector = 'FilterCrossSector',
    FilterLastDays = 'FilterLastDays',
    FilterLastQuarter = 'FilterLastQuarter',
    FilterClearAll = 'FilterClearAll',
    FilterCompaniesImFollowing = 'FilterCompaniesImFollowing',
    LoginFooterPartner = 'LoginFooterPartner',
    LoginFooterUsers = 'LoginFooterUsers',
    LoginJJNetwork = 'LoginJJNetwork',
    LoginJJInternal = 'LoginJJInternal',
    Username = 'Username',
    LoginJJNetworkUsername = 'LoginJJNetworkUsername',
    Password = 'Password',
    LoginJJNetworkPassword = 'LoginJJNetworkPassword',
    LoginUserRole = 'LoginUserRole',
    LoginGoToPublic = 'LoginGoToPublic',
    Disclaimer1 = 'Disclaimer1',
    Disclaimer2 = 'Disclaimer2',
    DisclaimerAuth1 = 'DisclaimerAuth1',
    DisclaimerAuth2 = 'DisclaimerAuth2',
    DisclaimerAuth3 = 'DisclaimerAuth3',
    ReviewModalReview = 'ReviewModalReview',
    ReviewModalNoReviews = 'ReviewModalNoReviews',
    MyInterestsWhatWould = 'MyInterestsWhatWould',
    MyInterestsLetsGetStarted = 'MyInterestsLetsGetStarted',
    MyInterestsSectorAndSub = 'MyInterestsSectorAndSub',
    MyInterestsJLABS = 'MyInterestsJLABS',
    MyInterestsDontSee = 'MyInterestsDontSee',
    MyInterestsDontWorry = 'MyInterestsDontWorry',
    Continue = 'Continue',
    DealsCompaniesDealsAndPartnerships = 'DealsCompaniesDealsAndPartnerships',
    DealsJLABSCompanies = 'DealsJLABSCompanies',
    DealsJLABSCompanies$one = 'DealsJLABSCompanies$one',
    DealsJLABSCompanies$other = 'DealsJLABSCompanies$other',
    DealsDealsAndPartnerships = 'DealsDealsAndPartnerships',
    DealsDealsAndPartnerships$one = 'DealsDealsAndPartnerships$one',
    DealsDealsAndPartnerships$other = 'DealsDealsAndPartnerships$other',
    DealsDealsPartnerships = 'DealsDealsPartnerships',
    DealsDealsPartnerships$one = 'DealsDealsPartnerships$one',
    DealsDealsPartnerships$other = 'DealsDealsPartnerships$other',
    DealsBetween = 'DealsBetween',
    SectorMapNoCompanies = 'SectorMapNoCompanies',
    TagCloudNoCompanies = 'TagCloudNoCompanies',
    Explore = 'Explore',
    CompanyProfile = 'CompanyProfile',
    Review = 'Review',
    SearchBarSearchCompanies = 'SearchBarSearchCompanies',
    LargeDownloadModalLarge = 'LargeDownloadModalLarge',
    LargeDownloadModalNavigator = 'LargeDownloadModalNavigator',
    LargeDownloadModalDownload = 'LargeDownloadModalDownload',
    LargeDownloadModalDownload$one = 'LargeDownloadModalDownload$one',
    LargeDownloadModalDownload$other = 'LargeDownloadModalDownload$other',
    Cancel = 'Cancel',
    SortControlSortBy = 'SortControlSortBy',
    SearchResultsSearch = 'SearchResultsSearch',
    SearchResultsOnlyShowingCompanies = 'SearchResultsOnlyShowingCompanies',
    SearchResultsMayTake = 'SearchResultsMayTake',
    DownloadCSV = 'DownloadCSV',
    SearchResultsSorry = 'SearchResultsSorry',
    SearchResultsSorryEnd = 'SearchResultsSorryEnd',
    SearchResultsPortfolioSearch = 'SearchResultsPortfolioSearch',
    Authenticating = 'Authenticating',
    Add = 'Add',
    Edit = 'Edit',
    SavedSearchSummaryCompaniesMatched = 'SavedSearchSummaryCompaniesMatched',
    SavedSearchSummaryCompaniesMatched$one = 'SavedSearchSummaryCompaniesMatched$one',
    SavedSearchSummaryCompaniesMatched$other = 'SavedSearchSummaryCompaniesMatched$other',
    SavedSearchCriteriaSelected = 'SavedSearchCriteriaSelected',
    SavedSearchCriteriaSelected$one = 'SavedSearchCriteriaSelected$one',
    SavedSearchCriteriaSelected$other = 'SavedSearchCriteriaSelected$other',
    SavedSearchModalCreate = 'SavedSearchModalCreate',
    SavedSearchModalEdit = 'SavedSearchModalEdit',
    Submit = 'Submit',
    Delete = 'Delete',
    SavedSearchModalMatch1 = 'SavedSearchModalMatch1',
    SavedSearchModalMatch2 = 'SavedSearchModalMatch2',
    SavedSearchModalMatch3 = 'SavedSearchModalMatch3',
    Location = 'Location',
    SectorAndSubSector = 'SectorAndSubSector',
    Status = 'Status',
    CompanyStatus = 'CompanyStatus',
    LocationStatus = 'LocationStatus',
    SavedSearchModalCompaniesCurrently = 'SavedSearchModalCompaniesCurrently',
    SavedSearchModalCompaniesCurrently$one = 'SavedSearchModalCompaniesCurrently$one',
    SavedSearchModalCompaniesCurrently$other = 'SavedSearchModalCompaniesCurrently$other',
    SavedSearchModalThisNumber = 'SavedSearchModalThisNumber',
    CompaniesPickerModalAddCompanies = 'CompaniesPickerModalAddCompanies',
    CompaniesPickerModalCompaniesInclude = 'CompaniesPickerModalCompaniesInclude',
    CompaniesPickerModalFilterList = 'CompaniesPickerModalFilterList',
    LocationsPickerModalAddLocations = 'LocationsPickerModalAddLocations',
    LocationsPickerModalOurLocation = 'LocationsPickerModalOurLocation',
    LocationsPickerModalFilterList = 'LocationsPickerModalFilterList',
    NotificationsModalJLABSNavigator = 'NotificationsModalJLABSNavigator',
    NotificationsModalNotificationsYouWill = 'NotificationsModalNotificationsYouWill',
    NotificationsModalCompanyProvided = 'NotificationsModalCompanyProvided',
    NotificationsModalJLABSCompany = 'NotificationsModalJLABSCompany',
    NotificationsModalJLABSCompaniesIn = 'NotificationsModalJLABSCompaniesIn',
    NotificationsModalCompanyMentions = 'NotificationsModalCompanyMentions',
    NotificationsModalNewCompanies = 'NotificationsModalNewCompanies',
    NotificationsModalCompanyOfficially = 'NotificationsModalCompanyOfficially',
    Sectors = 'Sectors',
    Tags = 'Tags',
    Companies = 'Companies',
    Locations = 'Locations',
    Searches = 'Searches',
    SavedSearches = 'SavedSearches',
    NotificationsModalSave = 'NotificationsModalSave',
    NotificationsModalPrefix = 'NotificationsModalPrefix',
    NotificationsModalEveryday = 'NotificationsModalEveryday',
    NotificationsModalEveryMonday = 'NotificationsModalEveryMonday',
    NotificationsModalEveryOtherMonday = 'NotificationsModalEveryOtherMonday',
    NotificationsModalFirstMondayMonth = 'NotificationsModalFirstMondayMonth',
    NotificationsModalFirstMondayQuarter = 'NotificationsModalFirstMondayQuarter',
    NotificationsModalFirstMondayYear = 'NotificationsModalFirstMondayYear',
    NotificationsModalNoAlerts = 'NotificationsModalNoAlerts',
    SectorPickerModalAddSectors = 'SectorPickerModalAddSectors',
    SectorPickerModalTheseSectors = 'SectorPickerModalTheseSectors',
    FilterListBySector = 'FilterListBySector',
    PreferencesBackToMy = 'PreferencesBackToMy',
    Notifications = 'Notifications',
    PreferencesCompanyProvided = 'PreferencesCompanyProvided',
    PreferencesBuildYourFollowing = 'PreferencesBuildYourFollowing',
    PreferencesYoullReceive = 'PreferencesYoullReceive',
    PreferencesYoullReceive$one = 'PreferencesYoullReceive$one',
    PreferencesYoullReceive$other = 'PreferencesYoullReceive$other',
    PreferencesThisNumber = 'PreferencesThisNumber',
    PreferencesNoSectors = 'PreferencesNoSectors',
    PreferencesAutomaticallyInclude = 'PreferencesAutomaticallyInclude',
    PreferencesNoCompanies = 'PreferencesNoCompanies',
    PreferencesNoLocations = 'PreferencesNoLocations',
    PreferencesGetNotified = 'PreferencesGetNotified',
    PreferencesNoSearchQueries = 'PreferencesNoSearchQueries',
    PreferencesSavedSearch = 'PreferencesSavedSearch',
    PreferencesSavedSearch$one = 'PreferencesSavedSearch$one',
    PreferencesSavedSearch$other = 'PreferencesSavedSearch$other',
    AppTheCompany = 'AppTheCompany',
    CompanyCount = 'CompanyCount',
    CompanyCount$one = 'CompanyCount$one',
    CompanyCount$other = 'CompanyCount$other',
    Decline = 'Decline',
    Declined = 'Declined',
    Approve = 'Approve',
    Approved = 'Approved',
    SummaryGlad = 'SummaryGlad',
    SummaryJLABSCompanies = 'SummaryJLABSCompanies',
    SummaryCrossSector = 'SummaryCrossSector',
    QFCAwardees = 'QFCAwardees',
    BlueKnightInformationInformation = 'BlueKnightInformationInformation',
    BlueKnightInformationAlignment = 'BlueKnightInformationAlignment',
    BlueKnightGoal = 'BlueKnightGoal',
    BlueKnightAlignment1 = 'BlueKnightAlignment1',
    BlueKnightAlignment2 = 'BlueKnightAlignment2',
    BlueKnightInformationDescribeThe = 'BlueKnightInformationDescribeThe',
    BlueKnightInformationCurrentPlans = 'BlueKnightInformationCurrentPlans',
    BlueKnightInformationMentorshipNeeds = 'BlueKnightInformationMentorshipNeeds',
    BlueKnightInformationDescribeMentorship = 'BlueKnightInformationDescribeMentorship',
    BlueKnightInformationChallenges = 'BlueKnightInformationChallenges',
    BlueKnightInformationDescribeChallenges = 'BlueKnightInformationDescribeChallenges',
    BlueKnightInformationPriorities = 'BlueKnightInformationPriorities',
    BlueKnightInformationDescribePriorities = 'BlueKnightInformationDescribePriorities',
    BlueKnightInformationOverviewOfTRLs = 'BlueKnightInformationOverviewOfTRLs',
    BlueKnightInformationCompanyEmployees = 'BlueKnightInformationCompanyEmployees',
    BlueKnightInformationCurrentSize = 'BlueKnightInformationCurrentSize',
    BlueKnightInformationCompositionAndGrowth = 'BlueKnightInformationCompositionAndGrowth',
    BlueKnightInformationCompositionAndGrowthHeader = 'BlueKnightInformationCompositionAndGrowthHeader',
    Added = 'Added',
    Deleted = 'Deleted',
    Currency = 'Currency',
    CompanyUpdateConfirmationSuccessfullySubmitted = 'CompanyUpdateConfirmationSuccessfullySubmitted',
    CompanyUpdateConfirmationSuccessfullyApproved = 'CompanyUpdateConfirmationSuccessfullyApproved',
    CompanyUpdateConfirmationSuccessfullyApproved$one = 'CompanyUpdateConfirmationSuccessfullyApproved$one',
    CompanyUpdateConfirmationSuccessfullyApproved$other = 'CompanyUpdateConfirmationSuccessfullyApproved$other',
    CompanyUpdateConfirmationSuccessfullyDeclined = 'CompanyUpdateConfirmationSuccessfullyDeclined',
    CompanyUpdateConfirmationSuccessfullyDeclined$one = 'CompanyUpdateConfirmationSuccessfullyDeclined$one',
    CompanyUpdateConfirmationSuccessfullyDeclined$other = 'CompanyUpdateConfirmationSuccessfullyDeclined$other',
    CompanyUpdateConfirmationSuccessfullyApprovedAndDeclined = 'CompanyUpdateConfirmationSuccessfullyApprovedAndDeclined',
    CompanyUpdateConfirmationSuccessfullyApprovedAndDeclined$one = 'CompanyUpdateConfirmationSuccessfullyApprovedAndDeclined$one',
    CompanyUpdateConfirmationSuccessfullyApprovedAndDeclined$other = 'CompanyUpdateConfirmationSuccessfullyApprovedAndDeclined$other',
    CompanyUpdateConfirmationApprovedEdits = 'CompanyUpdateConfirmationApprovedEdits',
    CompanyUpdateConfirmationFirstTime = 'CompanyUpdateConfirmationFirstTime',
    CompanyUpdateConfirmationCompanyMinority = 'CompanyUpdateConfirmationCompanyMinority',
    CompanyUpdateConfirmationCompanyFemale = 'CompanyUpdateConfirmationCompanyFemale',
    Edited = 'Edited',
    CompanyUpdateConfirmationReturnToMyCompany = 'CompanyUpdateConfirmationReturnToMyCompany',
    CompanyUpdateConfirmationReturnToExplore = 'CompanyUpdateConfirmationReturnToExplore',
    CompanyUpdateConfirmationReviewNext = 'CompanyUpdateConfirmationReviewNext',
    CompanyUpdateModalAnythingYouShare = 'CompanyUpdateModalAnythingYouShare',
    Save = 'Save',
    CompanyUpdateModalThisEntryWillBe = 'CompanyUpdateModalThisEntryWillBe',
    CompanyUpdateModalRestoreEntry = 'CompanyUpdateModalRestoreEntry',
    CompanyUpdateModalThisEntryHasBeen = 'CompanyUpdateModalThisEntryHasBeen',
    ConferencesAndEvents = 'ConferencesAndEvents',
    ConferencesAndEventsQuestion = 'ConferencesAndEventsQuestion',
    Deal = 'Deal',
    DealModalIsThisADeal = 'DealModalIsThisADeal',
    DealModalDealParty = 'DealModalDealParty',
    DealModalJJDivision = 'DealModalJJDivision',
    DealModalOrganizationTheDeal = 'DealModalOrganizationTheDeal',
    DealModalDealType = 'DealModalDealType',
    DealModalSelectType = 'DealModalSelectType',
    DealModalContingentFunding = 'DealModalContingentFunding',
    DealModalDollarsThatAreContingent = 'DealModalDollarsThatAreContingent',
    DealModalSecureFunding = 'DealModalSecureFunding',
    DealModalDollarsThatAreGuaranteed = 'DealModalDollarsThatAreGuaranteed',
    DealModalNoNeedToEnter = 'DealModalNoNeedToEnter',
    DealModalAnnoucementDate = 'DealModalAnnoucementDate',
    DealModalDateOrApproximate = 'DealModalDateOrApproximate',
    DealModalSelectCurrency = 'DealModalSelectCurrency',
    DealModalDealComments = 'DealModalDealComments',
    DealModalThisInformation = 'DealModalThisInformation',
    Bytes = 'Bytes',
    KB = 'KB',
    MB = 'MB',
    GB = 'GB',
    TB = 'TB',
    PB = 'PB',
    EB = 'EB',
    ZB = 'ZB',
    YB = 'YB',
    Required = 'Required',
    EditItemOf = 'EditItemOf',
    EditItemAddTags = 'EditItemAddTags',
    Unchanged = 'Unchanged',
    Removed = 'Removed',
    TheseItemsRemoved = 'TheseItemsRemoved',
    TheseItemsAdded = 'TheseItemsAdded',
    EditItemTaggingIsAGreat = 'EditItemTaggingIsAGreat',
    EditItemYourLogo = 'EditItemYourLogo',
    EditItemChoose = 'EditItemChoose',
    EditItemOnlyJPG = 'EditItemOnlyJPG',
    EditItemLogoImage = 'EditItemLogoImage',
    ViewOriginal = 'ViewOriginal',
    HideOriginal = 'HideOriginal',
    EditsSummaryEditsToReview = 'EditsSummaryEditsToReview',
    EditsSummaryEditsApproved = 'EditsSummaryEditsApproved',
    EditsSummaryEditsDeclined = 'EditsSummaryEditsDeclined',
    EditsSummaryDeclineAll = 'EditsSummaryDeclineAll',
    EditsSummaryApproveAll = 'EditsSummaryApproveAll',
    EditsSummaryNextTab = 'EditsSummaryNextTab',
    FundingModalFundingStatus = 'FundingModalFundingStatus',
    FundingModalFundingRound = 'FundingModalFundingRound',
    FundingModalOrganization = 'FundingModalOrganization',
    FundingModalSelectRound = 'FundingModalSelectRound',
    FundingModalFundingParty = 'FundingModalFundingParty',
    FundingModalAmountRaised = 'FundingModalAmountRaised',
    FundingModalDollarsThatWere = 'FundingModalDollarsThatWere',
    FundingModalDateRaised = 'FundingModalDateRaised',
    FundingModalDateOrApproximate = 'FundingModalDateOrApproximate',
    FundingModalFundingComments = 'FundingModalFundingComments',
    FundingModalThisInformation = 'FundingModalThisInformation',
    UpdateHeaderGoToNavigator = 'UpdateHeaderGoToNavigator',
    UpdateHeaderAuthenticatedAs = 'UpdateHeaderAuthenticatedAs',
    JnjInformationOtherCountry = 'JnjInformationOtherCountry',
    JnjInformationOtherAllCountries = 'JnjInformationOtherAllCountries',
    JnjInformationItsImportant = 'JnjInformationItsImportant',
    JnjInformationKeyManagement = 'JnjInformationKeyManagement',
    JnjInformationFirstTimeEntrepreneur = 'JnjInformationFirstTimeEntrepreneur',
    JnjInformationPubliclyShared = 'JnjInformationPubliclyShared',
    Secured = 'Secured',
    Contingent = 'Contingent',
    JnjInformationDiversityEquity = 'JnjInformationDiversityEquity',
    Country = 'Country',
    JnjInformationWhereIsYourCompany = 'JnjInformationWhereIsYourCompany',
    JnjInformationAboriginalPeoplesMeans = 'JnjInformationAboriginalPeoplesMeans',
    JnjInformationLeadershipDiversity = 'JnjInformationLeadershipDiversity',
    JnjInformationPleaseCheckCLevel = 'JnjInformationPleaseCheckCLevel',
    JnjInformationWeAreAsking = 'JnjInformationWeAreAsking',
    JnjInformationBoardAdvisor = 'JnjInformationBoardAdvisor',
    JnjInformationPleaseCheckBoard = 'JnjInformationPleaseCheckBoard',
    JnjInformationWomenLedOrg = 'JnjInformationWomenLedOrg',
    JnjInformationDoYouHaveAnyCLevel = 'JnjInformationDoYouHaveAnyCLevel',
    JnjInformationWomenLedBoard = 'JnjInformationWomenLedBoard',
    JnjInformationDoYouHaveAnyBoard = 'JnjInformationDoYouHaveAnyBoard',
    NotificationsSavedEdits = 'NotificationsSavedEdits',
    NotificationsYourEditsSaved = 'NotificationsYourEditsSaved',
    NotificationsRetractDraft = 'NotificationsRetractDraft',
    NotificationsPending = 'NotificationsPending',
    NotificationsYourEditsSubmitted = 'NotificationsYourEditsSubmitted',
    NotificationsRetractSubmission = 'NotificationsRetractSubmission',
    Reviewed = 'Reviewed',
    NotificationsYourEditsSubmittedReviewedApproved = 'NotificationsYourEditsSubmittedReviewedApproved',
    NotificationsYourEditsSubmittedReviewedDeclined = 'NotificationsYourEditsSubmittedReviewedDeclined',
    NotificationsTheTeam = 'NotificationsTheTeam',
    PublicInformationTheAreas = 'PublicInformationTheAreas',
    CompanyName = 'CompanyName',
    Logo = 'Logo',
    Website = 'Website',
    Description = 'Description',
    CompanyContact = 'CompanyContact',
    PublicInformationReachOut = 'PublicInformationReachOut',
    ContactTitle = 'ContactTitle',
    CompanyUpdateServiceNewUpdate = 'CompanyUpdateServiceNewUpdate',
    CompanyUpdateReviewEdits = 'CompanyUpdateReviewEdits',
    CompanyUpdateTheSubmitEdits = 'CompanyUpdateTheSubmitEdits',
    CompanyUpdateCommandDisabled = 'CompanyUpdateCommandDisabled',
    CompanyUpdateViewJLABS = 'CompanyUpdateViewJLABS',
    CompanyUpdateBasedOn = 'CompanyUpdateBasedOn',
    PrimarySector = 'PrimarySector',
    JPAL = 'JPAL',
    SiteHead = 'SiteHead',
    CompanyUpdateShareAnUpdate = 'CompanyUpdateShareAnUpdate',
    CompanyUpdateTheseNonConfidential = 'CompanyUpdateTheseNonConfidential',
    CompanyUpdateAnyUpdate = 'CompanyUpdateAnyUpdate',
    CompanyUpdateShareCompany = 'CompanyUpdateShareCompany',
    CompanyUpdatePleaseNote = 'CompanyUpdatePleaseNote',
    CompanyUpdateBeforeSubmitting = 'CompanyUpdateBeforeSubmitting',
    CompanyUpdateCommentsToCompany = 'CompanyUpdateCommentsToCompany',
    CompanyUpdateAnyComments = 'CompanyUpdateAnyComments',
    CompanyUpdateShareWith = 'CompanyUpdateShareWith',
    CompanyUpdateDoYouFeel = 'CompanyUpdateDoYouFeel',
    CompanyUpdateFieldsNeed = 'CompanyUpdateFieldsNeed',
    CompanyUpdateFieldsNeed$one = 'CompanyUpdateFieldsNeed$one',
    CompanyUpdateFieldsNeed$other = 'CompanyUpdateFieldsNeed$other',
    RevertChanges = 'RevertChanges',
    SaveEdits = 'SaveEdits',
    SubmitEdits = 'SubmitEdits',
    NextTab = 'NextTab',
    UpdateComponentBaseAnticipated = 'UpdateComponentBaseAnticipated',
    DocGenDescription = 'DocGenDescription',
    DocGenDescription$one = 'DocGenDescription$one',
    DocGenDescription$other = 'DocGenDescription$other',
    DocGenConfidential = 'DocGenConfidential',
    DocGenCopyrightNotice = 'DocGenCopyrightNotice',
    DocGenInternalData = 'DocGenInternalData',
    DocGenSector = 'DocGenSector',
    DocGenSubsector = 'DocGenSubsector',
    DocGenTargetTechProduct = 'DocGenTargetTechProduct',
    Geography = 'Geography',
    ExploreCompanies = 'ExploreCompanies',
    Relevancy = 'Relevancy',
    Newest = 'Newest',
    Alphabetic = 'Alphabetic',
    ViewMore = 'ViewMore',
    SecondarySector = 'SecondarySector',
    DEIBlackAfricanAmerican = 'DEIBlackAfricanAmerican',
    DEIHispanic = 'DEIHispanic',
    DEIAsian = 'DEIAsian',
    DEINativeAmerican = 'DEINativeAmerican',
    DEIPacificIslander = 'DEIPacificIslander',
    DEIAboriginalPeoples = 'DEIAboriginalPeoples',
    DEIBlack = 'DEIBlack',
    DEIChinese = 'DEIChinese',
    DEIFilipinos = 'DEIFilipinos',
    DEIJapanese = 'DEIJapanese',
    DEIKorean = 'DEIKorean',
    DEILatinAmerican = 'DEILatinAmerican',
    DEISouthAsian = 'DEISouthAsian',
    DEIWestAsiansArabs = 'DEIWestAsiansArabs',
    DEIMinorityLed = 'DEIMinorityLed',
    DEINone = 'DEINone',
    DEINoBoard = 'DEINoBoard',
    NoPreviousValue = 'NoPreviousValue',
    UpdateField_amountCurrency = 'UpdateField_amountCurrency',
    UpdateField_announcementDate = 'UpdateField_announcementDate',
    UpdateField_approachUsecase = 'UpdateField_approachUsecase',
    PrimarySectorQuestion = 'PrimarySectorQuestion',
    PrimarySubsectorQuestion = 'PrimarySubsectorQuestion',
    SecondarySectorQuestion = 'SecondarySectorQuestion',
    SecondarySubSectorQuestion = 'SecondarySubSectorQuestion',
    IndicationQuestion = 'IndicationQuestion',
    IndicationPlural = 'IndicationPlural',
    UpdateField_countryForDeiReporting = 'UpdateField_countryForDeiReporting',
    UpdateField_currentRdStage = 'UpdateField_currentRdStage',
    UpdateField_dateRaised = 'UpdateField_dateRaised',
    UpdateField_dealParty = 'UpdateField_dealParty',
    UpdateField_dealType = 'UpdateField_dealType',
    UpdateField_description = 'UpdateField_description',
    UpdateField_entryExitStrategy = 'UpdateField_entryExitStrategy',
    UpdateField_firstTimeEntrepreneur = 'UpdateField_firstTimeEntrepreneur',
    UpdateField_fundingParty = 'UpdateField_fundingParty',
    UpdateField_fundingRound = 'UpdateField_fundingRound',
    UpdateField_isConfidential = 'UpdateField_isConfidential',
    UpdateField_isJnjDeal = 'UpdateField_isJnjDeal',
    UpdateField_keyMgmtAndAdvBm = 'UpdateField_keyMgmtAndAdvBm',
    UpdateField_leadershipDiversity = 'UpdateField_leadershipDiversity',
    UpdateField_logoBase64 = 'UpdateField_logoBase64',
    UpdateField_mentorship = 'UpdateField_mentorship',
    UpdateField_minorityLed = 'UpdateField_minorityLed',
    UpdateField_name = 'UpdateField_name',
    UpdateField_problem = 'UpdateField_problem',
    UpdateField_progressUpdate = 'UpdateField_progressUpdate',
    UpdateField_raised = 'UpdateField_raised',
    UpdateField_raisedCurrency = 'UpdateField_raisedCurrency',
    UpdateField_solution = 'UpdateField_solution',
    UpdateField_tags = 'UpdateField_tags',
    UpdateField_website = 'UpdateField_website',
    UpdateField_womenLedOrgLeadership = 'UpdateField_womenLedOrgLeadership',
    UpdateField_womenLedBoardOfDirectorsOrEquiv = 'UpdateField_womenLedBoardOfDirectorsOrEquiv',
    UpdateField_womenLed = 'UpdateField_womenLed',
    PrimaryIndication = 'PrimaryIndication',
    SecondaryIndication = 'SecondaryIndication',
    PrimarySubSector = 'PrimarySubSector',
    SecondarySubSector = 'SecondarySubSector',
    PrimarySectorDetail = 'PrimarySectorDetail',
    SecondarySectorDetail = 'SecondarySectorDetail',
    URL = 'URL',
    RecentUpdateDate = 'RecentUpdateDate',
    RecentUpdateSubject = 'RecentUpdateSubject',
    RecentUpdatedDescription = 'RecentUpdatedDescription',
    MostRecentCompanyUpdateApproverEmail = 'MostRecentCompanyUpdateApproverEmail',
    MostRecentCompanyUpdateComment = 'MostRecentCompanyUpdateComment',
    MostRecentCompanyUpdateDate = 'MostRecentCompanyUpdateDate',
    CommencementDate = 'CommencementDate',
    GraduationDate = 'GraduationDate',
    ExportFileName = 'ExportFileName',
    JNJErrorHandlerSomethingUnexpected = 'JNJErrorHandlerSomethingUnexpected',
    JNJErrorHandlerError = 'JNJErrorHandlerError',
}
