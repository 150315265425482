import { Component, Input } from '@angular/core';
import { Company, LocalizedTextIds, Location } from 'company-finder-common';

import { ComponentBase } from '../_component.base';
import { StatusItem } from '../status-list/StatusItem';

@Component({
  selector: 'company-location-status',
  templateUrl: './company-location-status.component.html',
  styleUrls: ['./company-location-status.component.scss'],
})
export class CompanyLocationStatusComponent extends ComponentBase {
  // public properties
  @Input()
  public company: Company;
  @Input()
  /** When true, adds a top line showing only company-level statuses */
  public includeCompanyStatusRow = true;
  @Input()
  public isUpdateScreen = false;
  @Input()
  public useSeparator = true;
  @Input()
  /** When false, the city is move to a separate line from the location */
  public singleLine = true;

  private _sortedLocation: Location[];
  private get locationsToDisplay(): Location[] {
    if (!this._sortedLocation) {
      this._sortedLocation = this.company.locations?.sort((a, b) =>
        this.getSortString(a).localeCompare(this.getSortString(b))
      );
    }

    return this._sortedLocation?.length > 0
      ? this._sortedLocation
      : [new Location()];
  }

  private getSortString(location: Location): string {
    return location.city ?? location.name;
  }

  public get showSeparator(): boolean {
    return !this.narrowScreen && this.useSeparator && this.hasLocationStatus;
  }

  private get hasLocationStatus(): boolean {
    return this.locationsToDisplay.some(
      (loc) => loc.locationStatuses?.length > 0
    );
  }

  private get hasCompanyStatus(): boolean {
    return (
      this.company.statuses?.length > 0 ||
      this.company.isQfcWinner ||
      this.company.isBlueKnight
    );
  }

  public get companyStatusItems(): StatusItem[] {
    const statuses = this.company.statuses
      ?.filter(
        (status) =>
          !(
            this._deploymentContext.companyStatusIcon &&
            status === this._deploymentContext.statusForCompanyIcon
          )
      )
      .map(
        (status) =>
          new StatusItem(
            this._deploymentContext.getStatusDisplayName(status),
            this._deploymentContext.getStatusTooltip(status)
          )
      );

    if (this.company.isQfcWinner) {
      statuses.push(new StatusItem(this.Loc(LocalizedTextIds.QFCAwardee)));
    }

    if (this.company.isBlueKnight) {
      statuses.push(
        new StatusItem(
          this.Loc(LocalizedTextIds.BlueKnight),
          null,
          '/assets/detail/blue-knight.png',
          18,
          14
        )
      );
    }

    return statuses;
  }

  public statusItems(location: Location): StatusItem[] {
    const statuses =
      location.locationStatuses?.map(
        (status) =>
          new StatusItem(
            this._deploymentContext.getStatusDisplayName(status),
            this._deploymentContext.getStatusTooltip(status)
          )
      ) ?? [];

    const sorted = [...new Set(statuses)].sort((a, b) =>
      this.compareStatuses(a.displayText, b.displayText)
    );
    return sorted;
  }

  public compareStatuses(a: string, b: string): number {
    const aOrder = this._deploymentContext.getGroupOrderByDisplay(a);
    const bOrder = this._deploymentContext.getGroupOrderByDisplay(b);

    if (aOrder < bOrder) {
      return -1;
    } else if (bOrder < aOrder) {
      return 1;
    }

    return 0;
  }

  public getLocationDisplayValue(location: Location): string {
    return !location.city || location.name === location.city
      ? location.name
      : `${location.city} (${location.name})`;
  }
}
