import { Component, Input } from '@angular/core';

import { ComponentBase } from '../_component.base';

import { LogoSize } from '../../constants/LogoSize';

// model imports
import { Company, Constants } from 'company-finder-common';
import { DeploymentContext } from '../../utilities/deployment-context/deployment-context';

@Component({
  selector: 'company-logo',
  templateUrl: './company-logo.component.html',
  styleUrls: ['./company-logo.component.scss'],
})
export class CompanyLogoComponent extends ComponentBase {
  @Input()
  public company: Company;
  @Input()
  public logoBase64: string;
  @Input()
  public show?: boolean = undefined;
  @Input()
  public size: LogoSize = LogoSize.ExtraLarge;

  constructor(dc: DeploymentContext) {
    super(dc);
  }

  // public getters
  public get logoClass(): string {
    switch (this.size) {
      case LogoSize.Small:
        return 'cf_company-logo-small';
      case LogoSize.SmallPlus:
        return 'cf_company-logo-small-plus';
      case LogoSize.Medium:
        return 'cf_company-logo-medium';
      case LogoSize.Large:
        return 'cf_company-logo-large';
      case LogoSize.ExtraLarge:
        return 'cf_company-logo';
    }
    return 'unknown';
  }

  public get logoAsUrl(): {
    'background-image': string;
  } {
    // If logo provided directly, can't use async fetch, since these bytes may be nowhere else, so use inline
    if (this.logoBase64) {
      return {
        'background-image': `url(data:image/gif;base64,${this.logoBase64})`,
      };
    }

    // the hash of the logo ensures client side invalidation if the logo changes.
    // Allow for global caching of the logos for improved performance.
    // Security by obscurity means anonymous users can't "discover" internal-only logos.
    return this.company?.logoBase64Hash
      ? {
          'background-image': `url(/api/v1/company/logo/${encodeURIComponent(
            this.company.id
          )}?${Constants.PARAM_CACHEABLE}=true&${this.company.logoBase64Hash})`,
        }
      : null;
  }
}
