import { Component, Input, Output, EventEmitter } from '@angular/core';
import { SavedSearchBaseComponent } from './saved-search-base.component';
import { SearchService } from '../../../../_common/services/search/search.service';
import { ViewType } from '../../../../_common/utilities/preferences/preferences.util';
import { CompanyService } from '../../../../_common/services/company/company.service';
import { DeploymentContext } from '../../../../_common/utilities/deployment-context/deployment-context';

@Component({
  selector: 'saved-search-summary',
  templateUrl: './saved-search-summary.component.html',
  styleUrls: ['./saved-search-summary.component.scss'],
})
export class SavedSearchSummaryComponent extends SavedSearchBaseComponent {
  @Input() searchTitle: string;
  @Input() viewType: ViewType;
  // Event emitted when the add item button is clicked. Emits nothing if addItemTemplate is used
  @Output() editSavedSearch = new EventEmitter<void>();

  constructor(
    dc: DeploymentContext,
    searchService: SearchService,
    companyService: CompanyService
  ) {
    super(dc, searchService, companyService);
  }

  public get isModalView(): boolean {
    return this.viewType === ViewType.Modal;
  }

  // public methods
  public editQuery(): void {
    this.editSavedSearch.emit();
  }
}
