import { ModelBase } from './_model.base';

export class Contact extends ModelBase {
    public name: string;
    public title: string;
    public mobilePhone: string;
    public email: string;
    public accountId: string;
}

export class CoOwnerContact extends ModelBase {
    public name: string;
    public opportunityId: string;
    public email: string;
}