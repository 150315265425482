export class PrimarySubSectorCount {
  public primarySubSector: string;
  public count = 0;

  constructor(primarySubSector: string) {
    this.primarySubSector = primarySubSector;
  }
}

export class PrimarySectorCount {
  public count = 0;
  private primarySubSectorCounts: PrimarySubSectorCount[] = new Array<PrimarySubSectorCount>();

  constructor(public primarySector: string) {
  }

  public get descendingPrimarySubSectorCounts(): PrimarySubSectorCount[] {
    return this.primarySubSectorCounts.sort((a, b) => b.count - a.count);
  }

  public get maxPrimarySubSectorCount(): number {
    // eslint-disable-next-line prefer-spread
    return Math.max.apply(
      Math,
      this.primarySubSectorCounts?.map((o) => o.count)
    );
  }

  public getPrimarySubSectorCount(subSector: string): PrimarySubSectorCount {
    let theEntry = this.primarySubSectorCounts.find(
      (entry) => entry.primarySubSector === subSector
    );

    if (!theEntry) {
      theEntry = new PrimarySubSectorCount(subSector);
      this.primarySubSectorCounts.push(theEntry);
    }
    return theEntry;
  }
}

export interface NameValuePair { 
  name: string; 
  value: number 
}

export interface SubsectorCounts extends NameValuePair { 
  dealCount: number;
}

export interface NameWithChildNameValues {
  name: string;
  value: number;
  children: NameValuePair[];
}

export interface SectorCounts extends NameWithChildNameValues {
  dealCount: number;
  children: SubsectorCounts[];
  stageCounts: StageCount[];
}

export class StageCount {
  public stage: string;
  public count: number;
}

