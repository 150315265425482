import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DeploymentContext } from '../../_common/utilities/deployment-context/deployment-context';
import { AuthnService } from '../../_common/services/authn/authn.service';
import { ComponentBase } from '../../_common/components/_component.base';
import { navigateToLogin } from '../../_common/utilities/navigation/navigation.utils';

@Component({
  selector: 'login-footer',
  templateUrl: './login-footer.component.html',
  styleUrls: ['./login-footer.component.scss'],
})
export class LoginFooterComponent extends ComponentBase {
  public constructor(
    dc: DeploymentContext,
    private router: Router,
    private route: ActivatedRoute,
    private authnService: AuthnService,
    private context: DeploymentContext
  ) {
    super(dc);
  }

  public get showLoginButtons(): boolean {
    return !this.authnService.isAuthenticated;
  }

  public loginJnJ(): void {
    navigateToLogin(this.router, this.route, this._deploymentContext);
  }

  public loginJuniverse(): void {
    this.context.setJuniverseHref();
  }
}
