import {
  Input,
  Directive,
  EventEmitter,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';

import { ComponentBase } from '../../../_common/components/_component.base';
import {
  compareItemsWithCanonicalString,
  IUser,
  ObjectWithCanonicalString,
} from 'company-finder-common';
import { PickerModalComponent } from './picker-modal.component';
import { ActionResult } from '../action-modal/action-modal.component';
import { UserService } from '../../../_common/services/user/user.service';
import { updateCountOfMatchedCompaniesForWizard } from '../../utilities/preferences/preferences.util';
import { DeploymentContext } from '../../utilities/deployment-context/deployment-context';

@Directive()
export abstract class PickerModalBaseComponent<T>
  extends ComponentBase
  implements OnInit
{
  private _showModal: boolean;

  @Input()
  public totalMatchedCompanies: number;

  @Output() action = new EventEmitter<unknown[]>();
  @Output() wizardAction = new EventEmitter<ActionResult>();

  @Input()
  public isOnBoardingWizard = false;

  @Input()
  public set showModal(shown: boolean) {
    this._showModal = shown;
    this.updateCompanyCountForWizard(this._userService.getCachedUserSync());
    if (shown) {
      this.triggerCDAndDo(() => this.onShown());
    }
  }
  public get showModal(): boolean {
    return this._showModal;
  }

  @Input()
  public user: IUser;

  @Input() initialSearchText = '';
  @ViewChild(PickerModalComponent, { static: true })
  pickerModal: PickerModalComponent;

  public doFilter = this.doSearch.bind(this);

  public constructor(
    dc: DeploymentContext,
    protected _userService: UserService
  ) {
    super(dc);
  }

  public async ngOnInit(): Promise<void> {
    this.updateCompanyCountForWizard(this._userService.getCachedUserSync());
  }

  public doAction(itemsToAdd: unknown[]): void {
    this.action.emit(itemsToAdd);
  }

  public doWizardAction(actionResult: ActionResult): void {
    this.wizardAction.emit(actionResult);
  }

  public onShown(): void {}

  public abstract doSearch(newSearchText: string): Promise<T[]>;

  public compareItemsWithCanonicalString(
    l1: ObjectWithCanonicalString | string,
    l2: ObjectWithCanonicalString | string
  ): boolean {
    return compareItemsWithCanonicalString(l1, l2);
  }

  // protected methods
  protected async updateCompanyCountForWizard(user: IUser): Promise<void> {
    if (!this.isOnBoardingWizard) {
      return;
    }
    const matchedCompanyCounts = await updateCountOfMatchedCompaniesForWizard(
      this._userService,
      user
    );
    this.totalMatchedCompanies =
      matchedCompanyCounts?.matchedTotal ?? this.totalMatchedCompanies;
  }
}
