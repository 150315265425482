export class AuthnStrings {
  // header keys
  public static readonly RESPONSE_HEADER_TOKEN_KEY = 'x-accesstoken';

  // local storage keys
  public static readonly KEY_AUTH_TOKEN = 'authToken';
  public static readonly KEY_LAST_REFRESH_TIME = 'lastRefreshTime';
  public static readonly KEY_JNJ_EVER_LOGGED_IN = 'jnjEverLoggedIn'; // if ever logged in
  public static readonly KEY_JNJ_WAS_LOGGED_IN = 'jnjWasLoggedIn'; // if currently should be logged in, cleared by logout
  public static readonly KEY_JNJ_LOGGED_OUT = 'jnjLoggedOut'; // created after logout, prevents accidental login through intercepted token

  public static readonly INTERNAL_OVERRIDE_TOKEN = 'internalOverrideToken';
  public static readonly INTERNAL_SUPER_OVERRIDE_TOKEN =
    'internalSuperOverrideToken';
  public static readonly BARDA_OVERRIDE_TOKEN = 'bardaOverrideToken';
  public static readonly COMPANY_OVERRIDE_TOKEN = 'companyOverrideToken';
  public static readonly PARTNER_OVERRIDE_TOKEN = 'partnerOverrideToken';
  public static readonly INVALID_OVERRIDE_TOKEN = 'invalidOverrideToken';
}
