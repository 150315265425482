import { Component, Input } from '@angular/core';

import { ReviewEditsService } from '../../services/review-edits.service';

// model imports
import { Deal, Funding } from 'company-finder-common';

@Component({
  selector: 'review-deal-funding',
  templateUrl: './review-deal-funding.component.html',
  styleUrls: ['./review-deal-funding.component.scss'],
})
export class ReviewDealFundingComponent {
  // public properties
  @Input()
  public item: Deal | Funding;

  public constructor(protected _reviewEditsService: ReviewEditsService) {}

  // public methods
  public showReviewModal(): void {
    this._reviewEditsService.showReviewModalSubject.next(this.item);
  }

  public get showFlag(): boolean {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const item = this.item as any;
    return !this._reviewEditsService.isModelReviewed(
      item.fundingId ?? item.dealId
    );
  }
}
