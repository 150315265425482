import {
  Component,
  HostListener,
  OnDestroy,
  OnInit,
  ViewChild,
  NgZone,
  ViewChildren,
  QueryList,
} from '@angular/core';
import {
  ActivatedRoute,
  Router,
  NavigationStart,
  NavigationEnd,
  RouterEvent,
} from '@angular/router';

import { ComponentBase } from '../../_common/components/_component.base';
import { SortControlComponent } from './components/sort-control/sort-control.component';
import { LargeDownloadModalComponent } from './components/large-download-modal/large-download-modal.component';

// model imports
import {
  Company,
  DocumentGeneration,
  LocalizedTextIds,
  TagWithCount,
} from 'company-finder-common';

// service imports
import { AuthnService } from '../../_common/services/authn/authn.service';
import { BreadcrumbsService } from '../../_common/services/breadcrumbs/breadcrumbs.service';
import { CompanyService } from '../../_common/services/company/company.service';
import { SearchService } from '../../_common/services/search/search.service';
import { WebAnalyticsService } from '../../_common/services/web-analytics/web.analytics';
import { IframeResizerService } from '../../_common/services/iframe/iframe.resizer.service';

// utility imports
import { ApplicationContext } from '../../_common/utilities/application-context/application-context';
import { DeploymentContext } from '../../_common/utilities/deployment-context/deployment-context';
import { Summary } from '../../_common/utilities/summary/summary';
import { SearchResolverResult } from '../../_common/resolvers/search-results-resolver';
import { CompanySummaryComponent } from './components/company-summary/company-summary.component';
import { iframeResizer } from '@iframe-resizer/child';

@Component({
  selector: 'search-results',
  templateUrl: './search-results.component.html',
  styleUrls: ['./search-results.component.scss'],
})
export class SearchResultsComponent
  extends ComponentBase
  implements OnDestroy, OnInit
{
  // public properties
  public companies: Company[] = [];
  public comprehensiveSummary: Summary;
  @ViewChild('sortControl', { static: true })
  public sortControl: SortControlComponent;

  @ViewChild('largeDownloadModal')
  public modal: LargeDownloadModalComponent;

  @ViewChildren(CompanySummaryComponent)
  protected companySummaries: QueryList<CompanySummaryComponent>;
  protected get firstSummaryElement(): CompanySummaryComponent {
    return this.companySummaries?.first;
  }
  protected get footerHeight(): number {
    return this._deploymentContext.footerHeight;
  }
  protected get summaryHeight(): number {
    return this.firstSummaryElement?.height ?? 0;
  }

  public showMenu = false;
  public isShowingTooltip = false;
  public tagCounts: TagWithCount[];
  public totalCompanies: number;
  public debounceScroll = true;
  public waitingOnScroll = false;
  public showAllCompanies: boolean;
  public showLargeDownloadModal = false;
  public isLoadCompleted = false;
  public completedLoads = [];
  // private properties
  private _documentGenerationConfig: DocumentGeneration;
  private scrollY: number;

  public constructor(
    dc: DeploymentContext,
    private _activatedRoute: ActivatedRoute,
    private _applicationContext: ApplicationContext,
    private _authnService: AuthnService,
    private _breadcrumbsService: BreadcrumbsService,
    private _companyService: CompanyService,
    _router: Router,
    private _searchService: SearchService,
    private _webAnalyticsService: WebAnalyticsService,
    private _scrollingService: IframeResizerService,
    private ngZone: NgZone
  ) {
    super(dc);
    this._documentGenerationConfig =
      this._deploymentContext.documentGenerationConfig;
    this.showAllCompanies = this._searchService.isAllCompanies;

    _router.events.subscribe((event: RouterEvent) => {
      // If we are navigating away, save the scroll position
      if (
        event instanceof NavigationStart &&
        event.url.indexOf('/search-results') < 0
      ) {
        _breadcrumbsService.setSearchPageOffset(
          this.getScrollY(),
          this.companies.length
        );
      } else if (
        event instanceof NavigationEnd &&
        event.url.indexOf('/search-results') >= 0
      ) {
        // coming back, restore the scroll
        this.waitingOnScroll = true;
      }
    });
  }

  public get isAuthenticated(): boolean {
    return this._authnService.isAuthenticated;
  }

  // public getters/setters
  public get badSearchPredicate(): string {
    return this._searchService.badSearchPredicate;
  }

  public set badSearchPredicate(predicate: string) {
    this._searchService.badSearchPredicate = predicate;
  }

  // public methods
  public async exportAsCsv(): Promise<void> {
    const companiesForExport = await this._companyService.companiesForExport(
      this._searchService.currentSearchPredicate,
      this._searchService.filter
    );

    this._applicationContext.saveDocument(
      this.Loc(LocalizedTextIds.ExportFileName),
      companiesForExport,
      'csv'
    );
  }

  public get isDownloadDocumentEnabled(): boolean {
    return this._documentGenerationConfig.documentDownloadEnabled || false;
  }

  public get isInternalView(): boolean {
    return this._authnService.isAuthenticated;
  }

  public async downloadWordDocument(): Promise<void> {
    this.showMenu = false;
    const response = await this._companyService.getWordDataForSearch(
      this._searchService.currentSearchPredicate,
      this._searchService.filter
    );
    this._applicationContext.saveDocument(
      this.Loc(LocalizedTextIds.SearchResultsPortfolioSearch),
      response,
      'docx'
    );

    // FUTURE: Would be nice to know how many companies were in the set exported, but that is buried a bit deeper
    this._webAnalyticsService.trackEvent('export-search-results-as-word', {
      category: 'Export',
      label: this._searchService.getSearchTermForAnalytics(),
    });
  }

  public async downloadPdfClicked(): Promise<void> {
    // PDF generation is a time-consuming operation, and exceeds a reasonable wait time,
    // and exceeds a timeout enforced by the deployment infrastructure, so apply a limit here.
    const maxCompanyCountAllowedForPdf =
      this._documentGenerationConfig.maxCompanyCountAllowedForPdf;
    if (this.totalCompanies > maxCompanyCountAllowedForPdf) {
      this.showLargeDownloadModal = true;
      return;
    }
    this.showMenu = false;
    await this.downloadPdf();
  }

  public async downloadPdf(): Promise<void> {
    const shouldPage =
      this.totalCompanies >
      this._documentGenerationConfig.maxCompanyCountAllowedForPdf;

    const sharedTimestamp = shouldPage ? Date.now() : undefined;
    for (
      let i = 0;
      i < this.totalCompanies;
      i += this._documentGenerationConfig.maxCompanyCountAllowedForPdf
    ) {
      const response = await this._companyService.getPdfDataForSearch(
        this._searchService.currentSearchPredicate,
        this._searchService.filter,
        i,
        this._documentGenerationConfig.maxCompanyCountAllowedForPdf
      );

      const pageSuffix = shouldPage
        ? ` part ${
            i / this._documentGenerationConfig.maxCompanyCountAllowedForPdf + 1
          }`
        : undefined;

      this._applicationContext.saveDocument(
        this.Loc(LocalizedTextIds.SearchResultsPortfolioSearch),
        response,
        'pdf',
        pageSuffix,
        sharedTimestamp
      );
    }

    // FUTURE: Would be nice to know how many companies were in the set exported, but that is buried a bit deeper
    this._webAnalyticsService.trackEvent('export-search-results-as-pdf', {
      category: 'Export',
      label: this._searchService.getSearchTermForAnalytics(),
    });
  }

  public async closeLargePdfModal(shouldDownload: boolean): Promise<void> {
    this.showLargeDownloadModal = false;
    if (shouldDownload) {
      this.downloadPdf();
    }
  }

  public ngOnInit(): void {
    // If the resizer is enabled when the ngFor adds the
    // companies one at a time to the DOM it will cause
    // performance issues and hang the page. Instead, we
    // disable the resizer while the DOM is manipulated,
    // then re-enable it when the 'last' company summary
    // is on the screen so the resizer only has to manage
    // a stable page.
    this.disableResizer();
    this.addSubscription(
      this._searchService.searchResultsSubject.subscribe(() => {
        // Update the result title as the user performs search/filter operations on the screen.
        this.showAllCompanies = this._searchService.isAllCompanies;
      })
    );

    if (this._deploymentContext.hosted()) {
      this.addSubscription(
        this._scrollingService.iframeSubject.subscribe((event) =>
          this.handleScrollEvent(event)
        )
      );
    }

    this.addSubscription(
      this._activatedRoute.data.subscribe(
        async (data: { searchResults: SearchResolverResult }) => {
          this.companies = data.searchResults.companies;
          this.totalCompanies = data.searchResults.totalCompanies;
          this.tagCounts = data.searchResults.tagCounts;
          this.comprehensiveSummary = data.searchResults.summary;
        }
      )
    );
    this.badSearchPredicate = this._searchService.badSearchPredicate;
    this.addSubscription(
      this._searchService.badSearchPredicateSubject.subscribe((predicate) => {
        this.badSearchPredicate = predicate;
      })
    );
    this.addSubscription(
      this.sortControl.sortResultsSubject.subscribe((companies) => {
        this.companies = companies;
      })
    );
  }

  public ngOnDestroy(): void {
    this._subscriptions?.unsubscribe();
    this._subscriptions = undefined;
    super.ngOnDestroy();
  }

  // ADJQ-93:  Support infinite scroll of result set at the page level as stand-alone app.
  @HostListener('window:scroll', ['$event'])
  public onScroll(_ev: UIEvent): void {
    // Per: https://techstacker.com/posts/gGyGTHysrPuuJnNBk/vanilla-javascript-detect-when-user-scrolled-to-the-bottom
    // The original site is defunct - I beleive the same content is at https://techstacker.com/javascript-detect-when-scrolled-to-bottom/
    const atBottom =
      window.innerHeight + window.scrollY >= window.document.body.offsetHeight;
    if (atBottom && this.debounceScroll) {
      this.debounceScroll = false;
      this.loadNextPage();
    }
    if (!atBottom) {
      this.debounceScroll = true;
    }
  }

  public setShowMenu(ev: MouseEvent): void {
    ev.preventDefault();
    ev.stopPropagation();

    this.showMenu = true;
  }

  public showTooltip(): void {
    this.isShowingTooltip = true;
  }

  public hideTooltip(): void {
    this.isShowingTooltip = false;
  }

  // protected methods
  @HostListener('window:click', ['$event'])
  protected onClick(): void {
    this.showMenu = false;
  }

  public loadCompleted(i: number): void {
    if (this.completedLoads[i]) {
      return;
    }

    this.completedLoads[i] = this.isLoadCompleted = true;
    this.enableResizer();

    if (this.waitingOnScroll) {
      this.waitingOnScroll = false;
      this.scroll();
    }
  }

  private scroll() {
    this._deploymentContext.scrollVertical(
      this._breadcrumbsService.searchPageOffset ?? 0
    );
  }

  // private methods
  private async loadNextPage() {
    const countPriorToPaging = this.companies.length;

    if (countPriorToPaging >= this.totalCompanies) {
      return;
    }

    this.disableResizer();
    this.isLoadCompleted = false;

    ({ companies: this.companies, totalCompaniesCount: this.totalCompanies } =
      await this._searchService.getCompanies(countPriorToPaging));

    this._webAnalyticsService.trackEvent('paged-search-results', {
      label: `sort ${this._searchService.filter.sort} - had ${countPriorToPaging}`,
      value: this.totalCompanies,
    });
  }

  private handleScrollEvent(windowProperties: iframeResizer.ParentProps) {
    this.scrollY = windowProperties.viewport.pageTop;
    const scrollPadding =
      (this._searchService.PAGE_SIZE / 3) * this.summaryHeight;

    // When the bottom of the page (excluding the footer height) is less than
    // the height of the page plus 1/3 of the summaries we load in a page.
    // trigger the scroll. In effect, with a PAGE_SIZE of 20, this should
    // trigger a scroll when there are ~7 companies plus the footer
    // below the iframe.
    const nearBottom =
      windowProperties.iframe.bottom - this.footerHeight <=
      windowProperties.viewport.height + scrollPadding;
    if (!this.waitingOnScroll && this.isLoadCompleted && nearBottom) {
      // Since this is invoked via subscribe, we need to invoke ngZone
      // so we don't lose change tracking
      this.ngZone.run(() => {
        this.loadNextPage();
      });
    }
  }

  private getScrollY(): number {
    // If hosted, our scrollY will be set by handleScrollEvent(), else use the window's
    return this.scrollY || window.scrollY;
  }
}
