import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpEvent,
  HttpResponse,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

// service/utility imports
import { DeploymentContext } from '../utilities/deployment-context/deployment-context';
import { Logger } from '../utilities/logger/logger';
import { Constants } from 'company-finder-common';

@Injectable()
export class VersionCheckInterceptor implements HttpInterceptor {

  protected _logger: Logger;
  protected _warningLogged: boolean;

  constructor(
    private _deploymentContext: DeploymentContext
  ) {
    this._logger = new Logger(this.constructor.name);
  }

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {

    // Any time a token is received, hold onto it
    return next.handle(request).pipe(
      map((event: HttpEvent<unknown>) => {
        if (event instanceof HttpResponse) {
          if (event.status === 200 || event.status === 302) {
            const apiVersion = event.headers.get(
              Constants.RESPONSE_HEADER_API_VERSION
            );
            if (this._deploymentContext.appVersion !== apiVersion) {
              if (!this._warningLogged) {
                this._logger.warn(`Mismatched client/server versions: {app: ${this._deploymentContext.appVersion}, api: ${apiVersion}}`);
                this._warningLogged = true;
                // FUTURE:
                //  Consider prompting the user to reload the app (or automatically do so)
                //  But, that would probably require defining compatiblity ranges (major/minor/etc) to avoid too agressive prompting
            }
            }
          }
        }
        return event;
      })
    );
  }

}
