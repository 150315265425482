export enum UserRoles {
    // Anonymous = 'Anonymous', Given that a user role is tied to a user, anonymous can't ever be valid.
    Internal = 'Internal',
    InternalSuper = 'InternalSuper',
    Company = 'Company',
    Partner = 'Partner',
    BARDA = 'BARDA',
}

export class RoleGroup extends Array<UserRoles> {
    public constructor(roles: UserRoles[] = []) {
        super();
        for (const role of roles) {
            this.push(role);
        }
    }

    public containsRole(role: UserRoles): boolean {
        return this.includes(role);
    }
}
export class RoleGroups {
    public static AuthenticatedUsers = new RoleGroup([
        UserRoles.Internal,
        UserRoles.InternalSuper,
        UserRoles.Company,
        UserRoles.Partner,
        UserRoles.BARDA,
    ]);
    public static ExternalUsers = new RoleGroup([
        UserRoles.Company,
        UserRoles.Partner,
        UserRoles.BARDA,
    ]);
    public static InternalSuperUsers = new RoleGroup([UserRoles.InternalSuper]);
    public static InternalUsers = new RoleGroup([
        UserRoles.Internal,
        UserRoles.InternalSuper,
    ]);
    public static CompanyUsers = new RoleGroup([UserRoles.Company]);
    public static UpdateUsers = new RoleGroup([
        UserRoles.InternalSuper,
        UserRoles.Company,
    ]);
    public static InternalOrPaidUsers = new RoleGroup([
        UserRoles.Internal,
        UserRoles.InternalSuper,
        UserRoles.Partner,
        UserRoles.BARDA,
    ]);
    public static BARDAUsers = new RoleGroup([UserRoles.BARDA]);
    public static InternalOrBardaUsers = new RoleGroup([
        UserRoles.Internal,
        UserRoles.InternalSuper,
        UserRoles.BARDA,
    ]);
}
