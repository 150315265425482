export class RelayState {

    public acsUrl: string = `/acs`;

    public constructor(acsUrl?: string, returnUrl?: string) {
        this.acsUrl = acsUrl ?? `/acs`;
        this.returnUrl = returnUrl;
    }

    public toString() {
        return JSON.stringify({ acsUrl: this.acsUrl, returnUrl: this._returnUrl });
    }

    public get returnUrl() {
        return this._returnUrl;
    }

    public static fromPostBody(body: any): RelayState {
        const tempVal = JSON.parse(body.RelayState);
        return new RelayState(tempVal.acsUrl, tempVal.returnUrl);
    }

    public set returnUrl(value: string) {
        // We don't want the return URL to be empty - if they don't
        // have a target we set it to the landing page
        this._returnUrl =  value?.trim() ?? '/';
    }

    private _returnUrl: string = '/';
}
