export interface ICompanyLists {
    isCompanyContactFor: string[];
    isSiteHeadFor: string[];
    isCoOwnerFor: string[];
}   
    

export function nullOrArray(array: string[]): string[] {
    return array?.length > 0 ? array : null;
}

export class CompanyLists implements ICompanyLists {
    public isCompanyContactFor: string[] = [];
    public isSiteHeadFor: string[] = [];
    public isCoOwnerFor: string[] = [];

    constructor(
        isCompanyContactFor: string[] = [],
        isSiteHeadFor: string[] = [],
        isCoOwnerFor: string[] = []
    ) {
        this.isCompanyContactFor = isCompanyContactFor ?? [];
        this.isSiteHeadFor = isSiteHeadFor ?? [];
        this.isCoOwnerFor = isCoOwnerFor ?? [];
    }

    public clone(): CompanyLists {
        return new CompanyLists(
            this.isCompanyContactFor,
            this.isSiteHeadFor,
            this.isCoOwnerFor
        );
    }

    public get isCompanyContact(): boolean {
        return this.isCompanyContactFor?.length > 0;
    }

    public get isSiteHead(): boolean {
        return this.isSiteHeadFor?.length > 0;
    }

    public get isCoOwner(): boolean {
        return this.isCoOwnerFor?.length > 0;
    }

    public hasValue(): boolean {
        return this.isCompanyContact || this.isSiteHead || this.isCoOwner;
    }

    public clean(): void {
        this.isCompanyContactFor = nullOrArray(this.isCompanyContactFor);
        this.isSiteHeadFor = nullOrArray(this.isSiteHeadFor);
        this.isCoOwnerFor = nullOrArray(this.isCoOwnerFor);
    }
}