import { Component, Input } from '@angular/core';
import { ComponentBase } from '../../../../_common/components/_component.base';
import { Funding } from 'company-finder-common';
@Component({
  selector: 'funding-table',
  templateUrl: './funding-table.component.html',
  styleUrls: ['./funding-table.component.scss'],
})
export class FundingTableComponent extends ComponentBase {
  @Input()
  public funding: Funding[];

  @Input()
  public showConfidentialStatus = true;

  public get filteredFunding(): Funding[] {
    return this.funding.filter((funding)=> !funding.isConfidential || this.showConfidentialStatus)
  }
}
