import { NameValuePair, SectorCounts } from './PrimarySectorCount';
import { StatusWithCount } from './objectWithCount';

export class SharedStats {
    public numberOfCompanies = 0;
    public numberOfPubliclyOfferedCompanies = 0;
    public numberOfAcquiredCompanies = 0;
    public numberOfCrossSectorCompanies = 0;
    public numberOfNewCompanies = 0;
    public numberOfBlueKnights = 0;
    public numberOfQfcWinners = 0;
    public numberOfNACompanies = 0;

    public locationStatusCounts = {}; // FUTURE Better typing
    public sumTotalSecuredAndContingentAmount = 0;
    public numberOfResidentsForStatusTile = 0;
    public sectorGroups: SectorCounts[] = [];
    public allStatusesWithCounts: StatusWithCount[] = [];

    public serialEntrepreneurPercentage = 0;
    public femaleLedPercentage = 0;
    public firstTimeEntrepreneurPercentage = 0;
    public minorityLedPercentage = 0;
    public totalUniqueCompaniesWithDeals = 0;
    public tagCounts: NameValuePair[] = [];
}
