/**
 * Gets a list of all enum values for a numeric enum. Parameter typed as any because the TS
 * compiler doesn't have great suport for treating enums as objects, though that is how they
 * exist at runtime.
 * @param enumType The type of the enum.
 */
export function getNumericValues<TEnum>(enumType: any): TEnum[] {
    // Enums with string values are manifest at runtime as an object mapping string key -> string value.
    // Enums with numeric values are manifest as an object that both maps key -> numeric value
    // and numeric value -> string key. So we filter to remove the reverse mapping
    return Object.keys(enumType)
        .filter((key) => typeof enumType[key] === 'number')
        .map((key) => enumType[key] as TEnum);
}

/**
 * Gets a list of all enum values for a string enum. Parameter typed as any because the TS
 * compiler doesn't have great suport for treating enums as objects, though that is how they
 * exist at runtime.
 * @param enumType The type of the enum.
 */
export function getStringValues<TEnum>(enumType: any): TEnum[] {
    return Object.keys(enumType).map((key) => enumType[key] as TEnum);
}
