import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';

import { ComponentBase } from '../_component.base';

// service imports
import { ProgressIndicatorService } from '../../services/progress-indicator/progress-indicator.service';
import { DeploymentContext } from '../../utilities/deployment-context/deployment-context';
import { LocalizedTextIds } from 'company-finder-common';

@Component({
  selector: 'progress-indicator',
  templateUrl: './progress-indicator.component.html',
  styleUrls: ['./progress-indicator.component.scss'],
})
export class ProgressIndicatorComponent
  extends ComponentBase
  implements OnDestroy, OnInit
{
  // public properties
  @Input()
  public description = this.Loc(LocalizedTextIds.ProgressIndicatorLoading);
  @Input()
  public isStatic = false;
  public visible = false;

  // private properties
  private startOperationSubscription: Subscription;
  private completeOperationSubscription: Subscription;

  public constructor(
    dc: DeploymentContext,
    private _progressIndicatorService: ProgressIndicatorService
  ) {
    super(dc);
  }

  // public methods
  public ngOnInit(): void {
    this.startOperationSubscription =
      this._progressIndicatorService.startOperationObservable.subscribe(() => {
        this.visible = true;
      });

    this.completeOperationSubscription =
      this._progressIndicatorService.operationCompleteObservable.subscribe(
        () => {
          this.visible = false;
        }
      );
  }

  public ngOnDestroy(): void {
    this.startOperationSubscription.unsubscribe();
    this.completeOperationSubscription.unsubscribe();
  }
}
