import {
  AfterViewInit,
  Component,
  Input,
  OnDestroy,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { DeploymentContext } from '../../../../_common/utilities/deployment-context/deployment-context';
import { ReviewEditsService } from '../../services/review-edits.service';
import { UpdateComponentBaseWithEditItems } from '../../UpdateComponentBaseWithEditItems';
import { NotificationsComponent } from '../notifications/notifications.component';
import {
  Deal,
  Funding,
  ICompanyUpdate,
  IDealUpdate,
  IFundingUpdate,
  IModelEntity,
  LocalizedTextIds,
} from 'company-finder-common';
import _ from 'lodash';
import { DealModalComponent } from '../deal-modal/deal-modal.component';
import { FundingModalComponent } from '../funding-modal/funding-modal.component';
import { CompanyService } from '../../../../_common/services/company/company.service';
import { CompanyUpdateService } from '../../services/company-update.service';
import { ApproveDeclineComponent } from '../approve-decline/approve-decline.component';
import { EditablePropertyGroup } from '../../company-update.interface';
@Component({
  selector: 'jnj-information-update',
  templateUrl: './jnj-information-update.component.html',
  styleUrls: ['./jnj-information-update.component.scss'],
})
export class JnJInformationUpdateComponent
  extends UpdateComponentBaseWithEditItems
  implements OnDestroy, AfterViewInit
{
  // I'd really rather have something cleaner than passing in the component,
  // but I also don't want this current refactor to grow bigger than it
  // already needs to be. I'm adding a backlog story to revisit this, and I'll
  // add the link to it here once it exists (not doing it now in case I change my approach)
  @Input()
  public notificationsComponent: NotificationsComponent;

  @ViewChild('fundingModalComponent', { static: true })
  public fundingModalComponent: FundingModalComponent;
  @ViewChild('dealModalComponent', { static: true })
  public dealModalComponent: DealModalComponent;

  @ViewChildren(ApproveDeclineComponent)
  public approveDeclineComponents: QueryList<ApproveDeclineComponent>;

  public showFundingModal = false;
  public showDealModal = false;

  public get dealToModify(): Deal {
    return this._companyUpdateService.dealToModify;
  }

  public set dealToModify(value: Deal) {
    this._companyUpdateService.dealToModify = value;
  }

  public get fundingToModify(): Funding {
    return this._companyUpdateService.fundingToModify;
  }

  public set fundingToModify(value: Funding) {
    this._companyUpdateService.fundingToModify = value;
  }

  // Local getters to prevent having lots of code like
  // this._companyUpdateService.getCurrentUpdate().dealUpdate
  // which get a bit unweildy to read
  private get currentUpdate(): ICompanyUpdate {
    return this._companyUpdateService.getCurrentUpdate();
  }

  private get dealUpdates(): IDealUpdate[] {
    return this.currentUpdate.dealUpdates;
  }

  private get fundingUpdates(): IFundingUpdate[] {
    return this.currentUpdate.fundingUpdates;
  }

  public get haveFundingsChanged(): boolean {
    return this._companyUpdateService.haveFundingsChanged;
  }

  public set haveFundingsChanged(value: boolean) {
    this._companyUpdateService.haveFundingsChanged = value;
  }

  public get enableDiversityQuestions(): boolean {
    return this._deploymentContext.featureSwitches.enableDiversityQuestions;
  }

  public get leadershipDiversity(): string {
    const formControl = this.companyEditForm.get('leadershipDiversity');
    return formControl?.value;
  }

  public set leadershipDiversity(value: string) {
    const formControl = this.companyEditForm.get('leadershipDiversity');
    formControl?.setValue(value);
  }

  public get boardAdvisorDiversity(): string {
    const formControl = this.companyEditForm.get('boardAdvisorDiversity');
    return formControl?.value;
  }

  public set boardAdvisorDiversity(value: string) {
    const formControl = this.companyEditForm.get('boardAdvisorDiversity');
    formControl?.setValue(value);
  }

  public isAdded(obj: Deal | Funding, typeId: string): boolean {
    return (
      this._companyUpdateService.isNewDealOrFunding(obj) &&
      (!this.isCurrentUpdateApproved() ||
        !this.isDealOrFundingFromPriorUpdate(obj, typeId))
    );
  }

  public isApproved(obj: Deal | Funding, typeId: string): boolean {
    return (
      this._companyUpdateService.isNewDealOrFunding(obj) &&
      this.isCurrentUpdateApproved() &&
      this.isDealOrFundingFromPriorUpdate(obj, typeId)
    );
  }

  public constructor(
    dc: DeploymentContext,
    private _reviewEditsService: ReviewEditsService,
    _companyService: CompanyService,
    _companyUpdateService: CompanyUpdateService
  ) {
    super(dc, _companyUpdateService, _companyService);
  }

  public async ngAfterViewInit(): Promise<void> {
    this.setAllSubscriptions();
  }

  public ngOnDestroy(): void {
    this._subscriptions?.unsubscribe();
  }

  public setAllSubscriptions(): void {
    this.setReviewEditsSubscriptions();
    this.setFundingSubscriptions();
    this.setDealSubscriptions();
    super.setAllSubscriptions();
  }

  public setReviewEditsSubscriptions(): void {
    if (!this._reviewEditsService) {
      this._logger.info(`No _reviewEditsService`);
      return;
    }

    this.addSubscription(
      this._reviewEditsService.showReviewModalSubject.subscribe(
        (item: unknown) => {
          this.showDealOrFundingFromReview(item);
        }
      )
    );
  }

  public setFundingSubscriptions(): void {
    if (!this.fundingModalComponent) {
      this._logger.info(`No fundingModalComponent`);
      return;
    }

    this.addSubscription(
      this.fundingModalComponent.closingSubject.subscribe(
        async (result: { funding: Funding; isSave: boolean }) => {
          await this.closeFunding(result);
        }
      )
    );

    this.addSubscription(
      this.fundingModalComponent.approveDeclineSubject.subscribe(
        (result: { funding: Funding; isApproved: boolean }) => {
          this.showFundingModal = false;

          const approveDeclineComponent = this.approveDeclineComponents.find(
            (comp) =>
              comp.funding &&
              comp.funding.fundingId === result.funding.fundingId
          );
          if (approveDeclineComponent) {
            if (result.isApproved) {
              approveDeclineComponent.approveFunding(result.funding);
            } else {
              approveDeclineComponent.declineFunding(result.funding);
            }
          }
        }
      )
    );
  }

  public setDealSubscriptions(): void {
    if (!this.dealModalComponent) {
      this._logger.info(`No dealModalComponent`);
      return;
    }

    this.addSubscription(
      this.dealModalComponent.closingSubject.subscribe(
        async (result: { deal: Deal; isSave: boolean }) => {
          await this.closeDeal(result);
        }
      )
    );
    this.addSubscription(
      this.dealModalComponent.approveDeclineSubject.subscribe(
        (result: { deal: Deal; isApproved: boolean }) => {
          this.showDealModal = false;

          const approveDeclineComponent = this.approveDeclineComponents.find(
            (comp) => comp.deal && comp.deal.dealId === result.deal.dealId
          );
          if (approveDeclineComponent) {
            if (result.isApproved) {
              approveDeclineComponent.approveDeal(result.deal);
            } else {
              approveDeclineComponent.declineDeal(result.deal);
            }
          }
        }
      )
    );
  }

  private showDealOrFundingFromReview(item: {
    dealId?: string;
    fundingId?: string;
  }): void {
    if (item.dealId) {
      this.openDeal(item as Deal);
    }
    if (item.fundingId) {
      this.openFunding(item as Funding);
    }
  }

  private async closeDeal(result: {
    deal: Deal;
    isSave: boolean;
  }): Promise<void> {
    this.showDealModal = false;
    // We're not saving, so undo any changes to the deals
    if (!result.isSave && result.deal.dealId) {
      const originalDeal = this.companyWithMostRecentEdit.deals.find(
        (d) => d.dealId === result.deal.dealId
      );
      // Existing deals would be in the companyClone, new ones in the user's current session would not.
      const dealToRestore = originalDeal
        ? _.cloneDeep(originalDeal)
        : result.deal;
      const index = this.company.deals.findIndex(
        (d) => d.dealId === result.deal.dealId
      );
      this.company.deals.splice(index, 1, dealToRestore);
    }

    if (!result.isSave) {
      return;
    }

    if (this._companyUpdateService.isNewDealOrFunding(result.deal)) {
      const index = this.company.deals.findIndex(
        (d) => d.dealId === result.deal.dealId
      );
      if (!result.deal.isDeleted) {
        // Don't double-save
        if (index < 0) {
          this.company.deals.push(result.deal);
        }
      } else {
        // They deleted a deal they just tried to add, so remove it from the company.
        this.company.deals.splice(index, 1);
        if (this.pendingUpdate) {
          // Clean up the DB
          const updateType = 'deal';
          const deleteCompanyUpdate =
            this._companyUpdateService.wouldBeEmptyCompanyUpdate(
              this.pendingUpdate
            );
          await this._companyService.deletePendingAddedDealOrFundingUpdateByModelId(
            this.company.opportunityIdPrimary,
            updateType,
            result.deal.dealId,
            deleteCompanyUpdate
          );
          // Apply the pending update, assuming there is one.
          // If there isn't, i.e., deleteCompanyUpdate is true, applySavedOrPendingUpdateData()
          // does little more than retrieve a null company update, thus setting pendingUpdate to to null.
          // NOTE: Don't call initializeUpdateData() here because:
          // * We already have any approved but unprocessed update data, and nothing we've done in the modal impacts that stuff.
          // * We want the pending update applied to the company, but don't want to clone the company into the
          //   Pending cache or we'll lose track of what's different.
          await this._companyUpdateService.applySavedOrPendingUpdateData();
          // Make sure we remove the deleted deal from the caches
          _.remove(
            this.companyClone.deals,
            (d) => d.dealId === result.deal.dealId
          );
          _.remove(
            this.companyWithPending.deals,
            (d) => d.dealId === result.deal.dealId
          );
        }
        // The DB is clean, so now make sure the companyWithMostRecentEdit reflects the current state of the company.
        this.companyWithMostRecentEdit =
          this._companyUpdateService.cloneCompany(this.company);
        await this.notificationsComponent.getNotification();
      }
    }

    _.merge(this.companyWithMostRecentEdit.deals, this.company.deals);
    this._companyUpdateService.checkUpdatesForUI(this.currentUpdate);

    this.haveDealsChanged = this.dealUpdates?.length > 0;
  }

  private async closeFunding(result: {
    funding: Funding;
    isSave: boolean;
  }): Promise<void> {
    this.showFundingModal = false;

    // We're not saving, so undo any changes to the funding
    if (!result.isSave && result.funding.fundingId) {
      const originalFunding = this.companyWithMostRecentEdit.funding.find(
        (f) => f.fundingId === result.funding.fundingId
      );
      // Existing fundings would be in the companyClone, new ones in the user's current session would not.
      const fundingToRestore = originalFunding
        ? _.cloneDeep(originalFunding)
        : result.funding;
      const index = this.company.funding.findIndex(
        (f) => f.fundingId === result.funding.fundingId
      );
      this.company.funding.splice(index, 1, fundingToRestore);
    }

    if (!result.isSave) {
      return;
    }

    if (this._companyUpdateService.isNewDealOrFunding(result.funding)) {
      const index = this.company.funding.findIndex(
        (f) => f.fundingId === result.funding.fundingId
      );
      if (!result.funding.isDeleted) {
        // Don't double-save
        if (index < 0) {
          this.company.funding.push(result.funding);
        }
      } else {
        // They deleted a funding they just tried to add, so remove it from the company.
        this.company.funding.splice(index, 1);
        if (this.pendingUpdate) {
          // Clean up the DB
          const updateType = 'funding';
          const deleteCompanyUpdate =
            this._companyUpdateService.wouldBeEmptyCompanyUpdate(
              this.pendingUpdate
            );
          await this._companyService.deletePendingAddedDealOrFundingUpdateByModelId(
            this.company.opportunityIdPrimary,
            updateType,
            result.funding.fundingId,
            deleteCompanyUpdate
          );
          // Apply the pending update, assuming there is one.
          // If there isn't, i.e., deleteCompanyUpdate is true, applySavedOrPendingUpdateData()
          // does little more than retrieve a null company update, thus setting pendingUpdate to to null.
          // NOTE: Don't call initializeUpdateData() here because:
          // * We already have any approved but unprocessed update data, and nothing we've done in the modal impacts that stuff.
          // * We want the pending update applied to the company, but don't want to clone the company into the
          //   Pending cache or we'll lose track of what's different.
          await this._companyUpdateService.applySavedOrPendingUpdateData();
          // Make sure we remove the deleted deal from the caches
          _.remove(
            this.companyClone.funding,
            (f) => f.fundingId === result.funding.fundingId
          );
          _.remove(
            this.companyWithPending.funding,
            (f) => f.fundingId === result.funding.fundingId
          );
        }
        // The DB is clean, so now make sure the companyWithMostRecentEdit reflects the current state of the company.
        this.companyWithMostRecentEdit =
          this._companyUpdateService.cloneCompany(this.company);
        await this.notificationsComponent.getNotification();
      }
    }

    _.merge(this.companyWithMostRecentEdit.funding, this.company.funding);
    this._companyUpdateService.checkUpdatesForUI(this.currentUpdate);

    this.haveFundingsChanged = this.fundingUpdates?.length > 0;
  }

  public IsNumberOrNumberString(value: unknown): boolean {
    return !isNaN(parseFloat(value as string)) && isFinite(value as number);
  }

  public isCurrentUpdateApproved(): boolean {
    return this._companyUpdateService.isCurrentUpdateApproved(
      this.notificationsComponent
    );
  }

  public isDealOrFundingFromPriorUpdate(
    obj: Deal | Funding,
    typeId: string
  ): boolean {
    const updates: IModelEntity[] =
      typeId === 'dealId' ? this.dealUpdates : this.fundingUpdates;

    return !!_.find(updates, (update) => update.id === obj[typeId]);
  }

  public openFunding(funding?: Funding): void {
    this.fundingToModify = funding ?? new Funding();
    this.showFundingModal = true;
  }

  public openDeal(deal?: Deal): void {
    this.dealToModify = deal ?? new Deal();
    this.showDealModal = true;
  }

  public miscProperties: EditablePropertyGroup = {
    properties: ['keyMgmtAndAdvBm', 'firstTimeEntrepreneur'],
  };

  public get deiProperties(): EditablePropertyGroup {
    const deiGroup: EditablePropertyGroup = {
      header: LocalizedTextIds.JnjInformationDiversityEquity,
      properties: ['countryForDeiReporting'],
    };

    if (this.enableDiversityQuestions) {
      deiGroup.properties.push('leadershipDiversity', 'boardAdvisorDiversity');
    }

    deiGroup.properties.push(
      'womenLedOrgLeadership',
      'womenLedBoardOfDirectorsOrEquiv'
    );

    return deiGroup;
  }
}
