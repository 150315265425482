import { Injectable } from '@angular/core';
import {
  Resolve,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
} from '@angular/router';
import { Company, CompanyResolutionType } from 'company-finder-common';
import { Logger } from '../utilities/logger/logger';
import { AuthnService } from '../../_common/services/authn/authn.service';
import { CompanyService } from '../../_common/services/company/company.service';
import { UserService } from '../services/user/user.service';
import { WebAnalyticsService } from '../services/web-analytics/web.analytics';
import { ApplicationContext } from '../utilities/application-context/application-context';

@Injectable()
export class CompanyResolver implements Resolve<Company> {
  protected _logger = new Logger(this.constructor.name);

  constructor(
    protected _companyService: CompanyService,
    protected _authService: AuthnService,
    protected userService: UserService,
    private _companyResolutionType: CompanyResolutionType = CompanyResolutionType.Details,
    protected webAnalyticsService: WebAnalyticsService,
    protected context: ApplicationContext,
    private router: Router
  ) {}

  async resolve(
    route: ActivatedRouteSnapshot,
    _state: RouterStateSnapshot
  ): Promise<Company> {
    const id = route.params.id;
    const name = route.params.name;

    try {
      if (id) {
        const company = await this._companyService.getById(id);

        if (!company) {
          this.router.navigate(['/']);
        }

        return company;
      } else if (name) {
        const company = await this._companyService.getByEncodedNameOrOppId(
          name,
          this._companyResolutionType
        );

        if (!company) {
          this.router.navigate(['/']);
        }

        return company;
      } else if (this._authService.isAuthenticated) {
        // Find the company for a Company Contact hitting /company/update with no company name
        const associatedCompanies = this.userService.companiesForUpdate;

        if (!(associatedCompanies?.length > 0)) {
          this.router.navigate(['/']);
        }

        if (associatedCompanies?.length === 1) {
          const company = await this._companyService.getByNameOrOppId(
            associatedCompanies[0],
            this._companyResolutionType
          );

          if (!company) {
            this.webAnalyticsService.trackEvent('error', {
              category: 'juniverse-integration',
              event: `unmatched-company`,
              label: `${this._authService.userId ?? ''}:${
                this._authService.juniverseToken
              }`,
            });
            this._logger.warn(
              `Navigator can not match the company "${associatedCompanies[0]}" to any company in the index`
            );
          } else {
            return company;
          }
        } else {
          this._logger.warn(
            `Navigator can not automatically pick among the JUniverse user's ${associatedCompanies?.length} associated companies`
          );
        }
      }
      this.router.navigate(['/']);
    } catch (error) {
      this._logger.warn(`Company resolver failed: ${error}`);
      this.router.navigate(['/']);
    }
  }
}
