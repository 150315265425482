import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

// model imports
import { IUser, LocalizedTextIds, TimeZoneUtils } from 'company-finder-common';

// utility/service imports
import { UserService } from '../../services/user/user.service';
import { ComponentBase } from '../_component.base';
import { DeploymentContext } from '../../utilities/deployment-context/deployment-context';
import { AuthnService } from '../../services/authn/authn.service';

interface Blurb {
  line1: string;
  line2?: string;
}
@Component({
  selector: 'stay-in-the-loop',
  templateUrl: './stay-in-the-loop.component.html',
  styleUrls: ['./stay-in-the-loop.component.scss'],
})
export class StayInTheLoopComponent extends ComponentBase implements OnInit {
  // public properties
  public blurbs: Blurb[] = [
    {
      line1: this.Loc(LocalizedTextIds.StayInTheLoopLine1),
      line2: this.Loc(LocalizedTextIds.StayInTheLoopLine2),
    },
  ];
  public dots: number[] = [];
  public currentScreen: number;
  public showModal = false;
  @Input()
  public isInternal: boolean;
  // private properties
  private _user: IUser;

  // public getters
  public get currentBlurb(): Blurb {
    return this.blurbs[this.currentScreen];
  }

  public constructor(
    dc: DeploymentContext,
    private _router: Router,
    private _userService: UserService,
    private authnService: AuthnService
  ) {
    super(dc);
    for (let i = 0; i < this.blurbs.length; i++) {
      this.dots.push(i);
    }
    this.currentScreen = this.dots[0];
  }

  async ngOnInit(): Promise<void> {
    this._user = await this._userService.getCachedUser();
    this.showModal =
      this._user &&
      this.authnService.isInternal &&
      !this._user.viewedStayInTheLoop;
  }

  // public methods
  public async close(): Promise<void> {
    this.showModal = false;
    if (this._user && !this._user.viewedStayInTheLoop) {
      this._user.viewedStayInTheLoop = true;

      this._user.timeZoneOffsetInHours = TimeZoneUtils.getCurrentTimeZoneOffset(
        false,
        false
      ); // no half or special tzs

      await this._userService.save(this._user);
    }
  }

  public async getStarted(): Promise<void> {
    await this.close();
    this.goToMyUpdatesPreferences();
  }

  public next(): void {
    if (this.currentScreen < this.blurbs.length - 1) {
      ++this.currentScreen;
    } else {
      // NOTE: The UI currently prevents this by hiding the button at this end
      this.currentScreen = 0;
    }
  }

  public previous(): void {
    if (this.currentScreen > 0) {
      --this.currentScreen;
    } else {
      // NOTE: The UI currently prevents this by hiding the button at this end
      this.currentScreen = this.blurbs.length - 1;
    }
  }

  // private methods
  private goToMyUpdatesPreferences(): void {
    this._router.navigate(['user', 'preferences'], {
      state: { _showWizard: true },
    });
  }
}
