export enum EmailFrequency {
    Never,
    Yearly,
    Quarterly,
    Monthly,
    BiWeekly,
    Weekly,
    Daily,
}

export function emailFrequencyToDays(frequency: EmailFrequency) {
    switch (frequency) {
        case EmailFrequency.Yearly:
            return 365;

        case EmailFrequency.Quarterly:
            return 90;

        case EmailFrequency.Monthly:
            return 30;

        case EmailFrequency.BiWeekly:
            return 14;

        case EmailFrequency.Weekly:
            return 7;

        case EmailFrequency.Daily:
            return 1;

        case EmailFrequency.Never:
        default:
            return Number.POSITIVE_INFINITY;
    }
}

export function emailFrequencyToHours(frequency: EmailFrequency) {
    return this.emailFrequencyToDays(frequency) * 24;
}

export function emailFrequencyToIntervalString(
    frequency: EmailFrequency
): string {
    switch (frequency) {
        case EmailFrequency.Yearly:
            return 'year';
        case EmailFrequency.Quarterly:
            return 'quarter';
        case EmailFrequency.Monthly:
            return 'month';
        case EmailFrequency.BiWeekly:
            return 'two weeks';
        case EmailFrequency.Weekly:
            return 'week';
        case EmailFrequency.Daily:
            return 'day';
        default:
            return 'none';
    }
}

export function emailFrequencyToDisplayString(
    frequency: EmailFrequency
): string {
    switch (frequency) {
        case EmailFrequency.Yearly:
            return 'Yearly';
        case EmailFrequency.Quarterly:
            return 'Quarterly';
        case EmailFrequency.Monthly:
            return 'Monthly';
        case EmailFrequency.BiWeekly:
            return 'Bi-Weekly';
        case EmailFrequency.Weekly:
            return 'Weekly';
        case EmailFrequency.Daily:
            return 'Daily';
        case EmailFrequency.Never:
            return 'Not now';
        default: 
            // The default for an unitialized user is weekly, so leave it on that
            return 'Weekly';
    }
}
