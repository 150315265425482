import { Injectable } from '@angular/core';

// utility/service imports
import { AuthnService } from '../authn/authn.service';
import { Router } from '@angular/router';

declare let dataLayer: { push: (event: unknown) => unknown };

declare global {
  interface Window {
    dataLayer: { push: (event: unknown) => unknown };
  }
}

/** See https://confluence.jnj.com/x/JdKGC for more details.
 * Opted not to bother with an https://www.npmjs.com/package/angulartics2 or comparable
 * since it was easy enough to just implement.
 */
@Injectable()
export class WebAnalyticsService {
  // public properties

  public constructor(private _authnService: AuthnService) {
    // The dataLayer needs to be initialized
    if (typeof dataLayer !== 'undefined' && dataLayer) {
      dataLayer = window.dataLayer = window.dataLayer || [];
    }
  }

  /** We are making the Action the core required value, and everything in properties is optional.
   * Note that properties.event should be rarely used, since it would require additional GTM configuration.
   */
  public trackEvent(
    ptAction: string,
    properties?: {
      event?: string;
      category?: string;
      label?: string;
      value?: number;
    }
  ): void {
    properties = properties || {};

    // Always need to push all values, since, with a single-page-app, any previously pushed values
    // will carry forward.
    this.pushToDataLayer({
      // The event value is what will be referenced in the GTM trigger. A GTM trigger is established for the default
      // value, but if we use any custom ones, GTM would have to be configured to support it.
      // But I don't see any reason why we would need to)
      event: properties.event || 'portfoliotool-custom-interaction',
      ptCategory: properties.category || 'Interaction',
      // FUTURE: Turn this into an enum so we have a well defined set of actions, although we probably aren't repeating values anywhere
      ptAction,
      ptLabel: properties.label || ptAction, // Chris DuBois didn't want unset Label values, so default this to the Action, if unspecified
      ptValue: properties.value,
      // These are mapped to User Defined Variables in GTM, and included as "additional dimensions" in the GTM Google Analytics config
      ptUserType: this._authnService.userRole ?? 'anonymous',
      ptUserId: this._authnService.isAuthenticated
        ? this._authnService.currentUsername
        : '',
    });
  }

  public trackBreadcrumbNavigation(breadCrumb: string, router: Router): void {
    this.trackEvent(breadCrumb, {
      category: 'Breadcrumb Navigation',
      // FUTURE: There is bound to be a better way to consolidate parameterized routes into a single value
      // but did the quick and dirty for now, since the company details route is the only parameterized route.
      label: `From ${
        router.url.search(/details/gi) >= 0 ? 'company details' : router.url
      }`,
    });
  }

  private pushToDataLayer(event: unknown) {
    if (typeof dataLayer !== 'undefined' && dataLayer) {
      dataLayer.push(event);
    }
  }
}
