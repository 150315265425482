import { Component, Input, OnInit } from '@angular/core';
import {
  Company,
  LocalizedTextIds,
  NewsEventOfInterest,
} from 'company-finder-common';
import moment from 'moment';
import { ComponentBase } from '../../../../../_common/components/_component.base';
import { EventService } from '../../../../../_common/services/event/event.service';
import { DeploymentContext } from '../../../../../_common/utilities/deployment-context/deployment-context';

@Component({
  selector: 'news-component',
  templateUrl: './news.component.html',
  styleUrls: ['./news.component.scss'],
})
export class NewsComponent extends ComponentBase implements OnInit {
  @Input()
  public company: Company;

  public newsItems: NewsEventOfInterest[] = [];

  public currentPage = 1;
  public itemsPerPage = 6;

  public getDateString(date: Date): string {
    const days = moment.utc().diff(date, 'days');

    if (days === 0) {
      return this.Loc(LocalizedTextIds.Yesterday);
    } else if (days === 1) {
      return this.Loc(LocalizedTextIds.Today);
    } else {
      return days + this.Loc(LocalizedTextIds.NewsDaysAgo);
    }
  }

  public openLink(url: string): void {
    window.open(url, '_blank');
  }

  public getBorderClass(item: number, page: number): string {
    let isLastTwoOnPage = false;

    if (page * this.itemsPerPage <= this.newsItems.length) {
      if (item >= this.itemsPerPage - 2) {
        isLastTwoOnPage = true;
      }
    } else {
      const lastPageCount = this.newsItems.length % this.itemsPerPage;
      if (item >= lastPageCount - 2) {
        isLastTwoOnPage = true;
      }
    }

    if (isLastTwoOnPage) {
      return '';
    } else {
      return 'hasBorder';
    }
  }

  constructor(
    deploymentContext: DeploymentContext,
    private _eventService: EventService
  ) {
    super(deploymentContext);
  }

  async ngOnInit(): Promise<void> {
    this.newsItems = await this._eventService.getCompanyNewsDigest(
      this.company.id
    );
  }
}
