import { Component, Input } from '@angular/core';
import { ComponentBase } from '../_component.base';

@Component({
  selector: 'company-status-icon',
  templateUrl: './company-status-icon.component.html',
  styleUrls: ['./company-status-icon.component.scss'],
})
export class CompanyStatusIconComponent extends ComponentBase {
  @Input()
  public statuses: string[];

  @Input()
  public float = 'initial';

  @Input()
  public tooltipTop: string;

  @Input()
  public tooltipLeft: string;

  public isShowingTooltip = false;

  public get showStatusIcon(): boolean {
    return (
      !!this._deploymentContext.companyStatusIcon &&
      this.statuses.includes(this._deploymentContext.statusForCompanyIcon)
    );
  }

  public get companyStatusIcon(): Partial<CSSStyleDeclaration> {
    return {
      backgroundImage: `url(${this._deploymentContext.companyStatusIcon})`,
      height: `32px`,
      width: `32px`,
      backgroundSize: `contain`,
      backgroundRepeat: `no-repeat`,
      backgroundPosition: `center center`,
      float: this.float,
      marginTop: `-2px`,
      marginLeft: `2px`,
    };
  }

  public get tooltipPosition(): Partial<CSSStyleDeclaration> {
    return {
      top: this.tooltipTop,
      left: this.tooltipLeft,
    };
  }

  public get iconTooltip(): string {
    return this._deploymentContext?.iconTooltip ?? '';
  }

  public showTooltip(_event: MouseEvent): void {
    this.isShowingTooltip = this.iconTooltip.length > 0;
  }

  public hideTooltip(_event: MouseEvent): void {
    this.isShowingTooltip = false;
  }
}
