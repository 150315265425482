export enum TabConfig {
  PUBLIC,
  JNJ_ONLY,
  BLUE_KNIGHT,
  BLUE_KNIGHT_MILESTONES,
}
export interface TabInfo {
  blueKnightIcon?: boolean;
  highlightWidth: number;
  lockIcon?: boolean;
  label: string;
  value: TabConfig;
  getErrorInfo?: () => string[];
}
