import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

// service imports;
import { AuthnService } from '../../_common/services/authn/authn.service';
import { DeploymentContext } from '../../_common/utilities/deployment-context/deployment-context';
import { WebAnalyticsService } from '../../_common/services/web-analytics/web.analytics';
import { getParsedReturnUrl } from '../../_common/utilities/navigation/navigation.utils';
import { AuthnStrings } from '../../_common/constants/AuthnStrings';
import { ComponentBase } from '../../_common/components/_component.base';

@Component({
  selector: 'acs',
  templateUrl: './acs.component.html',
  styleUrls: ['./acs.component.scss'],
})
export class AcsComponent extends ComponentBase implements OnInit {
  // public properties
  public showProgressIndicator = false;

  constructor(
    deploymentContext: DeploymentContext,
    private _authnService: AuthnService,
    private _webAnalyticsService: WebAnalyticsService,
    private _router: Router,
    private route: ActivatedRoute
  ) {
    super(deploymentContext);
  }

  // public methods
  public async ngOnInit(): Promise<void> {
    this._authnService.clearJnjLoggedOut();

    this.showProgressIndicator = true;
    setTimeout(async () => {
      if (!(await this._authnService.isSSOAuthValid())) {
        this._router.navigate(['']);
        return;
      }
      this._webAnalyticsService.trackEvent('login', {
        category: 'successful-login',
        label: this._authnService.currentUsername ?? '',
      });

      const target = getParsedReturnUrl(this.route);
      this._router.navigateByUrl(target);
    }, this._deploymentContext.progressIndicator.pauseForStaticIndicator);
  }
}
