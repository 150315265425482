import mapboxgl from 'mapbox-gl';
import { Location } from 'company-finder-common';

export interface LocationInfo {
  location: Location;
  sectorCounts: number[];
}
export class LocationGroup extends Array<LocationInfo> {
  public constructor(private getMap: () => mapboxgl.Map = () => null) {
    super();
  }

  public get name(): string {
    return this.map((locationInfo) => locationInfo.location.name)
      .sort()
      .join('\n');
  }

  public get city(): string {
    const uniqueLocNames = [
      ...new Set(this.map((locationInfo) => locationInfo.location.city)),
    ];
    return uniqueLocNames.sort().join(', ');
  }

  public get latLng(): mapboxgl.LngLat {
    return this.getMap().unproject(this.point);
  }

  public get point(): mapboxgl.Point {
    if (!this.getMap()) {
      return new mapboxgl.Point(0, 0);
    }

    if (this.length === 1) {
      return this.locationInfoPoint(this[0]);
    }

    const sums = this.reduce(
      (pv, cv) => {
        const pt = this.locationInfoPoint(cv);
        return [pv[0] + pt.x, pv[1] + pt.y];
      },
      [0, 0]
    );
    const count = this.length;
    return new mapboxgl.Point(sums[0] / count, sums[1] / count);
  }

  public get counts(): number[] {
    const counts = this.reduce((pv, locationInfo) => {
      locationInfo.sectorCounts.forEach((count, i) => (pv[i] += count));
      return pv;
    }, Array<number>(this[0].sectorCounts.length).fill(0));
    return counts;
  }

  private locationInfoPoint(locationInfo: LocationInfo): mapboxgl.Point {
    // MapBox unproject() method does not always yield a point on our visible map--
    // it treates longitude x different from 360+x.  So we force it to use the slice
    // of virtual map that starts with the left edge of our visible map.
    const map = this.getMap();
    const leftLng = map.unproject([0, 0]).lng;
    const geoPosition = locationInfo.location.geoPosition ?? { lat: 0, lng: 0 };
    const lng = leftLng + ((((geoPosition.lng - leftLng) % 360) + 360) % 360);
    return map.project(new mapboxgl.LngLat(lng, geoPosition.lat));
  }
}
