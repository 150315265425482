import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';

import {
  Company,
  Filter,
  SharedStats,
  SortType,
  TagWithCount,
  SearchResult,
} from 'company-finder-common';

import { CompanyService } from '../company/company.service';
import { DeploymentContext } from '../../utilities/deployment-context/deployment-context';
import { ServiceBase } from '../_service.base';

import _ from 'lodash';

@Injectable()
export class SearchService extends ServiceBase {
  public readonly PAGE_SIZE = 20;
  public readonly ARRAY_QUERY_PARAM_DELIMITER = '+';
  public filter: Filter;
  public filterSubject: Subject<Filter> = new Subject();
  public drilldownSubject: Subject<Filter> = new Subject();
  public badSearchPredicate: string;
  public badSearchPredicateSubject: Subject<string> = new Subject<string>();
  public searchPredicateSubject: Subject<string> = new Subject<string>();
  public searchResultsSubject: Subject<Company[]> = new Subject<Company[]>();
  public initialFetchSize: number = undefined;

  private _companies: Company[];
  private _totalCompanyCount: number;
  private _tagCounts: TagWithCount[];
  private _currentSearchPredicate: string;
  private _lastSearchTotal: number;
  private _lastSearchPredicate: string;
  private _lastSearchFilter: Filter;

  constructor(
    private _companyService: CompanyService,
    _deploymentContext: DeploymentContext,
    _httpClient: HttpClient,
    private _router: Router
  ) {
    super(_httpClient, _deploymentContext, '/search');
    this.filter = Filter.emptyFilter;
  }

  public get companies(): Company[] {
    return this._companies;
  }

  /** The count of companies included in the most recent search scope
   * (if multiple searches are performed concurrently, there is no guarantee which one this value will represent) */
  // FUTURE: There is probably a better way to have exposed the count of companies in the last search to webanalytics calls
  //  that didn't easily have a handle on the current search results.
  public get companyCountFromLastSearch(): number {
    return this._totalCompanyCount;
  }

  // public methods
  public get isAllCompanies(): boolean {
    // A valid (i.e. non-empty and non-bad) search term or filter term will reduce the scope
    // of the search to not include all companies. Though it's theoretically possible a search
    // term could match all companies, we don't consider that case
    return (
      (!this.currentSearchPredicate || !!this.badSearchPredicate) &&
      this.filter.isShowAll()
    );
  }

  public get currentSearchPredicate(): string {
    return this._currentSearchPredicate;
  }

  public set currentSearchPredicate(value: string) {
    this._currentSearchPredicate = value;
  }

  public getSearchTermForAnalytics(): string {
    if (
      _.isEmpty(this.currentSearchPredicate) &&
      _.isEmpty(this.filter.locations) &&
      _.isEmpty(this.filter.primarySectors) &&
      _.isEmpty(this.filter.primarySubSectors) &&
      _.isEmpty(this.filter.secondarySectors) &&
      _.isEmpty(this.filter.secondarySubSectors) &&
      this.filter.locationStatuses === ''
    ) {
      return null;
    }

    let searchString = '';
    if (!_.isEmpty(this.currentSearchPredicate)) {
      searchString += ' terms: ' + this.currentSearchPredicate;
    }

    if (!_.isEmpty(this.filter.locations)) {
      searchString += ' locations: ' + this.filter.locations;
    }

    if (!_.isEmpty(this.filter.primarySectors)) {
      searchString += ' primarySectors: ' + this.filter.primarySectors;
    }

    if (!_.isEmpty(this.filter.primarySubSectors)) {
      searchString += ' primarySubSectors: ' + this.filter.primarySubSectors;
    }

    if (this.filter.locationStatuses !== '') {
      searchString += ' location status: ' + this.filter.locationStatuses;
    }

    if (this.filter.isCrossSector !== null) {
      searchString += ' isCrossSector: ' + this.filter.isCrossSector;
    }

    if (this.filter.isQFCWinner !== null) {
      searchString += ' isQFCWinner: ' + this.filter.isQFCWinner;
    }

    if (this.filter.isNewInLastQuarter !== null) {
      searchString += ' isNewInLastQuarter: ' + this.filter.isNewInLastQuarter;
    }

    if (this.filter.currentRdStage !== null) {
      searchString += ' currentRdStage: ' + this.filter.currentRdStage;
    }

    if (this.filter.isNewInLast90Days !== null) {
      searchString += ' isNewInLast90Days: ' + this.filter.isNewInLast90Days;
    }

    if (this.filter.isFollowedCompaniesOnly !== null) {
      searchString +=
        ' isFollowedCompaniesOnly: ' + this.filter.isFollowedCompaniesOnly;
    }

    if (this.filter.isMyJPALCompaniesOnly !== null) {
      searchString +=
        ' isMyJPALCompaniesOnly: ' + this.filter.isMyJPALCompaniesOnly;
    }

    if (searchString.length <= 60) {
      return searchString;
    } else {
      return searchString.substr(0, 60) + '...';
    }
  }

  public clearSearchPredicate(): void {
    this.currentSearchPredicate = undefined;
    this.searchPredicateSubject.next(this.currentSearchPredicate);
    this.searchResultsSubject.next(null);
  }

  public async getCompanies(
    from: number,
    size: number = this.PAGE_SIZE
  ): Promise<SearchResult> {
    // if we have already performed this search, don't repeat it
    if (
      this.currentSearchPredicate &&
      this.currentSearchPredicate === this._lastSearchPredicate &&
      from === 0 &&
      Filter.equals(this.filter, this._lastSearchFilter)
    ) {
      return {
        companies: this._companies,
        totalCompaniesCount: this._lastSearchTotal,
      };
    }

    if (from === 0) {
      this._companies = [];
    }
    const result = await this.search(from, size);

    this._companies = this._companies.concat(result.companies);
    this._lastSearchTotal = result.totalCompaniesCount;

    return {
      companies: this._companies,
      totalCompaniesCount: result.totalCompaniesCount,
    };
  }

  public getSearchResultsQueryParams(): unknown {
    // Get the filter object, serialized into key=value pairs
    const serializedQueryParams = this.filter.serializeToKeyValuePairs();

    // Set up the queryParams from the filter, encoding them along the way.
    const queryParamsObj = {
      queryParams: {},
    };
    Object.keys(serializedQueryParams).map((key) => {
      const obj = serializedQueryParams[key];
      let value = obj.value;
      if (obj.isArray) {
        value = obj.value.join(this.ARRAY_QUERY_PARAM_DELIMITER);
      }

      queryParamsObj.queryParams[encodeURIComponent(key)] =
        encodeURIComponent(value);
    });

    // Add the search predicate
    if (this.currentSearchPredicate) {
      queryParamsObj.queryParams['text'] = encodeURIComponent(
        this.currentSearchPredicate
      );
    }

    return queryParamsObj;
  }

  public async getTagCounts(): Promise<TagWithCount[]> {
    // FUTURE: Is there ever a situation where we want to update the tag counts?
    if (!this._tagCounts) {
      this._tagCounts = await this._companyService.getTagCounts();
    }
    return this._tagCounts;
  }

  public async getTypeahead(prefix: string): Promise<string[]> {
    const apiUrl = `${this._apiUrl}/typeahead/${prefix}`;

    const result = await this._httpClient
      .get<string[]>(apiUrl, { headers: this._standardHeaders })
      .toPromise();

    return result;
  }

  public navigateToSearchResults(): void {
    this._router.navigate(
      ['/search-results'],
      this.getSearchResultsQueryParams()
    );
  }

  private prepareForSearch() {
    if (this.currentSearchPredicate) {
      this.currentSearchPredicate = this.currentSearchPredicate.trim();
    }

    if (!this.filter.sort) {
      // If there is no sort, default to Alpha
      this.filter.sort = SortType.Alpha;
    } else if (
      this.filter.sort === SortType.Ranking &&
      !this.currentSearchPredicate
    ) {
      // If we are currently sorted on Rank but there is no search predicate, switch back to Alpha
      this.filter.sort = SortType.Alpha;
    }

    this.searchPredicateSubject.next(this.currentSearchPredicate);
  }

  public async search(
    from: number = 0,
    size: number = this.PAGE_SIZE
  ): Promise<SearchResult> {
    this.prepareForSearch();
    let result = await this._companyService.searchAndFilter(
      this.currentSearchPredicate,
      from,
      size,
      this.filter
    );
    if (!result.companies || result.companies.length === 0) {
      // Notify subscribers there was a bad search term, then get all companies.
      this.badSearchPredicateSubject.next(this.currentSearchPredicate);
      result = await this._companyService.searchAndFilter(
        '',
        0,
        this.PAGE_SIZE,
        this.filter
      );
    } else {
      this.badSearchPredicateSubject.next(null);
    }

    this.finishSearch(result);
    return result;
  }

  public async searchAggregate(): Promise<SharedStats> {
    this.prepareForSearch();
    let result = await this._companyService.searchAndFilterAggregate(
      this.currentSearchPredicate,
      0,
      0,
      this.filter
    );
    if (!result || result.numberOfCompanies === 0) {
      // Notify subscribers there was a bad search term, then get all companies.
      this.badSearchPredicateSubject.next(this.currentSearchPredicate);
      result = await this._companyService.searchAndFilterAggregate(
        '',
        0,
        this.PAGE_SIZE,
        this.filter
      );
    } else {
      this.badSearchPredicateSubject.next(null);
    }

    this.finishSearch({
      totalCompaniesCount: result.numberOfCompanies,
      companies: [],
    });
    return result;
  }

  private finishSearch(result: SearchResult) {
    this._totalCompanyCount = result.totalCompaniesCount;

    if (result.companies?.length > 0) {
      this.searchResultsSubject.next(result.companies);
    }

    this._lastSearchPredicate = this.currentSearchPredicate;
    // this.filter is mutable, so make sure this._lastSearchFilter isn't pointing to the same instance as this.filter
    this._lastSearchFilter = this.filter.clone();
  }

  public clearCachedSearch(): void {
    // Implemented as part of ADJQ-487 to facilitate making a fresh search (rather than returning cached results)
    // even if the search criteria has not changed
    this._lastSearchFilter = undefined;
    this._lastSearchPredicate = undefined;
    this._lastSearchTotal = undefined;
  }
}
