export class StatusImage {
  public iconUrl: string;
  public height: number;
  public width: number;

  public constructor(assetPath: string, height: number, width: number) {
    this.iconUrl = `url(${assetPath})`;
    this.height = height;
    this.width = width;
  }
}

export class StatusItem {
  public displayText: string;
  public tooltip?: string = null;
  public image?: StatusImage = null;

  public constructor(
    displayText: string,
    tooltip?: string,
    assetPath?: string,
    height?: number,
    width?: number
  ) {
    this.displayText = displayText;
    this.tooltip = tooltip;
    this.image = assetPath ? new StatusImage(assetPath, height, width) : null;
  }

  public get spacerCss(): Partial<CSSStyleDeclaration> {
    return this.image
      ? {
          height: `${this.image.height}px`,
          width: `${this.image.width}px`,
          backgroundImage: `${this.image.iconUrl}`,
        }
      : null;
  }
}
