export interface HierarchicalMenu {
  sectorOptions: HierarchicalMenuOption[];
}

export interface HierarchicalMenuOption extends MenuOption {
  children?: HierarchicalMenuOption[];
  parent?: HierarchicalMenuOption;
}

export interface MenuOption {
  aggregateDbValues?: string[];
  db: string;
  name: string;
  value: boolean;
}

export function getSelectedItems(menuOptions: MenuOption[]): string[] {
  return menuOptions.reduce((result, option) => {
    // Get only those options that are selected
    if (option.value) {
      // Then return either the values in aggregateDbValues...
      if (option.aggregateDbValues && option.aggregateDbValues.length) {
        result.push(...option.aggregateDbValues);
      } else {
        // ...or the individual db value.
        result.push(option.db);
      }
    }
    return result;
  }, []);
}
