import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { ServiceBase } from '../../../_common/services/_service.base';
import { DeploymentContext } from '../../../_common/utilities/deployment-context/deployment-context';
import { HealthStatus } from 'company-finder-common';
import { environment } from '../../../environments/environment';

@Injectable()
export class HealthCheckService extends ServiceBase {
  constructor(
    protected _httpClient: HttpClient,
    protected _context: DeploymentContext
  ) {
    super(_httpClient, _context, '/');
  }

  public async doServerHealthCheck(): Promise<HealthStatus> {
    // We originally followed our typical server API request pattern here,
    // but the data is now captured during a pre-Angular-bootstrap as a result of ADJQ-1768.
    return environment.apiInfo;
  }

  public get appVersion(): string {
    return this._context.appVersion;
  }
}
