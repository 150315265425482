import { UserRoles } from './userRoles';

export class Identifier {
    public username: string;
    public userRole?: UserRoles;
}

export class LogPayload {
    // Indicates if an error occured or this is a diagnostic log
    public isError: boolean;

    // Standard log items
    public created: Date;
    public stack: string;
    public message: string;
    public logLevel: 'debug' | 'info' | 'warn' | 'error';

    // Details to identify the source of the error
    public origin: string;
    public userAddress?: string | string[];
    public clientVersion: string;
    public correlationId: string;

    // These should always be the same, but a discrepency could
    // indicate an issue
    public clientUser?: Identifier;
    public apiUser?: Identifier;
}
