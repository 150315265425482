import { Injectable } from '@angular/core';
import { Observable, Subscription, BehaviorSubject, Subscriber } from 'rxjs';
import { filter } from 'rxjs/operators';
import { iframeResizer } from '@iframe-resizer/child';
import { DeploymentContext } from '../../utilities/deployment-context/deployment-context';

@Injectable({
  providedIn: 'root',
})
export class IframeResizerService {
  private _iframeSubject = new BehaviorSubject<iframeResizer.ParentProps>(null);
  private _sourceObservableSubscription: Subscription;

  constructor(private _deploymentContext: DeploymentContext) { };
  /**
   * A subject that publishes events whenever the window is scrolled or resized
   */
  public get iframeSubject(): Observable<iframeResizer.ParentProps> {
    if (this._iframeSubject.observers.length === 0) {
      // Wait until the first subscription to create the underlying observable
      this._sourceObservableSubscription =
        this.subscribeSubjectToSourceObservable();
    }

    // BehaviorSubjects will push their current value to any new subscribers, but that current value
    // might be the null that is initialize above. Rather than filtering that out at each subscriber,
    // we can just nip those in the bud here.
    return this._iframeSubject.asObservable().pipe(filter(value => value !== null));
  }

  // FUTURE - use in all subscribers to reduce boilerplate
  public subscribeIfIframed(next: (value: iframeResizer.ParentProps) => void): Subscription {
    if (!this._deploymentContext.hosted()) {
      return null;
    }

    return this.iframeSubject.subscribe(next);
  }

  private subscribeSubjectToSourceObservable(): Subscription {
    const sourceObservable = this.initializeSourceObservable();

    // Since a subject is both an Observer and an Observable, this allows the Subject's subscribers
    // to receive values published by sourceObservable without being bound to sourceObservable itself
    return sourceObservable?.subscribe(this._iframeSubject);
  }

  private initializeSourceObservable(): Observable<iframeResizer.ParentProps> {
    return this._deploymentContext.hosted()
      ? new Observable<iframeResizer.ParentProps>((subscriber) => this.scollOrResizeIframe(subscriber, this._deploymentContext.iframe),)
      : null;
  }

  private lastProps: any;
  private scollOrResizeIframe(subscriber: Subscriber<iframeResizer.ParentProps>, iframe: iframeResizer.IFramePage) {
    iframe?.getParentProps(
      (props) => {
        // This seems to be robust enough to de-dupe the scroll/resize events,
        // with the bad-case being it could send an event that is semantically
        // identical, which would be inneficient but have no real effect,
        // so the trade off seems fine here. 
        //
        // In short, given that 
        // * This has worked consistently in testing
        // * We were not de-duping scroll/resize events prior to this code being introduced
        // * There is effort in writing a more robust compairson
        // * The result of accidentally letting a duplicate through is essentially a no-op scroll/resize event
        // 
        // this should be sufficient for our needs
        if (JSON.stringify(props) === JSON.stringify(this.lastProps)) {
          return;
        }
        this.lastProps = props;
        // This function will be called for every scroll and resize event,
        // if those listeners are enabled in the parent frame
        subscriber.next(props);
      }
    );
  }
}
