import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

// service imports
import { DeploymentContext } from '../../utilities/deployment-context/deployment-context';
import { ServiceBase } from '../_service.base';
import {
  LoginInfo,
  RelayState,
  RoleGroups,
  UserRoles,
} from 'company-finder-common';
import { WebAnalyticsService } from '../web-analytics/web.analytics';
import { AuthnStrings } from '../../constants/AuthnStrings';
import { TokenService } from '../token/token.service';

const RefreshRetryBackoffFactor = 1.5; // exponent of increasing backoff retry time for failed refresh
const PercentLifeToRefresh = 0.6; // How far into life of token to attempt refresh (must be > 0.5)
const RefreshRetryMinMs = 5000; // Min time to wait before retrying refresh (or when no token), until very close to token expiry.

@Injectable({
  providedIn: 'root',
})
export class AuthnService extends ServiceBase {
  // private properties
  private RefreshRetryCount = 0; // Count of failed refresh calls since last successful token

  // To avoid collisions between open tabs refreshing, add a random number of ms
  private randomPerturbation = Math.random() * 1000;
  private static isTokenRefreshScheduled = false;

  constructor(
    _httpClient: HttpClient,
    _context: DeploymentContext,
    private tokenService: TokenService
  ) {
    super(_httpClient, _context, '/authn');
  }

  public async login(
    username: string,
    password: string,
    userRole?: string
  ): Promise<boolean> {
    const result = await this._httpClient
      .post<string>(
        `${this._apiUrl}/login`,
        {
          username: username,
          password: password,
          role: userRole,
        },
        { headers: this._standardHeaders }
      )
      .toPromise();

    // If the login was successful, the response contained the token in a header
    //  and would have already been captured by the authn.interceptor.ts HttpInterceptor
    if (!result) {
      this.clearToken();
    }
    return result != null;
  }

  public async attemptJuniverseAuth(
    webAnalyticsService: WebAnalyticsService,
    bypassJuniverse = false,
    tokenToSend = ''
  ): Promise<boolean> {
    if (!this._context.juniverseConfigured) {
      return false;
    }
    let token: string = null;

    // If the user already has a Navigator auth token, don't attempt to retrieve a JUniverse token.
    // NOTE: JUniverse's preflight CORS check baulks at the presence of a bearer token (added by AuthnInterceptor).
    //   Otherwise the error is: "Request header field authorization is not allowed by Access-Control-Allow-Headers in preflight response"
    if (this.tokenService.isAuthenticated) {
      return;
    }

    try {
      if (!bypassJuniverse) {
        const resp = await this._httpClient
          .get<{ token: string }>(
            `${
              this._context.juniverseTokenEndpoint
            }?tsp=${new Date().getMilliseconds()}`,
            {
              withCredentials: true,
            }
          )
          .toPromise();
        token = resp?.token;
      } else {
        this._logger.info(
          `Sending the ${tokenToSend} token from the configuration.`
        );
        token = this._context[tokenToSend];
      }
    } catch (err) {
      this._logger.error(`Error with juniverse integration: ${err}`);
    }

    if (!token) {
      this.tokenService.clearJuniverseToken();
      return;
    }

    // If you log in as Juniverse, remove the flag to promt a J&J login
    this.tokenService.setLoggedInFlags(false);
    this.tokenService.setLoggedOutFlags(false);
    const result = await this._httpClient
      .post(
        `${this._apiUrl}/login_token`,
        {
          token,
        },
        { responseType: 'text' }
      )
      .toPromise();

    // If the login was successful, the response contained the token in a header
    //  and would have already been captured by the authn.interceptor.ts HttpInterceptor
    if (!result) {
      this.clearToken();
      return false;
    }

    webAnalyticsService.trackEvent('login', {
      category: 'successful-login',
      label: this.currentUsername ?? '',
    });

    return true;
  }

  // FUTURE - Should other services call the token service
  // directly? Probably not, it seems cleaner to limit that
  // low-level code to only be called from the authn service
  // and authn interceptor and let the rest of the services
  // remain blissfully ignorant of the implementation of the
  // tokens
  public get isAuthenticated(): boolean {
    return this.tokenService.isAuthenticated;
  }

  public clearToken(): void {
    this.tokenService.clearToken();

    this.resetRefreshRetryCount();
  }

  public get juniverseToken(): string {
    return this.tokenService.juniverseToken;
  }

  public async isSSOAuthValid(): Promise<boolean> {
    const result = await this._httpClient
      .get<string>(`${this._apiUrl}/sso/token`, {
        headers: this._standardHeaders,
      })
      .toPromise();

    // If the login was successful, the response contained the token in a header
    //  and would have already been captured by the authn.interceptor.ts HttpInterceptor

    if (!result) {
      this.clearToken();
    }
    return result != null;
  }

  public async refreshToken(): Promise<void> {
    const url = `${this._apiUrl}/refresh`;

    this.setLastRefreshAttemptTime();

    await this._httpClient
      .post(url, {}, { headers: this._standardHeaders })
      .toPromise();
  }

  public clearJnjWasLoggedIn(): void {
    localStorage.removeItem(AuthnStrings.KEY_JNJ_WAS_LOGGED_IN);
  }

  public clearJnjLoggedOut(): void {
    localStorage.removeItem(AuthnStrings.KEY_JNJ_LOGGED_OUT);
  }

  public get loginInfo(): LoginInfo {
    return this.getLoginInfo();
  }

  public get userId(): string {
    return this.loginInfo ? this.loginInfo.id : null;
  }

  public get currentRole(): UserRoles {
    if (this._context.featureSwitches.enablePaywall) {
      return this.loginInfo?.role;
    }
    return this.loginInfo?.role ?? UserRoles.Partner;
  }

  public get juniverseId(): string {
    return this.loginInfo && this.loginInfo.id;
  }

  public get currentUsername(): string {
    return this.loginInfo?.id;
  }

  private getLoginInfo(): LoginInfo {
    // FUTURE - should we cache this?
    return this.tokenService.token?.length > 0
      ? this.tokenService.decodeToken()
      : null;
  }

  public get userRole(): UserRoles {
    return this.loginInfo?.role;
  }

  public get isInternal(): boolean {
    return RoleGroups.InternalUsers.containsRole(this.userRole);
  }

  public get isInternalOrPaid(): boolean {
    return RoleGroups.InternalOrPaidUsers.containsRole(this.userRole);
  }

  public get isInternalOrBARDA(): boolean {
    return RoleGroups.InternalOrBardaUsers.containsRole(this.userRole);
  }

  public get isAuthenticatedUser(): boolean {
    return RoleGroups.AuthenticatedUsers.containsRole(this.userRole);
  }

  public get isExternalUser(): boolean {
    return RoleGroups.ExternalUsers.containsRole(this.userRole);
  }

  public async sso(relayState: RelayState): Promise<string> {
    const responseType = 'text';
    return await this._httpClient
      .post(
        `${this._apiUrl}/sso`,
        { relayState },
        { headers: this._standardHeaders, responseType: responseType }
      )
      .toPromise();
  }

  private get lastRefreshAttemptTime() {
    const time = localStorage
      ? Number(localStorage.getItem(AuthnStrings.KEY_LAST_REFRESH_TIME))
      : 0;

    return time;
  }

  private setLastRefreshAttemptTime(): void {
    localStorage?.setItem(
      AuthnStrings.KEY_LAST_REFRESH_TIME,
      new Date().getTime().toString()
    );
  }

  private resetRefreshRetryCount() {
    this.RefreshRetryCount = 0;
  }

  private getRefreshRetryIntervalMs(): number {
    return Math.floor(
      RefreshRetryMinMs *
        Math.pow(RefreshRetryBackoffFactor, this.RefreshRetryCount - 1)
    );
  }

  private msToNextRefresh(): number {
    const loginInfo = this.loginInfo;
    const tokenLifeInSeconds = loginInfo.exp - loginInfo.iat;
    const now = new Date().getTime();
    const ageInSeconds = now / 1000 - loginInfo.iat;
    const msSinceLastRefresh =
      new Date().getTime() - this.lastRefreshAttemptTime;
    let retval = this.randomPerturbation;

    // Don't try to refresh an expired token
    if (ageInSeconds > tokenLifeInSeconds) {
      this.clearToken();
      retval = RefreshRetryMinMs;
    } else if (this.RefreshRetryCount === 0) {
      // If we haven't just failed refresh, wait until just after the half life
      const justPastHalfLifeInSeconds =
        PercentLifeToRefresh * tokenLifeInSeconds;
      retval += 1000 * (justPastHalfLifeInSeconds - ageInSeconds);
    } else {
      // If we've failed, retry after a lengthening backoff interval
      // but not too close to expiration time
      const msUntilExpiry = 1000 * (tokenLifeInSeconds - ageInSeconds);
      retval += Math.min(
        this.getRefreshRetryIntervalMs() - msSinceLastRefresh,
        msUntilExpiry - RefreshRetryMinMs
      );
    }

    return Math.floor(retval);
  }

  public async scheduleTokenRefresh(): Promise<void> {
    if (AuthnService.isTokenRefreshScheduled) {
      return;
    }

    AuthnService.isTokenRefreshScheduled = true;
    await this.queueTokenRefresher();
  }

  public async setTokenAndScheduleRefresh(
    jwtToken: string,
    fromParent?: boolean
  ): Promise<void> {
    this.tokenService.setToken(jwtToken, fromParent);
    await this.scheduleTokenRefresh();
  }

  // Asynchronously issue requests to refresh our token if we see we've gotten past the half-life
  // without a refresh.
  // Note that the renewToken() method will not renew a token unless it is past the half-life, so
  // we only try a refreshToken() when a bit beyond that point.
  private async queueTokenRefresher(): Promise<void> {
    if (!this.tokenService.token) {
      // This should be scheduled once we have a token,
      // so just bail here without setting a new retry -
      // setTokenAndScheduleRefresh will handle this if we
      // get a token later
      return;
    }
    let retryInterval: number = this.msToNextRefresh();

    try {
      if (retryInterval <= 0) {
        await this.refreshToken();
      }
    } catch (error) {
      this.RefreshRetryCount++;
      this._logger.warn(
        `Token refresh failed at ${error?.url}: ${error?.statusText}.`
      );
    } finally {
      // Requeue the refresher, so it doesn't stop running

      if (!this.tokenService.token) {
        // If the token was expired, msToNextRefresh cleared it. No need to schedule.
        AuthnService.isTokenRefreshScheduled = false;
        return;
      }

      // Queue retry in interval ms, but no sooner than one second from now.
      retryInterval = Math.max(1000, this.msToNextRefresh());
      if (this.RefreshRetryCount > 0) {
        this._logger.info(
          `Retrying token refresh in ${retryInterval / 1000} seconds`
        );
      }
      setTimeout(() => this.queueTokenRefresher(), retryInterval);
    }
  }
}
