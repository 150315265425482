import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ComponentBase } from '../../../../_common/components/_component.base';

import { ReviewEditsService } from '../../services/review-edits.service';
import { DeploymentContext } from '../../../../_common/utilities/deployment-context/deployment-context';

@Component({
  selector: 'edits-summary',
  templateUrl: './edits-summary.component.html',
  styleUrls: ['./edits-summary.component.scss'],
})
export class EditsSummaryComponent extends ComponentBase {
  @Input()
  public isSubmitBarInvisible = false;

  @Input()
  public onLastTab = false;

  @Output()
  public nextTabClickEvent: EventEmitter<boolean> = new EventEmitter<boolean>();

  public constructor(
    dc: DeploymentContext,
    private _reviewEditsService: ReviewEditsService
  ) {
    super(dc);
  }

  // public getters/setters
  public get editsApproved(): number {
    return this._reviewEditsService.editsApproved;
  }

  public get approveButtonOn(): boolean {
    return (
      !(this._reviewEditsService.editsToReview === 0) &&
      !(this._reviewEditsService.editsDeclined > 0)
    );
  }

  public get declineButtonOn(): boolean {
    return (
      !(this._reviewEditsService.editsToReview === 0) &&
      !(this._reviewEditsService.editsApproved > 0)
    );
  }

  public get allEditsReviewed(): boolean {
    return this._reviewEditsService.editsToReview === 0;
  }

  public get editsDeclined(): number {
    return this._reviewEditsService.editsDeclined;
  }

  public get editsToReview(): number {
    return this._reviewEditsService.editsToReview;
  }

  // public methods
  public approveAll(): void {
    this._reviewEditsService.approveAll();
  }

  public declineAll(): void {
    this._reviewEditsService.declineAll();
  }

  public nextTab(): void {
    this.nextTabClickEvent.emit(true);
  }
}
