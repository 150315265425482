import {
  Filter,
  SectorWithCountAndCanonicalString,
} from 'company-finder-common';

export class SearchBody {
  predicate: string;
  from: number;
  size: number;
  filter: Filter;
  by: string;

  constructor(
    allSectorsWithCounts: SectorWithCountAndCanonicalString[] = [],
    predicate: string = '',
    filter: Filter = Filter.emptyAlphaSortFilter,
    from: number = 0,
    size: number = 0,
    by: string = undefined
  ) {
    this.predicate = predicate ?? '';
    this.from = from;
    this.size = size;
    this.filter = filter.clone();
    this.by = by;
    this.hdyrateFilter(this.filter, allSectorsWithCounts);
  }

  public hydrateSectors(
    sectors: string[],
    subsectors: string[],
    allSectorsWithCounts: SectorWithCountAndCanonicalString[]
  ): { sectors: string[]; subsectors: string[] } {
    const uniqueSectors = new Set<string>(sectors);
    const uniqueSubsectors = new Set<string>(subsectors);

    uniqueSubsectors?.forEach((subsector) => {
      const fullSubsector = allSectorsWithCounts?.find(
        (s) => s.sector === subsector
      );
      uniqueSectors.add(fullSubsector?.parentSector);
    });

    return {
      sectors: [...uniqueSectors],
      subsectors: [...uniqueSubsectors],
    };
  }

  private hdyrateFilter(
    filter: Filter,
    allSectorsWithCounts: SectorWithCountAndCanonicalString[]
  ): void {
    ({ sectors: filter.primarySectors, subsectors: filter.primarySubSectors } =
      this.hydrateSectors(
        filter.primarySectors,
        filter.primarySubSectors,
        allSectorsWithCounts
      ));
    ({
      sectors: filter.secondarySectors,
      subsectors: filter.secondarySubSectors,
    } = this.hydrateSectors(
      filter.secondarySectors,
      filter.secondarySubSectors,
      allSectorsWithCounts
    ));
  }
}
