import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpEvent,
  HttpResponse,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { catchError, finalize, map } from 'rxjs/operators';

// service/utility imports
import { DeploymentContext } from '../utilities/deployment-context/deployment-context';
import { ProgressIndicatorService } from '../services/progress-indicator/progress-indicator.service';
import { AuthnStrings } from '../constants/AuthnStrings';
import { TokenService } from '../services/token/token.service';
import { ApplicationContext } from '../utilities/application-context/application-context';
import { AuthnService } from '../services/authn/authn.service';

@Injectable()
export class AuthnInterceptor implements HttpInterceptor {
  private progress = {};
  private _timer = {};
  private _defaultTimeInterval = 1800;

  constructor(
    private _applicationContext: ApplicationContext,
    private _tokenService: TokenService,
    private _deploymentContext: DeploymentContext,
    private _progressIndicatorService: ProgressIndicatorService,
    private _authnService: AuthnService
  ) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    this.startIndicator(request.url);
    this.progress[request.url] = true;

    if (this._tokenService.isAuthenticated) {
      // FUTURE: Given that our app may initiate xhr requests to systems other than Navigator (currently just JUniverse),
      //   we might consider only adding this auth header to requests sent to the Navigator API.
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${this._tokenService.getToken()}`,
        },
      });
    }

    // Any time a token is received, hold onto it
    return next.handle(request).pipe(
      map((event: HttpEvent<unknown>) => {
        if (event instanceof HttpResponse) {
          if (event.status === 200 || event.status === 302) {
            const returnedToken = event.headers.get(
              AuthnStrings.RESPONSE_HEADER_TOKEN_KEY
            );
            // if user has intentionally logged out, ignore token
            if (returnedToken && !this._applicationContext.jnjLoggedOut) {
              this._authnService.setTokenAndScheduleRefresh(returnedToken);
            } else {
              // If the user had a token, it's not valid. Clear it out
              this._authnService.clearToken();
              this._authnService.clearJnjWasLoggedIn();
            }
          }
        }
        return event;
      }),
      catchError((error: HttpErrorResponse) => {
        if (error.status === 401) {
          // we don't want to log out a user or redirect them if they don't have a JUNIVERSE login.
          // As part of ADJQ-750 we investigated removing this 401 from the log, but Chrome is logging
          // the error before it is handed to Angular
          if (
            this._deploymentContext.juniverseConfigured &&
            error.url.startsWith(this._deploymentContext.juniverseTokenEndpoint)
          ) {
            this.stopIndicator(request.url);
            return of(null);
          }

          if (error.url.indexOf('/api/v1/') > -1) {
            this._tokenService.clearToken();
          }

          // To interrupt the current route, need to throw an error, but one the catch-all JnjErrorHandler will ignore.
          // This was "proven" based on logging pre & post httpclient invocation; "return EMPTY" did not interrupt execution.
          // See https://github.com/angular/angular/issues/21149 for some context, although that didn't provide decisiveclarity
          return of(null);
        } else {
          return throwError(error);
        }
      }),
      finalize(() => {
        this.stopIndicator(request.url);
      })
    );
  }

  private startIndicator(url: string) {
    const timerInterval =
      this._deploymentContext.progressIndicator?.delay ||
      this._defaultTimeInterval;

    // Clear any old timer for this url before we start a new one
    // Better might be to pass in a unique id for this specific httprequest invocation, not just by url, but this
    // is a quick fix to the problem where the progress indicator pops up if you do two requests to the same url in a row
    // with a particular timing.
    clearInterval(this._timer[url]);

    this._timer[url] = setInterval(() => {
      if (this.progress[url]) {
        this._progressIndicatorService.startOperation();
      }
    }, timerInterval);
  }

  private stopIndicator(url: string) {
    clearInterval(this._timer[url]);
    this.progress[url] = false;
    this._progressIndicatorService.completeOperation();
  }
}
