import { ActivatedRoute } from '@angular/router';
import {
  AfterViewChecked,
  Component,
  HostListener,
  OnDestroy,
  OnInit,
  QueryList,
  ViewChild,
} from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';

import { ToastrService } from 'ngx-toastr';

import _ from 'lodash';

import { CompanyUpdateConfirmationComponent } from './components/company-update-confirmation/company-update-confirmation.component';
import { EditItemComponent } from './components/edit-item/edit-item.component';
import { NotificationsComponent } from './components/notifications/notifications.component';
import { ReviewEditsService } from './services/review-edits.service';
import { CompanyUpdateService } from './services/company-update.service';
import { compareCompanies, Diff, itemIsDifferent } from './utils';

import {
  SelfUpdateMode,
  SubmittedUpdate,
  ItemReviewModification,
} from './company-update.interface';
import {
  Company,
  CompanyKey,
  EditItemType,
  getDependentFields,
  getUpdateableFields,
  getUpdateOptions,
  isBlueKnightProperty,
  isCompanyContactTitle,
  LocalizedTextIds,
  nameof,
  UpdateStatus,
  urlIdentifierForCompany,
} from 'company-finder-common';
import {
  Breadcrumb,
  BreadcrumbsService,
} from '../../_common/services/breadcrumbs/breadcrumbs.service';
import { CompanyService } from '../../_common/services/company/company.service';

// utility imports
import { DeploymentContext } from '../../_common/utilities/deployment-context/deployment-context';
import {
  requireIfBlueKnightCompany,
  RequireMoreThanJustSpaces,
} from '../../_common/utilities/forms/form-util';
import { Subscription } from 'rxjs';
import { StickyOnScrollComponent } from '../../_common/components/sticky-on-scroll/sticky-on-scroll.component';
import { JnJInformationUpdateComponent } from './components/jnj-information-update/jnj-information-update.component';
import { BlueKnightInformationUpdateComponent } from './components/blue-knight-information-update/blue-knight-information-update.component';
import { BlueKnightMilestonesUpdateComponent } from './components/blue-knight-milestones-update/blue-knight-milestones-update.component';
import { PublicInformationUpdateComponent } from './components/public-information-update/public-information-update.component';
import { UpdateComponentBaseWithEditItems } from './UpdateComponentBaseWithEditItems';
import { UpdateHeaderComponent } from './components/header/update-header.component';
import { TabConfig, TabInfo } from '../../_common/utilities/tabs/tab.util';
import { SimpleTabUIComponent } from '../../_common/components/simple-tab-ui/simple-tab-ui.component';
import { UserService } from '../../_common/services/user/user.service';
import { ComponentOkToDeactivate } from '../../_common/guards/pending-changes-guard';
import { Observable } from 'rxjs';
import { TitleAndMetadata } from '../../_common/utilities/title-and-metadata/title-and-metadata';

@Component({
  selector: 'company-update',
  templateUrl: './company-update.component.html',
  styleUrls: ['./company-update.component.scss'],
})
export class CompanyUpdateComponent
  extends UpdateComponentBaseWithEditItems
  implements AfterViewChecked, OnDestroy, OnInit, ComponentOkToDeactivate
{
  public isShowingTooltip = {
    primary: false,
    secondary: false,
  };

  @ViewChild('notificationsComponent', { static: true })
  public notificationsComponent: NotificationsComponent;
  @ViewChild(UpdateHeaderComponent)
  public header: UpdateHeaderComponent;
  @ViewChild('submissionConfirmation', { static: true })
  public submissionConfirmation: CompanyUpdateConfirmationComponent;
  public companyEditForm: FormGroup;
  public companyEditFormSubscription: Subscription;

  @HostListener('window:beforeunload')
  okToDeactivate(): Observable<boolean> | boolean {
    if (this._deploymentContext.debug.allowAllReloads) {
      return true;
    }
    return this.reviewSubmitted || !this.hasChanges();
  }

  public tabs: TabInfo[] = [
    {
      highlightWidth: 77,
      label: this.Loc(LocalizedTextIds.CompanyDetailsPublicInfo),
      value: TabConfig.PUBLIC,
      getErrorInfo: (): string[] =>
        this.getErrorsForTab(
          nameof<CompanyUpdateComponent>('publicInformation')
        ),
    },
    {
      highlightWidth: 145,
      label: this.Loc(LocalizedTextIds.CompanyDetailsInternalJJInfo),
      lockIcon: true,
      value: TabConfig.JNJ_ONLY,
      getErrorInfo: (): string[] =>
        this.getErrorsForTab(nameof<CompanyUpdateComponent>('jnjInformation')),
    },
  ];

  public blueKnightTab: TabInfo = {
    blueKnightIcon: true,
    highlightWidth: 242,
    label: this.Loc(LocalizedTextIds.CompanyDetailsInternalBKOverview),
    lockIcon: true,
    value: TabConfig.BLUE_KNIGHT,
    getErrorInfo: (): string[] =>
      this.getErrorsForTab(
        nameof<CompanyUpdateComponent>('blueKnightInformation')
      ),
  };

  public blueKnightMilestonesTab: TabInfo = {
    blueKnightIcon: true,
    highlightWidth: 252,
    label: this.Loc(LocalizedTextIds.CompanyDetailsInternalBKMilestones),
    lockIcon: true,
    value: TabConfig.BLUE_KNIGHT_MILESTONES,
    getErrorInfo: (): string[] =>
      this.getErrorsForTab(
        nameof<CompanyUpdateComponent>('blueKnightMilestones')
      ),
  };

  @ViewChild('editsSubmissionSummary', { static: true })
  public editsSubmissionSummary: StickyOnScrollComponent;
  @ViewChild('submitEditsFooter', { static: false })
  public submitEditsFooter: StickyOnScrollComponent;

  @ViewChild('tabControl')
  public tabControl: SimpleTabUIComponent;

  @ViewChild(JnJInformationUpdateComponent)
  public jnjInformation: JnJInformationUpdateComponent;
  @ViewChild(BlueKnightInformationUpdateComponent)
  public blueKnightInformation: BlueKnightInformationUpdateComponent;
  @ViewChild(BlueKnightMilestonesUpdateComponent)
  public blueKnightMilestones: BlueKnightMilestonesUpdateComponent;
  @ViewChild(PublicInformationUpdateComponent)
  public publicInformation: PublicInformationUpdateComponent;

  // private properties
  private _isSubmitBarInvisible: boolean;
  private _routeParamsSubscription: Subscription;

  public currentTab: TabConfig = TabConfig.PUBLIC;

  public constructor(
    dc: DeploymentContext,
    private _route: ActivatedRoute,
    private _breadcrumbsService: BreadcrumbsService,
    private _reviewEditsService: ReviewEditsService,
    private userService: UserService,
    _companyService: CompanyService,
    _companyUpdateService: CompanyUpdateService,
    public _toastrService: ToastrService,
    private titleAndMetadata: TitleAndMetadata
  ) {
    super(dc, _companyUpdateService, _companyService);
    if (!this.isFollowEnabled) {
      this.isShareWithFollowers = false;
    }
  }

  // public getters
  public get isEditMode(): boolean {
    return this.selfUpdateMode === SelfUpdateMode.Edit;
  }

  public get isReviewMode(): boolean {
    return this.selfUpdateMode === SelfUpdateMode.Review;
  }

  public get progressUpdateString(): string {
    return this._companyUpdateService.progressUpdateString;
  }

  public get hasUpdatesToShareWithFollowers(): boolean {
    return this._reviewEditsService.editsApproved > 0;
  }

  public get isEditsSummaryInactive(): boolean {
    return !!this._reviewEditsService.currentEditItemProperty;
  }

  public get isFollowEnabled(): boolean {
    return this._deploymentContext.featureSwitches.enableFollow;
  }

  public get isReviewPendingProgressUpdate(): boolean {
    return this.isReviewMode && this.isProgressUpdateSet;
  }

  public get updateSubmitted(): boolean {
    return this._companyUpdateService.updateSubmitted;
  }

  public set updateSubmitted(value: boolean) {
    this._companyUpdateService.updateSubmitted = value;
  }

  public get progressUpdate(): string {
    return this._companyUpdateService.progressUpdate;
  }

  public set progressUpdate(value: string) {
    this._companyUpdateService.progressUpdate = value;
  }

  public get isEditingReviewComments(): boolean {
    return this._companyUpdateService.isEditingReviewComments;
  }

  public set isEditingReviewComments(value: boolean) {
    this._companyUpdateService.isEditingReviewComments = value;
  }

  public get reviewSubmitted(): boolean {
    return this._companyUpdateService.reviewSubmitted;
  }

  public set reviewSubmitted(value: boolean) {
    this._companyUpdateService.reviewSubmitted = value;
  }

  public get showFlag(): boolean {
    return (
      this.isProgressUpdateSet &&
      this._reviewEditsService.isPropertyInNeedOfReview(
        this.progressUpdateString
      )
    );
  }

  public get isShowingRevertProgressUpdate(): boolean {
    return (
      this.isReviewPendingProgressUpdate &&
      (this.isEditingProperty[this.progressUpdateString] ||
        this.company[this.progressUpdateString] !==
          this.companyWithPending[this.progressUpdateString])
    );
  }

  public get isSubmitEditsDisabled(): boolean {
    // Need to check validity to make sure there's at least company name
    if (!this.companyEditForm.valid) {
      return true;
    }
    return (
      !this.hasChanges(this.companyWithPending) &&
      this.pendingUpdate?.status !== UpdateStatus.Saved
    );
  }

  public get missingWarning(): string {
    return this.LocPluralize(
      LocalizedTextIds.CompanyUpdateFieldsNeed,
      this.invalidFieldCount,
      this.invalidFieldCount
    );
  }

  public get missingFields(): string {
    return this.invalidFields
      .map((field: CompanyKey) => this.getHeaderForProperty(field))
      .join(', ');
  }

  public get isSaveEditsDisabled(): boolean {
    return (
      this.isEditingAnyProperty || !this.hasChanges(this.companyWithPending)
    );
  }

  public hasChanges(compareTo: Company = this.companyClone): boolean {
    function areEqual(s1: string, s2: string): boolean {
      return (s1 || '') === (s2 || '');
    }

    if (this.isProgressUpdateSet) {
      if (!areEqual(this.progressUpdate, compareTo.progressUpdate)) {
        return true;
      }
    } else {
      if (
        this.progressUpdate?.trim() &&
        !areEqual(this.progressUpdate, compareTo.progressUpdate)
      ) {
        return true;
      }
    }
    const diff: Diff = compareCompanies(this.company, compareTo);
    if (
      diff.hasPropertyChanges ||
      diff.hasDealChanges ||
      diff.hasFundingChanges
    ) {
      return true;
    }
    return false;
  }

  public get isSubmitReviewDisabled(): boolean {
    return (
      this._reviewEditsService.editsToReview > 0 ||
      (this.hasUpdatesToShareWithFollowers &&
        this.isShareWithFollowers === undefined)
    );
  }

  public get isSubmitBarInvisible(): boolean {
    const revs =
      this._reviewEditsService.editsToReview +
      this._reviewEditsService.totalEditsReviewed;
    const isInvisible =
      this._reviewEditsService.editsToReview > 0 || revs === 0;

    if (isInvisible !== this._isSubmitBarInvisible) {
      this._isSubmitBarInvisible = isInvisible;
    }

    return isInvisible;
  }

  public get submittedUpdateForConfirmation(): SubmittedUpdate {
    return this._companyUpdateService.submittedUpdate;
  }

  public get hideConfirmation(): boolean {
    return (
      (this.isEditMode && !this.updateSubmitted) ||
      (this.isReviewMode && !this.reviewSubmitted)
    );
  }

  // public methods
  public goToJlabsPage(): void {
    const destinationUrl = `${
      this._deploymentContext.hostingSiteUrl
    }/company/${urlIdentifierForCompany(this.companyBeforeAnyChanges.name)}`;
    window.open(destinationUrl, '_blank');
  }

  public get isShowingJnjInfo(): boolean {
    return this.currentTab === TabConfig.JNJ_ONLY;
  }

  public get isShowingPublicInfo(): boolean {
    return this.currentTab === TabConfig.PUBLIC;
  }

  public get isShowingBlueKnightInfo(): boolean {
    return this.currentTab === TabConfig.BLUE_KNIGHT;
  }

  public get isShowingBlueKnightMilestones(): boolean {
    return this.currentTab === TabConfig.BLUE_KNIGHT_MILESTONES;
  }

  public itemEditDone(propertyName: string): void {
    if (isCompanyContactTitle(propertyName)) {
      this.companyWithMostRecentEdit.companyContact.title =
        this.company.companyContact.title;
    } else if (propertyName === 'firstTimeEntrepreneur') {
      this.companyWithMostRecentEdit.firstTimeEntrepreneur =
        this.company.firstTimeEntrepreneur;
      this.companyWithMostRecentEdit.minorityLed = this.company.minorityLed;
      this.companyWithMostRecentEdit.womenLed = this.company.womenLed;
    } else {
      this.companyWithMostRecentEdit[propertyName] = _.cloneDeep(
        this.company[propertyName]
      );
    }
    // NOTE: The company object is updated implicitly
    this.isEditingProperty[propertyName] = false;
    this._reviewEditsService.currentEditItemProperty = null;
  }

  public get isEditingAnyProperty(): boolean {
    for (const key in this.isEditingProperty) {
      if (this.isEditingProperty.hasOwnProperty(key)) {
        if (this.isEditingProperty[key]) {
          return true;
        }
      }
    }

    return false;
  }

  public commitOpenEditItems(): void {
    for (const key in this.isEditingProperty) {
      if (this.isEditingProperty.hasOwnProperty(key)) {
        if (this.isEditingProperty[key]) {
          this.itemEditDone(key);
        }
      }
    }
    this._companyUpdateService.checkUpdatesForUI(
      this._companyUpdateService.getCurrentUpdate()
    );
  }

  public revertItemEdit(propertyName: string): void {
    const key = propertyName as CompanyKey;

    // If the item is a parent, revert its children
    const children = getDependentFields(key);
    const itemsToRevert = [propertyName, ...children];

    // Check if this item is dependent on another property (a child)
    const parent = getUpdateOptions(key).preconditionProp;

    // If we are changing a child but not the parent, leave the parent alone since
    // we are choosing from the same sets. If the parent is different, revert it and
    // revert its children
    if (itemIsDifferent(parent, this.company, this.companyBeforeAnyChanges)) {
      itemsToRevert.push(parent);
      itemsToRevert.push(...getDependentFields(parent));
    }

    itemsToRevert.forEach((item) =>
      this._companyUpdateService.revertItemEdit(item)
    );
    this._companyUpdateService.revertItemEdit(propertyName);
    // Reinitialize the edits form.
    this.initializeCompanyEditForm();
    this.setFormControlValues(this.company);
    this._companyUpdateService.checkUpdatesForUI(
      this._companyUpdateService.getCurrentUpdate()
    );
  }

  public consumeEvent(ev: UIEvent): void {
    ev.preventDefault();
    ev.stopPropagation();
  }

  public makePropertyEditable(
    propertyName: string,
    ev?: UIEvent,
    consumeEvent?: boolean
  ): void {
    if (ev && consumeEvent) {
      this.consumeEvent(ev);
    }

    if (!this.isEditingProperty[propertyName]) {
      this.commitOpenEditItems();
      this.isEditingProperty[propertyName] = true;
      this._reviewEditsService.currentEditItemProperty = propertyName;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } else if ((ev?.target as any).name === 'edit-button') {
      this.itemEditDone(propertyName);
      return;
    }

    // Set focus in a timeout to give the UI a cycle to unhide any hidden edit items.
    setTimeout(() => {
      const element = document.getElementById(`company-update-${propertyName}`);
      if (element) {
        element.focus();
      }
    }, 300);
  }

  /*
   * This is separate from makePropertyEditable(), above, because reviewComments
   * is not technically an edit-item, so it shouldn't need to manage other edit
   * items or tell anyone else when it's done. It also isn't being communicated
   * across components, so it shouldn't need a UI cycle to unhide anything. It
   * does, however, need to have its edit state managed by the click HostListener.
   */
  public makeReviewCommentsEditable(ev: MouseEvent): void {
    this.consumeEvent(ev);
    this.isEditingReviewComments = true;
  }

  public ngOnDestroy(): void {
    this._breadcrumbsService.clearBreadcrumbs();
    this.unsubscribe();

    if (this._routeParamsSubscription) {
      this._routeParamsSubscription.unsubscribe();
    }
  }

  public async ngOnInit(): Promise<void> {
    this.ngOnInitInternal();
    if (!this._routeParamsSubscription) {
      this._routeParamsSubscription = this._route.params.subscribe(() => {
        // FUTURE: Consider figuring out which things don't need to be reinitialized.
        // Don't double-init, because it will, at the very least duplicate some of our subscriptions below,
        // which would be a bad thing. If we have a _routeParamsSubscription, it means we already initialized.
        if (this._routeParamsSubscription) {
          this.ngOnInitInternal();
        }
      });
    }
  }

  private async ngOnInitInternal(): Promise<void> {
    this.reviewSubmitted = false;
    this.updateSubmitted = false;

    this._companyUpdateService.initializeCompany(
      this._route.snapshot.data.company
    );

    if (this.company.isBlueKnight) {
      this.tabs.push(this.blueKnightTab);
      this.tabs.push(this.blueKnightMilestonesTab);
    }

    const breadcrumb: Breadcrumb = {
      id: 'review-update',
      name: this.Loc(
        LocalizedTextIds.CompanyUpdateReviewEdits,
        this.company.name
      ),
      action: undefined,
    };

    if (this.userService.isReviewer) {
      this.selfUpdateMode = SelfUpdateMode.Review;
      // Setup the breadcrumbs
      this._breadcrumbsService.clearBreadcrumbs();
      this._breadcrumbsService.addOrReplaceBreadcrumb(breadcrumb);
    } else {
      this.selfUpdateMode = SelfUpdateMode.Edit;
    }

    this.initializeCompanyEditForm();

    // See if there is a pending update for this company, and if so, deal with it.
    await this._companyUpdateService.initializeUpdateData();

    this.setFormControlValues(this.company);

    // FUTURE - The code in these subscriptions should probably live in the CompanyUpdateService
    this.addSubscription(
      this.notificationsComponent.retractionSubject.subscribe(async () => {
        this._companyUpdateService.applyUpdateRetracted();
        this.setFormControlValues(_.merge({}, this.companyBeforeAnyChanges));
      })
    );
    this.addSubscription(
      this.submissionConfirmation.nextReview.subscribe(async () => {
        this.isShareWithFollowers = this.isFollowEnabled ? undefined : false;
      })
    );
    this.addSubscription(
      this.submissionConfirmation.returnToProfileSubject.subscribe(async () => {
        await this.notificationsComponent.getNotification();
        this.updateSubmitted = false;
        await this._companyUpdateService.initializeUpdateData();
        this.setFormControlValues(this.company);
      })
    );
  }

  public ngAfterViewChecked(): void {
    this.AttachEditItemComponentSubscriptions();
  }

  private AttachEditItemComponentSubscriptions() {
    // We want to be sure we are looking at the edit components for the main page
    // (If there are any) and from each tab component
    const allEditItemComponents: QueryList<EditItemComponent> = new QueryList();
    allEditItemComponents.reset([
      this.editItemArray,
      this.publicInformation?.editItemArray,
      this.jnjInformation?.editItemArray,
      this.blueKnightInformation?.editItemArray,
      this.blueKnightMilestones?.editItemArray,
    ]);

    for (const editItemComponent of allEditItemComponents) {
      this.addSubscriptionHandlerIfNeeded(
        editItemComponent.makeEditableSubject,
        (payload: { propertyName: string; ev: MouseEvent }) => {
          this.makePropertyEditable(payload.propertyName, payload.ev);
        }
      );
      this.addSubscriptionHandlerIfNeeded(
        editItemComponent.revertSubject,
        (propertyName: string) => {
          this.revertItemEdit(propertyName);
        }
      );
      if (editItemComponent.type === EditItemType.MultiModal) {
        this.addSubscriptionHandlerIfNeeded(
          editItemComponent.itemReviewSubject,
          (itemReview: ItemReviewModification) => {
            this._companyUpdateService.applyItemReviewModification(
              itemReview,
              editItemComponent.propertyName
            );
          }
        );
      }
    }
  }

  public handleTabClick(tab: TabInfo): void {
    this.currentTab = tab.value;
  }

  @HostListener('window:click', ['$event'])
  protected onClick(): void {
    // Commit any open edit items
    this.commitOpenEditItems();
    // Address the review comments, which are not technically an edit item.
    this.isEditingReviewComments = false;
  }

  public async revertChanges(): Promise<void> {
    await this._companyUpdateService.revertChanges();
    // Reinitialize the edits form.
    this.initializeCompanyEditForm();
    this.setFormControlValues(this.company);
  }

  public get onLastTab(): boolean {
    if (this.company.isBlueKnight) {
      return this.currentTab === TabConfig.BLUE_KNIGHT_MILESTONES;
    } else {
      return this.currentTab === TabConfig.JNJ_ONLY;
    }
  }

  public nextTab(): void {
    this.tabControl.changeTab(
      this.tabs.find((tab) => tab.value === this.nextTabConfig)
    );
  }

  public nextTabOrSubmit(): void {
    if (this.onLastTab) {
      if (this.isEditMode) {
        this.updateCompany();
      } else if (this.isReviewMode) {
        this.submitReview();
      }
    } else {
      this.nextTab();
    }
  }

  private get nextTabConfig(): TabConfig {
    switch (this.currentTab) {
      case TabConfig.PUBLIC:
        return TabConfig.JNJ_ONLY;
      case TabConfig.JNJ_ONLY:
        return this.company.isBlueKnight
          ? TabConfig.BLUE_KNIGHT
          : TabConfig.PUBLIC;
      case TabConfig.BLUE_KNIGHT:
        return TabConfig.BLUE_KNIGHT_MILESTONES;
      case TabConfig.BLUE_KNIGHT_MILESTONES:
        return TabConfig.PUBLIC;
    }
  }

  public cancelReview(): void {
    this._reviewEditsService.cancel();
  }

  public async submitReview(): Promise<void> {
    this._companyUpdateService.submitReview();
    this.header.searchBar.refreshReviewNotifications();
    this._deploymentContext.ensureScrolledToTop();
  }

  public synchronizeProgressUpdate(progressUpdate: string): void {
    this.company.progressUpdate = progressUpdate;
  }

  public toggleTooltip(whichTooltip: string): void {
    this.isShowingTooltip[whichTooltip] = !this.isShowingTooltip[whichTooltip];
  }

  // NOTE: The progress update is handled differently than the rest of the updatable fields.
  //       A new progressUpdate is created in JForce any time the user supplies one, so we
  //       don't use the progressUpdate hanging off this.company as the ngModel, since that
  //       would display the value in the edit UI, which we don't want. We do, however, want
  //       to show a pending progressUpdate if a user returns here while an update is pending
  //       review.
  //       Note, also, that the UI/UX team says that a progressUpdate that has already been
  //       submitted can only be deleted by retracting the entire submission.
  public async updateCompany(): Promise<void> {
    // Commit any open edit items
    this.commitOpenEditItems();

    // Have the service update the data appropriately
    this._companyUpdateService.updateCompany();

    // Set the page title and return to the top of the page
    this.titleAndMetadata.setPageTitle(this.company.name);
    this._deploymentContext.ensureScrolledToTop();
  }

  public async saveEdits(): Promise<void> {
    // Commit any open edit items
    this.commitOpenEditItems();

    await this._companyUpdateService.saveCompany(false);
    await this.notificationsComponent.getNotification();
  }

  // private methods
  private initializeCompanyEditForm(): void {
    this.companyEditFormSubscription?.unsubscribe();

    // We need to manually create controls for any fields that do not directly exist on the company (e.g. contact fields)
    const controls = {
      contactName: this.createDisabledControl(),
      companyContactTitle: new FormControl(),
    };

    for (const updatableField of getUpdateableFields()) {
      const updateOptions = getUpdateOptions(updatableField);
      const isBlueKnight = isBlueKnightProperty(updatableField);

      if (updateOptions.required) {
        controls[updatableField] = isBlueKnight
          ? this.createBlueKnightRequiredControl()
          : this.createMoreThanSpacesRequiredControl();
      } else {
        controls[updatableField] = new FormControl();
      }
    }

    this.companyEditForm = new FormGroup(controls);

    this.companyEditFormSubscription =
      this.companyEditForm.valueChanges.subscribe((formValues) => {
        if (formValues.companyContactTitle && this.company.companyContact) {
          formValues.companyContact = {
            name: this.companyEditForm.get('contactName')?.value,
            title: formValues.companyContactTitle,
          };
        }

        this.company = {
          ...this.company,
          ...formValues,
        };

        // Do not allow the flattened property to "bleed through"
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        delete (this.company as any).companyContactTitle;
      });
  }

  public createBlueKnightRequiredControl(): FormControl {
    return new FormControl('', requireIfBlueKnightCompany(this.company));
  }

  public createMoreThanSpacesRequiredControl(): FormControl {
    return new FormControl('', RequireMoreThanJustSpaces.required);
  }

  public createRequiredControl(): FormControl {
    return new FormControl('', Validators.required);
  }

  public createDisabledControl(): FormControl {
    return new FormControl({ value: '', disabled: true });
  }

  public formControlValuesSet = false;

  private setFormControlValues(company: Company): void {
    // FUTURE: Something strange is going on, where if I access the company object directly,
    //         fields are getting emptied out, hence the workaround below. Figure out what's
    //         happening, and see if we can avoid this clone.
    const clone = _.cloneDeep(company);
    for (const key in clone) {
      if (clone.hasOwnProperty(key)) {
        const val = clone[key];
        if (key === 'companyContact') {
          if (val != null) {
            let fc = this.companyEditForm.get('contactName');
            fc.setValue(val.name);
            fc = this.companyEditForm.get('companyContactTitle');
            fc.setValue(val.title);
          }
        } else {
          const fc = this.companyEditForm.get(key);
          if (fc) {
            fc.setValue(val);
          }
        }
      }
    }
    this.formControlValuesSet = true;

    // Re-attach subscriptions, in case there are newly visible controls
    this.AttachEditItemComponentSubscriptions();
  }

  private get invalidFields(): string[] {
    const fieldNames: string[] = [];
    if (this.formControlValuesSet) {
      const controls = this.companyEditForm.controls;
      Object.keys(controls).forEach((key) => {
        const control = controls[key];
        if (control.errors) {
          fieldNames.push(key);
        }
      });
    }
    return fieldNames;
  }

  public get invalidFieldCount(): number {
    return this.invalidFields.length;
  }

  public getErrorsForTab(cmpnName: string): string[] {
    const cmpn: UpdateComponentBaseWithEditItems = this[cmpnName];
    if (!cmpn) {
      return [];
    }
    const fieldNames = this.invalidFields;
    const fieldsOnThisTab = cmpn.editItemComponents
      .filter((item) => fieldNames.includes(item.propertyName))
      .map((editItem) => editItem.labelText);
    return fieldsOnThisTab;
  }

  public get nextTabOrSubmitButtonTitle(): string {
    if (this.onLastTab && this.isSubmitEditsDisabled) {
      return this.Loc(LocalizedTextIds.CompanyUpdateTheSubmitEdits);
    }
    return '';
  }

  public handleDisabledSubmitClick(): void {
    // If Submit button disabled but gets clicked, give a message
    const message = this.nextTabOrSubmitButtonTitle;
    if (message) {
      const title = this.Loc(LocalizedTextIds.CompanyUpdateCommandDisabled);
      this._toastrService.warning(message, title, {
        positionClass: 'toast-bottom-right',
        timeOut: 5000,
      });
    }
  }
}
