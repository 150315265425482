import { Component, OnInit } from '@angular/core';
import { AuthnService } from '../../_common/services/authn/authn.service';
import {
  HealthStatus,
  LoginInfo,
  RoleGroups,
  ServerStatus,
} from 'company-finder-common';
import { HealthCheckService } from '../../_common/services/health-check/health-check.service';
import { DeploymentContext } from '../../_common/utilities/deployment-context/deployment-context';
import { UserService } from '../../_common/services/user/user.service';
import { ApplicationContext } from '../../_common/utilities/application-context/application-context';
import { AuthnStrings } from '../../_common/constants/AuthnStrings';
import { WebAnalyticsService } from '../../_common/services/web-analytics/web.analytics';
import { Router } from '@angular/router';

@Component({
  selector: 'app-health-check',
  templateUrl: './health-check.component.html',
  styleUrls: ['./health-check.component.scss'],
})
export class HealthCheckComponent implements OnInit {
  public serverHealthCheckResult = new HealthStatus();
  public ServerStatus = ServerStatus;
  public AuthnStrings = AuthnStrings;

  constructor(
    private healthCheckService: HealthCheckService,
    private deploymentContext: DeploymentContext,
    public appContext: ApplicationContext,
    private userService: UserService,
    private authnService: AuthnService,
    private webAnalyticsService: WebAnalyticsService,
    private router: Router
  ) {}

  async ngOnInit(): Promise<void> {
    this.serverHealthCheckResult =
      await this.healthCheckService.doServerHealthCheck();
    if (this.versionMismatchWarning) {
      this.serverHealthCheckResult?.warnings?.push(
        'WARNING: The app and api versions differ. This may result in errors and may indicate deployment issues.'
      );
    }
  }

  public get showDebug(): boolean {
    return this.deploymentContext.debug.showHealthInfo ?? false;
  }

  public get persistenceConnection(): string {
    return `${
      this.serverHealthCheckResult?.dbConnected ? 'Connected' : 'Not Connected'
    } to ${this.serverHealthCheckResult?.database}`;
  }

  public get appVersion(): string {
    return this.healthCheckService.appVersion;
  }

  public get serverWarnings(): string[] {
    return this.serverHealthCheckResult?.warnings;
  }

  public get loginInfo(): LoginInfo {
    return this.authnService.loginInfo;
  }

  public get expiryDate(): Date {
    if (!this.loginInfo) {
      return undefined;
    }

    // A JWT is seconds since epoch, not miliseconds
    return new Date(this.loginInfo.exp * 1000);
  }

  public get siteHeadCompanies(): string {
    return this.userService.getCompanyListsSync().isSiteHeadFor.toString();
  }

  public get coOwnerCompanies(): string {
    return this.userService.getCompanyListsSync().isCoOwnerFor.toString();
  }

  public get companyContactCompanies(): string {
    return this.userService
      .getCompanyListsSync()
      .isCompanyContactFor.toString();
  }

  public get versionMismatchWarning(): boolean {
    return this.appVersion !== this.serverHealthCheckResult?.apiVersion;
  }

  public get showCompanyWarning(): boolean {
    return (
      (this.userService.getCompanyListsSync().isCompanyContact &&
        !RoleGroups.CompanyUsers.containsRole(this.loginInfo.role)) ||
      ((this.userService.getCompanyListsSync().isSiteHead ||
        this.userService.getCompanyListsSync().isCoOwner) &&
        !RoleGroups.InternalSuperUsers.containsRole(this.loginInfo.role))
    );
  }

  public throwError(): void {
    console.log('User requested an error be thrown');
    throw new Error(
      'Error intentionally triggered from the Health Check Component'
    );
  }

  public clearStorage(key: string): void {
    localStorage.removeItem(key);
  }

  public setStorage(key: string, value = 'true'): void {
    localStorage.setItem(key, value);
  }

  public loginInternal(): void {
    this.login(AuthnStrings.INTERNAL_OVERRIDE_TOKEN);
  }

  public loginCompany(): void {
    this.login(AuthnStrings.COMPANY_OVERRIDE_TOKEN);
  }

  public loginInternalSuper(): void {
    this.login(AuthnStrings.INTERNAL_SUPER_OVERRIDE_TOKEN);
  }

  public loginBarda(): void {
    this.login(AuthnStrings.BARDA_OVERRIDE_TOKEN);
  }

  public loginPartner(): void {
    this.login(AuthnStrings.PARTNER_OVERRIDE_TOKEN);
  }

  public loginInvalid(): void {
    this.login(AuthnStrings.INVALID_OVERRIDE_TOKEN);
  }

  public logOut(): void {
    this.clearTokenAndUser();
    this.router.navigate(['/']);
  }

  private clearTokenAndUser(): void {
    this.authnService.clearToken();
    this.userService.clearCachedUser();
  }

  private login(tokenToSend: string): void {
    this.authnService.attemptJuniverseAuth(
      this.webAnalyticsService,
      true,
      tokenToSend
    );
  }
}
