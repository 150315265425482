import _ from 'lodash';

export function equalsIgnoreCase(s1: string, s2: string): boolean {
    if (_.isNil(s1)) {
        return _.isNil(s2);
    }
    if (_.isNil(s2)) {
        return false;
    }
    return s1.toLowerCase() === s2.toLowerCase();
}

/**
 * Returns true if the given string is null, undefined, or contains only
 * whitespace characters. Returns false otherwise.
 * @param value A string to evaluate.
 */
export function isNilOrWhitespace(value: string): boolean {
    return _.isNil(value) || _.isEmpty(value.trim());
}

/**
 * Returns the width in pixels of a string rendered in an HTMLElement, assuming
 * the element's CSS properties are applied. The original use case for this was
 * to determine heights for review notifications to achieve the desired visual
 * design. It is assumed that the HTMLELement is in the DOM, but optionally not
 * visible.
 */
export function visualLength(s: string, ruler: HTMLElement): number {
    ruler.innerHTML = s;
    return ruler.offsetWidth;
}

export const nameof = <T>(name: keyof T): string => name.toString();

/**
 * ADJQ-1423: ensures a url has a protocol (defaults to http) and that it is a proper URL.
 * Otherwise returns null on the assumption it was a placeholder value in jforce that should
 * be ignored in Navigator.
 */
export function convertToValidWebsiteUrl(candidateURL: string): string {
    candidateURL = candidateURL ? candidateURL.trim() : null;
    if (candidateURL) {
        if (candidateURL.search(/^http/gi) === 0) {
            // it starts with a protocol.  Looks like a valid URL.
            return candidateURL;
        } else if (candidateURL.search(/\.[\S]+/g) > 0) {
            // Doesn't start with a protocol, but has a "." which is not the final character so likely is a URL
            return 'http://' + candidateURL; // ... but add a protocol, defaulting to http.
        } else {
            return null; // The value was likely "N/A", "n/a", "Website not provided" (ADJQ-1423) or equivalent placeholder text
        }
    } else {
        return null;
    }
}

/**
 * Returns the first instance of a number in a string.
 * Converts the output to a number.
 */
export function extractNumber(s: string): number {
    return Number(s.replace(/[^0-9]*([0-9]+).*/, '$1'));
}

/**
 * Only handles when running in browser.
 * We could implement serverside if needed using library like 'he'.
 */
let htmlDecoderParser: DOMParser;
const htmlEntityPat = /&(([-_a-z0-9]+)|(#x?[0-9a-f]+));/gi;

export function decodeHtmlEntities(s: string): string {
    return s.replace(htmlEntityPat, (match) => {
        if (!htmlDecoderParser) {
            htmlDecoderParser = new DOMParser();
        }
        const parsed: Document = htmlDecoderParser.parseFromString(
            match,
            'text/html'
        );
        return parsed.body.textContent;
    });
}
