// This is taken from https://github.com/ZeevKatz/ngx-d3
// That package has a lot of dependencies, some of which are no compatible with
// the versions we are using. If D3 changes significantly, this may need changes.
import { Injectable } from '@angular/core';
import * as d3 from 'd3';

/**
 * Type of D3.
 */
export type D3 = typeof d3;

/**
 * D3 service.
 */
@Injectable({
  providedIn: 'root',
})
export class NgxD3Service {
  getD3(): D3 {
    return d3;
  }
}
