import { Component, Input, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

import { ComponentBase } from '../../_component.base';

@Component({
  selector: 'toggle-input',
  templateUrl: './toggle-input.component.html',
  styleUrls: ['./toggle-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ToggleInputComponent),
      multi: true,
    },
  ],
})
export class ToggleInputComponent
  extends ComponentBase
  implements ControlValueAccessor
{
  @Input()
  public name: string;
  @Input()
  public labelPosition: 'left' | 'right' | 'top' | 'bottom' = 'left';

  public disabled: boolean;
  private _value: boolean;

  public onTouched: () => any = () => {};
  private onChange: (value: boolean) => any = () => {};

  public get value(): boolean {
    return this._value;
  }

  public set value(val: boolean) {
    this._value = val;
    this.onChange(this._value);
  }

  public writeValue(val: boolean) {
    this.value = val;
  }

  public registerOnChange(changeHandler: (value: boolean) => any) {
    this.onChange = changeHandler;
  }

  public registerOnTouched(touchHandler: () => any) {
    this.onTouched = touchHandler;
  }

  public setDisabledState(isDisabled: boolean) {
    this.disabled = isDisabled;
  }
}
