import { AfterViewInit, Component, OnInit } from '@angular/core';
import { UpdateComponentBaseWithEditItems } from '../../UpdateComponentBaseWithEditItems';

import { CompanyKey } from 'company-finder-common';
import { EditablePropertyGroup } from '../../company-update.interface';
@Component({
  selector: 'public-information-update',
  templateUrl: './public-information-update.component.html',
  styleUrls: ['./public-information-update.component.scss'],
})
export class PublicInformationUpdateComponent
  extends UpdateComponentBaseWithEditItems
  implements OnInit, AfterViewInit
{
  private existingTags: string[];
  // A normalized (lowercase) table of existing tags.
  // For example: this.normalizedExistingTagsTable['ai'] = true;
  public normalizedExistingTagsTable: [];

  public async ngOnInit(): Promise<void> {
    this.existingTags = await this._companyService.getTags();
    this.normalizedExistingTagsTable = [];
    this.existingTags.forEach(
      (tag) => (this.normalizedExistingTagsTable[tag.toLowerCase()] = true)
    );
  }

  public ngAfterViewInit(): void {
    this.setAllSubscriptions();
  }

  public get hasContact(): boolean {
    return !!this.company.companyContact;
  }

  public get propertyGroup(): EditablePropertyGroup {
    const properties: CompanyKey[] = [
      'name',
      'logoBase64',
      'website',
      'description',
      'tags',
      'problem',
      'solution',
    ];

    if (this.hasContact) {
      properties.push('contactName');
      properties.push('companyContactTitle');
    }

    if (this.featureSwitches.enableSectorUpdates) {
      if (this.featureSwitches.enableSectors) {
        properties.push('primarySector', 'primarySubSector');
      }
      if (this.featureSwitches.enableSecondarySectors) {
        properties.push('secondarySector', 'secondarySubSector');
      }
    }

    if (this.featureSwitches.enableIndicationUpdates) {
      properties.push('primaryIndication');
    }

    if (this.featureSwitches.enableLeadProductStage) {
      properties.push('currentRdStage');
    }

    return {
      properties: properties,
    };
  }

  public get header(): string {
    return this.Loc(this.LocalizedTextIds.PublicInformationTheAreas);
  }
}
