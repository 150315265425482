import { ActivatedRouteSnapshot } from '@angular/router';

export const getUrlFromRoute = (route: ActivatedRouteSnapshot): string => {
  const queryArgs = Object.entries(route.queryParams).map(
    ([key, value]) => `${key}=${value}`
  );

  return `${route.url.map((fragment) => fragment.path).join('/')}${
    queryArgs?.length > 0 ? `?${queryArgs.join('&')}` : ''
  }`;
};
