import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class ProgressIndicatorService {
  // Observable string sources
  private startOperationSubject = new Subject<any>();
  private operationCompleteSubject = new Subject<any>();

  // Observable string streams
  public startOperationObservable = this.startOperationSubject.asObservable(); // eslint-disable-line
  public operationCompleteObservable = this.operationCompleteSubject.asObservable(); // eslint-disable-line

  // Service message commands
  public startOperation() {
    this.startOperationSubject.next();
  }

  public completeOperation() {
    this.operationCompleteSubject.next();
  }
}
