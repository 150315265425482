import { GeoPosition } from '../config';
import { ModelBase } from './_model.base';

export class Location {
    public name: string;
    public locationStatuses: string[];
    public city: string;
    public isNorthAmerican: boolean;
    public aggregateName?: string;
    public geoPosition?: GeoPosition;
    public excludeFromMap?: boolean;

    public constructor(city: string = '', locationStatuses: string[] = []) {
        this.name = this.city = city;
        this.locationStatuses = locationStatuses;
    }
}

export class DataHubLocation extends ModelBase {
    public opportunityIdPrimary: string;
    public location: string;
    public locationStatus: string;
}
