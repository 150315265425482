import { ModelBase } from './_model.base';

export class Opportunity extends ModelBase {
    public opportunityType: string;
    public recordType: string;
    public stage: string;
    public closedDate: Date;
    public primarySector: string;
    public primarySubSector: string;
    public excludeFromReporting: string;
    public opportunityIdPrimary: string;
    public accountId: string;
}
