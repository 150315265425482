import {
  Component,
  OnInit,
  ViewChild,
  AfterViewInit,
  AfterViewChecked,
  ElementRef,
} from '@angular/core';

// service imports
import { AuthnService } from '../_common/services/authn/authn.service';
import { MsgToParentPage } from '../_common/constants/MsgToParentPage';
import { DeploymentContext } from '../_common/utilities/deployment-context/deployment-context';
import { ActivatedRoute, Router } from '@angular/router';
import { HealthCheckService } from '../_common/services/health-check/health-check.service';
import { ToastContainerDirective, ToastrService } from 'ngx-toastr';
import { ApplicationContext } from '../_common/utilities/application-context/application-context';
import { WebAnalyticsService } from '../_common/services/web-analytics/web.analytics';
import { ComponentBase } from '../_common/components/_component.base';
// import { register } from 'swiper/element/bundle';
import { register as registerSwiper } from 'swiper/swiper-element-bundle';
import { TokenService } from '../_common/services/token/token.service';
import { LocalizedTextIds } from 'company-finder-common';
import { BreakpointObserver } from '@angular/cdk/layout';
import { Subject } from 'rxjs';
import { navigateToLogin } from '../_common/utilities/navigation/navigation.utils';
@Component({
  selector: 'company-finder',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent
  extends ComponentBase
  implements OnInit, AfterViewInit, AfterViewChecked {
  title = this.Loc(LocalizedTextIds.AppTheCompany);

  @ViewChild(ToastContainerDirective, { static: true })
  toastContainer: ToastContainerDirective;

  @ViewChild('footer')
  private footer: ElementRef;

  public resizeSubject: Subject<UIEvent> = new Subject();

  public constructor(
    dc: DeploymentContext,
    private element: ElementRef,
    private _applicationContext: ApplicationContext,
    private _authnService: AuthnService,
    private _webAnalyticsService: WebAnalyticsService,
    private _healthCheckService: HealthCheckService,
    private _router: Router,
    private route: ActivatedRoute,
    private _toasterService: ToastrService,
    private _tokenService: TokenService,
    breakpointObserver: BreakpointObserver
  ) {
    super(dc);
    this.registerNarrowScreenBreakpoint(breakpointObserver);
    registerSwiper();
    // Set up favicon
    document
      .getElementById('favicon')
      ?.setAttribute('href', dc.themeSettings.faviconSrc);
  }

  // public getters
  public get currentRoute(): string {
    return this._router.url;
  }

  private get tagManagerFunction(): string {
    return (
      `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});` +
      `var f=d.getElementsByTagName(s)[0], j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;` +
      `j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);})` +
      `(window,document,'script','dataLayer','${this.tagManagerId}');`
    );
  }

  private get tagManagerSource(): string {
    return `https://www.googletagmanager.com/ns.html?id=${this.tagManagerId}`;
  }

  private get tagManagerId(): string {
    return this._deploymentContext.gtmId;
  }

  // public methods
  public async ngOnInit(): Promise<void> {
    this.attachGAScripts();

    // login automatically if token expired
    // FUTURE: Consider consolidating this logic with the UserResolver that handles other
    //  authentication aspects in a way that ensures completion prior to route initialization.
    //  Turns out that AppComponent.ngOnInit may not complete prior to route component ngOnInit
    //  and having authentication related logic in multiple places seems prone for confusion/regression.
    this.redirectToSSOIfExpired();

    // NOTE: The logic below needs to be coordinated with src\assets\IntegrationWithDrupal\iframedPortfolioTool.js
    if (this._deploymentContext.hosted()) {
      this.configureForIFrame();
    }

    // Use only one event listener for resize events in the app, since
    // within an iframe, adding an @HostListener('window:resize') on multiple elements can be very slow.
    // FUTURE - This is only used in one place, ADJQ-1712 would remove the need for this
    window.addEventListener('resize', (event: UIEvent) => {
      this.resizeSubject.next(event);
    });
  }

  private configureForIFrame() {
    // https://jira.jnj.com/browse/ADJQ-124
    // Upon initializing the app, ask the parent window to hand back (via iframe-resizer's sendmessage) any authentication token
    // we might have pushed to it after authentication. This pattern is only needed for browsers disallowing 3rd party cookies.
    // In other cases, an access token is never passed to the parent, so would always come back empty and be ignored.
    const AUTH_MSG_KEY = 'authSession3rdPartyCookiesWorkaround';

    // NOTE: Tried to use the following construct without luck: window.onmessage((event: MessageEvent) => {
    window.addEventListener('message', (event: MessageEvent) => {
      if (
        event.data.for === AUTH_MSG_KEY &&
        event.data.operation === MsgToParentPage.SetToken
      ) {
        // FUTURE - It's unclear why during testing we occassionnaly saw an
        // token with a string of null, but we don't want to store that
        if (event.data.token && event.data.token !== 'null') {
          this._authnService.setTokenAndScheduleRefresh(event.data.token, true);
        } else if (event.data.token === 'null') {
          // Log this to the console at least so we have an idea if
          // this still happens
          console.log(`Token had a string of 'null`);
        }
      }
    });


    this._deploymentContext.sendMessageToIframe(
      { for: AUTH_MSG_KEY, operation: 'getToken' }
    );


    this._tokenService
      .getWorkaroundForBlockedThirdPartyCookies()
      .subscribe((action) => {
        this._deploymentContext.sendMessageToIframe(
          {
            for: AUTH_MSG_KEY,
            operation: action,
            token: this._tokenService.getToken(),
          }
        );
      });
  }

  private redirectToSSOIfExpired() {
    if (
      !this._authnService.isAuthenticated &&
      this._applicationContext.jnjWasLoggedIn &&
      this._deploymentContext.ssoConfig?.enabled
    ) {
      this._authnService.clearJnjWasLoggedIn();
      navigateToLogin(this._router, this.route, this._deploymentContext);
    }
  }

  private attachGAScripts() {
    const gaScript = document.createElement('script');
    gaScript.setAttribute('async', 'true');
    gaScript.setAttribute('src', this.tagManagerSource);

    const gaScript2 = document.createElement('script');
    gaScript2.innerText = this.tagManagerFunction;

    document.documentElement.firstChild?.appendChild(gaScript);
    document.documentElement.firstChild?.appendChild(gaScript2);
  }

  public ngAfterViewInit(): void {
    // Render the toaster (for error messages) into a custom container that is
    // viewport-aware when hosted in an iframe (ADJQ-293)
    this._toasterService.overlayContainer = this.toastContainer;
    if (this.footer?.nativeElement) {
      this._deploymentContext.initFooter(this.footer.nativeElement as HTMLElement);
    }
  }

  public ngAfterViewChecked(): void {
    if (this._deploymentContext.scrollToTopOnNextUpdate) {
      this._deploymentContext.ensureScrolledToTop();
    }
  }

  public isInternal(): boolean {
    return this._authnService.isInternal;
  }

  public get instanceId(): string {
    return this._deploymentContext.instanceId;
  }

  public get appVersion(): string {
    return this._healthCheckService.appVersion;
  }

  public get isFollowEnabled(): boolean {
    return this._deploymentContext.featureSwitches.enableFollow;
  }
}
