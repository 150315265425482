import { Component, Input } from '@angular/core';

import { ComponentBase } from '../../../../_common/components/_component.base';

// model imports
import { EventFilterType } from 'company-finder-common';
import { DeploymentContext } from '../../../../_common/utilities/deployment-context/deployment-context';

@Component({
  selector: 'pill',
  templateUrl: './pill.component.html',
  styleUrls: ['./pill.component.scss'],
})
export class PillComponent extends ComponentBase {
  // public properties
  @Input()
  public type: EventFilterType;
  @Input()
  public value: string;
  public EventFilterTypes = EventFilterType;

  public constructor(dc: DeploymentContext) {
    super(dc);
  }
}
