import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CanDeactivate } from '@angular/router';
import { DeploymentContext } from '../utilities/deployment-context/deployment-context';
import { LocalizedTextIds } from 'company-finder-common';

export interface ComponentOkToDeactivate {
  okToDeactivate: () => boolean | Observable<boolean>;
}

@Injectable()
export class PendingChangesGuard
  implements CanDeactivate<ComponentOkToDeactivate>
{
  public constructor(private _dc: DeploymentContext) {}

  canDeactivate(
    component: ComponentOkToDeactivate
  ): boolean | Observable<boolean> {
    // When working on company update with hot reloading
    // it can be useful to let the browser reload on demand
    if (this._dc.debug.allowAllReloads) {
      return true;
    }

    if (!component.okToDeactivate()) {
      return confirm(this._dc.Loc(LocalizedTextIds.PendingChangesGuard));
    }
    return true;
  }
}
