import { Component, Input } from '@angular/core';
import { SectorWithCountIdCanonicalString } from 'company-finder-common';
import { SavedSearchCriteriaBaseComponent } from '../saved-search-criteria-base/saved-search-criteria-base.component';

@Component({
  selector: 'sector-selector',
  templateUrl: './sector-selector.component.html',
  styleUrls: ['./sector-selector.component.scss'],
})
export class SectorSelectorComponent extends SavedSearchCriteriaBaseComponent<SectorWithCountIdCanonicalString> {

  @Input()
  public includeCounts = true;

  @Input()
  public showFilter = false;

  @Input()
  public isPrimary = true;

  public searchText: string = '';

  onSearchChange(event) {
    this.searchText = (event.target.value)?.toLowerCase();
  }

  // Keep track of collapsed state of children by the parent
  // The default state is collapsed (not open)
  public openChildOptions: { [id: string]: boolean } = {};
  public compareProperty = 'sectorId';

  public isVisible(item: SectorWithCountIdCanonicalString): boolean {
    return !item.parentSector
      || this.openChildOptions[item.parentSector];
  }

  public get filteredData(): SectorWithCountIdCanonicalString[] {
    return (this.optionData as SectorWithCountIdCanonicalString[])
      .filter((sector) =>
        sector.canonicalString.includes(this.searchText)
        || sector.subSectors?.some((sub) =>
          sub.toLowerCase().includes(this.searchText))
      );
  }

  public handleLabelClick(sectorAndCount: SectorWithCountIdCanonicalString, ev?: Event) {
    ev?.preventDefault();
    if (!sectorAndCount.parentSector) {
      this.toggleOpenChildOptions(sectorAndCount.sector);
    } else {
      this.updateSelection(sectorAndCount, ev)
    }
  }

  public toggleOpenChildOptions(sectorName: string, ev?: Event): void {
    this.openChildOptions[sectorName] = !this.openChildOptions[sectorName];
  }

  public isInderminate(item: SectorWithCountIdCanonicalString): boolean {
    return (!this._selectedItems.some((sector) => sector.sectorId === item.sectorId)
      && this._selectedItems.some((sector) => sector.parentSectorId === item.sectorId));
  }

  public updateSelection(item: SectorWithCountIdCanonicalString, ev?: Event): void {

    ev?.stopPropagation();

    if (!this._selectedItems.some((sector) => sector.sectorId === item.sectorId)) {
      this._selectedItems.push(item);
    } else {
      this._selectedItems = this._selectedItems.filter((sector) => sector.sectorId !== item.sectorId);
    }

    this.handleSelectionUpdate.emit(this._selectedItems);
  }

  public trackBySector(_index: number, item: SectorWithCountIdCanonicalString): string {
    return `${item.parentSectorId}${item.sectorId}`;
  }
}
